import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// mui
import Grid from "@mui/material/Unstable_Grid2";
// kendo
import { Loader } from "@progress/kendo-react-all";
// components
import GeneralInfoForm from "./GeneralInfoForm";
import VehicleCostForm from "./VehicleCostForm";
import VehicleDetailsForm from "./VehicleDetailForm";
import { PageHeader } from "@/components/pageHeader/PageHeader";
// state
import { useInventoryDetailCtx } from "../../InventoryDetailProvider";
// utils
import { inventoryService } from "@/services/inventoryService";
import { useNavigationConfirm } from "@/hooks/useNavigationConfirm/useNavigationConfirm";
import { getRouteParamNum } from "@/utils/routing/formatting";
// interfaces
import { ColorListItem, VehicleCost, VehicleDetails } from "@/interfaces/Inventory";
import { ListItem } from "./interfaces";
// style
import styles from "./InventoryVehicle.module.scss";
import { BG_HIGHLIGHT } from "@/mui/theme/colors";

/** ###  */
const InventoryVehicleView: FC = () => {
  const invRecId = getRouteParamNum(useParams().invRecId);

  const generalInformation = useInventoryDetailCtx((s) => s.generalInformation);

  const [loading, setLoading] = useState(false);
  const [isInvInfoLoading, setIsInvInfoLoading] = useState(false);
  /** @todo use context state when implemented */
  const [vehicleCost, setVehicleCost] = useState<VehicleCost | null>(null);
  const [vehicleDetails, setVehicleDetails] = useState<VehicleDetails | null>(null);

  // @note use useReq
  const [colorOptions, setColorOptions] = useState<ColorListItem[]>([]);
  // @note use useReq
  const [bodyStyleOptions, setBodyStyleOptions] = useState<ListItem[]>([]);
  // @note use useReq
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState<ListItem[]>([]);
  // @note use useReq
  const [transmissionOptions, setTransmissionOptions] = useState<ListItem[]>([]);
  // @note use useReq
  const [fuelTypeOptions, setFuelTypeOptions] = useState<ListItem[]>([]);
  // @note use useReq
  const [vehicleSizeOptions, setVehicleSizeOptions] = useState<ListItem[]>([]);

  /** @deprecated do not use - this comes from react hook forms */
  const [generalInformationFormDirty, setGeneralInformationFormDirty] = useState(false);
  /** @deprecated do not use - this comes from react hook forms */
  const [vehicleCostFormDirty, setVehicleCostFormDirty] = useState(false);
  /** @deprecated do not use - this comes from react hook forms */
  const [vehicleDetailsFormDirty, setVehicleDetailsFormDirty] = useState(false);

  const { NavigationConfirm } = useNavigationConfirm(
    generalInformationFormDirty || vehicleCostFormDirty || vehicleDetailsFormDirty
  );

  const loadInvInfo = async (invRecId: number | null) => {
    if (!invRecId) return;

    try {
      setIsInvInfoLoading(true);
      const vehicleCostRes = await inventoryService.getVehicleCostDetails(invRecId.toString());
      setVehicleCost(vehicleCostRes || null);

      const vehicleDetailsRes = await inventoryService.getVehicleDetails(invRecId.toString());
      setVehicleDetails(vehicleDetailsRes || null);
    } finally {
      setIsInvInfoLoading(false);
    }
  };

  const loadData = async () => {
    try {
      setLoading(true);
      const colorOptionsRes = await inventoryService.getColors();
      setColorOptions(colorOptionsRes || []);

      const bodyStyleOptionsRes = await inventoryService.getBodyStyles();
      setBodyStyleOptions(bodyStyleOptionsRes || []);

      const vehicleTypeOptionsRes = await inventoryService.getVehicleTypes();
      setVehicleTypeOptions(vehicleTypeOptionsRes || []);

      const transmissionOptionsRes = await inventoryService.getTransmissions();
      setTransmissionOptions(transmissionOptionsRes || []);
      const fuelTypeOptionsRes = await inventoryService.getFuelTypes();
      setFuelTypeOptions(fuelTypeOptionsRes || []);

      const vehicleSizeOptionsRes = await inventoryService.getVehicleSizes();
      setVehicleSizeOptions(vehicleSizeOptionsRes || []);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
    loadInvInfo(invRecId);
  }, [invRecId]);

  const isLoading = loading || isInvInfoLoading;

  return (
    <>
      <Grid
        container
        direction="column"
        gap={2}
        sx={{ height: "80vh", width: "100%", overflow: "hidden", padding: "20px" }}
      >
        {/* Header Section */}
        <Grid overflow={"hidden"}>
          <PageHeader headerLabel="Vehicle" />
        </Grid>
        <Grid
          sx={{
            flex: 1, // Takes the remaining space
            borderRadius: 2.5,
            backgroundColor: BG_HIGHLIGHT,
            overflow: "scroll", // Prevent content overflow
          }}
        >
          <div className={styles.vehicleDetailsCard}>
            {isLoading ? (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                p="40px 0px"
              >
                <Loader themeColor="primary" size="large" />
              </Grid>
            ) : (
              <>
                <div className={styles.sectionHeader}>
                  <span className={styles.title}>General Information</span>
                  <div className={styles.divider}></div>
                </div>
                <div className={styles.formWrapper}>
                  <GeneralInfoForm setDirty={setGeneralInformationFormDirty} />
                </div>

                <div className={styles.sectionHeader}>
                  <span className={styles.title}>Vehicle Cost</span>
                  <div className={styles.divider}></div>
                </div>
                <div className={styles.formWrapper}>
                  {vehicleCost === null ? (
                    <div>No vehicle cost data available</div>
                  ) : (
                    <VehicleCostForm vehicleCost={vehicleCost} setDirty={setVehicleCostFormDirty} />
                  )}
                </div>

                <div className={styles.sectionHeader}>
                  <span className={styles.title}>Vehicle Details</span>
                  <div className={styles.divider}></div>
                </div>
                <div className={styles.formWrapper}>
                  {vehicleDetails === null ? (
                    <div>No vehicle detail data available</div>
                  ) : (
                    <VehicleDetailsForm
                      vehicleDetails={vehicleDetails}
                      setDirty={setVehicleDetailsFormDirty}
                      colorOptions={colorOptions.sort((a, b) => a.item.localeCompare(b.item))}
                      bodyStyleOptions={bodyStyleOptions}
                      vehicleTypeOptions={vehicleTypeOptions}
                      transmissionOptions={transmissionOptions}
                      fuelTypeOptions={fuelTypeOptions}
                      vehicleSizeOptions={vehicleSizeOptions}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </Grid>
      </Grid>
      {NavigationConfirm}
    </>
  );
};

export default InventoryVehicleView;
