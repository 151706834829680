import { FC, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import Grid from "@mui/material/Unstable_Grid2";
// components
import { AccountsMainPanel } from "../accountsMainPanel/AccountsMainPanel";
import { TextInput } from "@/components/inputs/text/TextInput";
import { Checkbox } from "@/components/checkbox/Checkbox";
// state
import { useAccountSelector } from "@/features/Accounts/accountSlice";
// utils
import { accountsService } from "@/services/accountsService";
import { MyCarPayConfig } from "@/interfaces";
import { Loader } from "@/components";

const defaultValues = {
  allowOnline: false
} as MyCarPayConfig;

export const MyCarPaySettings: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const contactInformation = useAccountSelector((s) => s.contactInformation);
  const accountInformation = useAccountSelector((s) => s.accountInformation);
  const { control, setValue } = useForm<MyCarPayConfig>({
    defaultValues
  });

  const buyer = useMemo(
    () => ({
      dob: contactInformation?.buyer?.dateOfBirth
        ? dayjs(contactInformation.buyer.dateOfBirth).utc().format("MM/DD/YYYY")
        : "",
      ssn: contactInformation?.buyer?.socialSecurityNumber || "",
      email: contactInformation?.buyer?.email || "",
    }),
    [contactInformation]
  );

  const coBuyer = useMemo(
    () => ({
      dob: contactInformation?.coBuyer?.dateOfBirth
        ? dayjs(contactInformation.coBuyer.dateOfBirth).utc().format("MM/DD/YYYY")
        : "",
      ssn: contactInformation?.coBuyer?.socialSecurityNumber || "",
      email: contactInformation?.coBuyer?.email || "",
    }),
    [contactInformation]
  );

  useEffect(()=>{
    const fetchGetAllowOnline = async () => {
      setIsLoading(true);
      const result = await accountsService.getMyCarPay(accountInformation!.appRecId!);
      setValue("allowOnline", result);
      setIsLoading(false);
    }
    fetchGetAllowOnline();
  },[])

  return (
    <AccountsMainPanel navBarTitle="MyCarPay.com Settings and Information">
      <Grid container direction="column" flex={1} sx={{ gap: "10px" }}>
          <div>
            <h3 style={{ color: "#2D4CA3", marginTop: "0px" }}>MyCarPay Access</h3>
            { isLoading && <Loader size="large" /> }
            { !isLoading && <>
            <p>Use 'Allow Online' to enable/disable MyCarPay.com access for this account.</p>
            <Controller
              name="allowOnline"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  label="Allow Online"
                  onChange={async (e) => {
                    setIsLoading(true);
                    setValue("allowOnline", e.value);
                    await accountsService.toggleMyCarPay(accountInformation!.appRecId!, e.value);
                    setIsLoading(false);
                  }}
                />
              )}
            />
            </>}
          </div>

        <div>
          <h3 style={{ color: "#2D4CA3" }}>MyCarPay Information</h3>
          <p>
            Registration details must match either Buyer <span>or</span> Co-Buyer.
          </p>
          <ul>
            <li>Current DMS Values shown below</li>
            <li>All 3 fields are required for a MyCarPay.com user (DOB, SSN, and Email)</li>
            <li>MyCarPay.com login must match the email below</li>
          </ul>
          <Grid container direction="row" flex={1} sx={{ gap: "20px" }}>
            <div style={{ width: "300px" }}>
              <h4 style={{ textDecoration: "underline" }}>Buyer</h4>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "150px",
                }}
              >
                <TextInput label="Date of Birth" value={buyer.dob} disabled />
                <TextInput label="SSN" value={buyer.ssn} disabled />
                <TextInput label="Email" value={buyer.email} disabled />
              </div>
            </div>
            <div style={{ width: "300px" }}>
              <h4 style={{ textDecoration: "underline" }}>Co-Buyer</h4>
              <div 
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "150px",
                }}
              >
                <TextInput label="Date of Birth" value={coBuyer.dob} disabled />
                <TextInput label="SSN" value={coBuyer.ssn} disabled />
                <TextInput label="Email" value={coBuyer.email} disabled />
              </div>
            </div>
          </Grid>
        </div>
      </Grid>
    </AccountsMainPanel>
  );
};
