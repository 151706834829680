import { KendoColumnDef, newColumnDef } from "@/utils/tableLayout/utils";
import SaleTypeColumnMenu from "../../SalesListViewTable/SaleTypeColumnMenu";
import CurrencyCell from "@/components/kendoCells/CurrencyCell";
import { IApplicationsDb } from "./interfaces";

const buildDetailRoute: (dataItem: IApplicationsDb) => string = ({ appRecId }) => {
  return `${appRecId}/`;
};

export const applicationsTableColumns: KendoColumnDef<keyof IApplicationsDb>[] = [
  newColumnDef("appDays", "Days", 80, "number", true, true, undefined, 80, 1),
  newColumnDef("bNames", "Name", 200, "link", true, true, buildDetailRoute, 150, 2),
  newColumnDef("appStatus", "Status", 80, undefined, true, true, undefined, 80, 1),
  newColumnDef("depositDown", "Deposit", 100, CurrencyCell, true, true, undefined, 100, 1),
  newColumnDef("stockNum", "Stk. #", 80, undefined, true, true, undefined, 80, 1),
  newColumnDef("vehYmm", "Year/Make/Model", 250, undefined, true, true, undefined, 200, 2),
  {
    field: "saleType",
    title: "Type",
    width: 110,
    minWidth: 100,
    flexGrow: 1,
    columnMenu: SaleTypeColumnMenu,
  },
  newColumnDef("upType", "Up Type", 120, undefined, true, true, undefined, 120, 1),
  newColumnDef("lastContactTime", "Last Contact", "auto", "date", true, true, undefined, 150, 2),
  newColumnDef("lastContactType", "Contact Type", 100, undefined, true, true, undefined, 100, 1),
];