import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import dayjs from 'dayjs';
// mui
import { GridRenderCellParams } from '@mui/x-data-grid';
// utils
import { currencyCellFmt, dateCellFmt } from '@/mui/components/dataTable/cellFormatUtils';
// interfaces
import { MuiColumnDef } from '@/mui/components/dataTable/interfaces';
import { OutForRepoRowData } from '../../interfaces';

const LinkCell: FC<GridRenderCellParams<OutForRepoRowData>> = ({ row }) => {
  return <NavLink to={`/accounts/${row.recId}/`}>{row.accountNum}</NavLink>;
};

export const tableColumns: MuiColumnDef<OutForRepoRowData>[] = [
  { field: 'accountNum', headerName: 'Account', renderCell: LinkCell }, // AccountLinkCell
  { field: 'buyers', headerName: 'Buyer', flex: 1 },
  { field: 'vehicle', headerName: 'Vehicle' },
  {
    field: 'dateSold',
    headerName: 'Sold',
    type: 'dateTime',
    valueFormatter: dateCellFmt,
    valueGetter: (p) => dayjs(p?.row.dateSold).toDate(),
  },
  {
    field: 'openTime',
    headerName: 'Out for Repo',
    type: 'dateTime',
    valueGetter: (p) => {
      if (!p.row.openTime) return null;
      return dayjs.utc(p.row.openTime).local().toDate();
    },
    valueFormatter: (params) => {
      if (!params.value) return '';
      return dayjs(params.value).format('M/D/YYYY h:mm A'); 
    },
  },
  { field: 'arBalance', headerName: 'AR Balance', valueFormatter: currencyCellFmt, type: 'number' },
  { field: 'code3', headerName: 'Lot' },
];
