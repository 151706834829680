import { toast } from "react-toastify";
// utils
import { systemService } from "@/services/systemService";
import { accountsService } from "@/services/accountsService";
import { inventoryService } from "@/services/inventoryService";
// interfaces
import { SetState } from "@/interfaces/utilityTypes";
import { GpsDevice, GpsHistoryItemDeprec, GpsInformation } from "./interfaces";
import { IGpsCtx } from "./GpsProvider";
import dayjs from "dayjs";
import { DateFormat } from "@/utils/helpers/general";

export const loadGpsLastLocate = async (
  vehRecId: number | undefined | null,
  gpsInformation: GpsInformation,
  setGpsLastLocateLoading: SetState<boolean>,
  setGpsLastLocation: SetState<IGpsCtx["gpsLastLocation"]>
) => {
  if (!vehRecId) {
    console.warn(`loadGpsLastLocate(): null/undefined 'vehRecId'`);
    return;
  }

  if (!gpsInformation.device) return;

  if (
    ![GpsDevice.enum.PassTime, GpsDevice.enum.Spireon, GpsDevice.enum.Ituran].includes(gpsInformation.device)
  ) {
    toast.error(`Unable to get GPS location for provider ${gpsInformation.device}`);
    return;
  }
  try {
    setGpsLastLocateLoading(true);
    let response: GpsHistoryItemDeprec | null = null;
    if (gpsInformation.device === GpsDevice.enum.PassTime) {
      var passTimeResponse = await systemService.getPasstimeLastLocate(vehRecId);
      
      if (!passTimeResponse) {
        toast.error("No data returned from PassTime: 'Last Locate'");
        return;
      }
      
      response = passTimeResponse;
    }
    if (gpsInformation.device === GpsDevice.enum.Spireon) {
      var spireonResponse = await systemService.getSpireonLastLocate(vehRecId);

      if (!spireonResponse) {
        toast.error("No data returned from Spireon: 'Last Locate'");
        return;
      }

      response = spireonResponse;
    }
    if (gpsInformation.device === GpsDevice.enum.Ituran) {
      var ituranResponse = await systemService.getIturanLastLocate(vehRecId);

      if (!ituranResponse) {
        toast.error("No data returned from Ituran: 'Last Locate'");
        return;
      }

      response = ituranResponse;
    }
    setGpsLastLocation(response);
  } catch {
  } finally {
    setGpsLastLocateLoading(false);
  }
};

export const getGpsInfo = async (
  recId: number,
  recIdType: "colRecId" | "vehRecId",
  setGpsLoading: SetState<boolean>,
  setGpsInformation: SetState<IGpsCtx["gpsInformation"]>
) => {
  try {
    setGpsLoading(true);
    if (recIdType !== "colRecId" && recIdType !== "vehRecId") {
      throw new Error("RecIdType must be colRecId or vehRecId");
    }

    const response =
      recIdType === "colRecId"
        ? await accountsService.getGpsInfoFromColRecId(recId)
        : await inventoryService.getGpsInfoFromInvRecId(recId);

    setGpsInformation(response);
  } finally {
    setGpsLoading(false);
  }
};

export const forceUpdateGpsLocaton = async (invRecId: number, gpsInformation: GpsInformation) => {
  if (
    gpsInformation.device !== GpsDevice.enum.PassTime &&
    gpsInformation.device !== GpsDevice.enum.Spireon
  ) {
    toast.error(`Currently unable to force-update GPS location for ${gpsInformation.device} devices`);
    return;
  }
  try {
    if (gpsInformation.device === GpsDevice.enum.PassTime) {
      await systemService.updatePasstimeMap(invRecId);
      toast.info(
        "Request sent to PassTime provider. This can take about a minute, please check back then",
        { autoClose: false }
      );
    } else if (gpsInformation.device === GpsDevice.enum.Spireon) {
      await systemService.updateSpireonMap(invRecId); // Assuming this function exists
      toast.info(
        "Request sent to Spireon provider. This can take 20-30 seconds, please check back then",
        { autoClose: false }
      );
    }
  } catch (error) {
    console.error("Error in forceUpdateGpsLocaton:", error);
    toast.error("Unable to force update GPS location");
  }
};
