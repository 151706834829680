import { FC, useEffect } from "react";
import { toast } from "react-toastify";
import { useBlocker } from "react-router-dom";
// state
import { useAddOnsSalesTaxCtx } from "../AddOnsSalesTaxProvider";
// interfaces
import { CompanyAddOnsTaxForm } from "@/interfaces/CtxAdd";
// mui
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Typography } from "@mui/material";
import { Divider } from "@mui/material";
// components
import BackLink from "@/mui/components/buttons/BackLink";
import { Button, Loader } from "@/components";
// import Button from "@/mui/components/buttons/MuiKendoButton";
import NavBlock from "@/utils/forms/NavBlock";
// services
import { companyService } from "@/services/companyService";
import { BG_HIGHLIGHT, DCS_BLUE } from "@/mui/theme/colors";
import TopContainer from "./TopContainer";
import BottomContainer from "./BottomContainer";

const CancelButton: FC<{ onClick?: () => void }> = ({ onClick = () => null }) => {
  const { reset } = useAddOnsSalesTaxCtx((s) => s.addOnsSalesTaxForm);
  const activeRepoCompany = useAddOnsSalesTaxCtx((s) => s.activeProfile);

  // Event handlers
  const handleClickCancel = () => {
    reset({ ...activeRepoCompany } as CompanyAddOnsTaxForm);
    onClick && onClick();
  };

  return <Button type="button" label="Cancel" onClick={handleClickCancel} />;
};

const SubmitButton: FC = () => {
  const { isDirty, isSubmitting } = useAddOnsSalesTaxCtx((s) => s.addOnsSalesTaxForm.formState);

  return (
    <Button
      type="submit"
      disabled={!isDirty || isSubmitting}
      loading={isSubmitting}
      label={"Submit"}
    />
  );
};

const AddOnsSalesTaxDetail: FC<{
  isNew?: boolean;
  onSubmit?: () => void;
  onCancel?: () => void;
}> = ({ isNew = false, onSubmit = () => null, onCancel = () => null }) => {
  const activeProfile = useAddOnsSalesTaxCtx((s) => s.activeProfile);
  const setActiveProfile = useAddOnsSalesTaxCtx((s) => s.setActiveProfile);
  const profileName = useAddOnsSalesTaxCtx((s) => s.profileName);
  const profiles = useAddOnsSalesTaxCtx((s) => s.profiles);
  const loadAddOnsSalesTaxProfiles = useAddOnsSalesTaxCtx((s) => s.loadAddOnsSalesTaxProfiles);
  const {
    handleSubmit,
    getValues,
    reset,
    formState: { isDirty, isValid, isSubmitSuccessful },
  } = useAddOnsSalesTaxCtx((s) => s.addOnsSalesTaxForm);
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return (isDirty || !isValid) && currentLocation.pathname !== nextLocation.pathname;
  });

  const handleClearTaxes = () => {
    const currentValues = getValues(); // Get current form values
    const updatedValues: CompanyAddOnsTaxForm = {
      ...currentValues,
      stateRate: 0.0,
      countyRate: 0.0,
      cityRate: 0.0,
      locRate: 0,
      stateOn: null,
      countyOn: null,
      cityOn: null,
      locOn: null,
      stateMax: 0,
      countyMax: 0,
      cityMax: 0,
      locMax: 0,
      stateMin: 0,
      countyMin: 0,
      cityMin: 0,
      locMin: 0,
    };

    reset(updatedValues); // Reset the form with updated values
  };

  const handleSubmitForm = async (form: CompanyAddOnsTaxForm) => {
    try {
      await companyService.updateAddOnsSalesTaxSettings(form);
      reset(form);
      setActiveProfile(form);
      toast.success("Success: updating company tax and add on settings.");

      onSubmit && onSubmit();
    } catch (error) {
      toast.error("There was an issue saving the add ons and sales tax info.");
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    !profiles && loadAddOnsSalesTaxProfiles.load();
  }, []);

  return (
    <>
      {loadAddOnsSalesTaxProfiles.isLoading ? (
        <Loader size="large" />
      ) : (
        <>
          {!isNew && (
            <BackLink onClick={() => isSubmitSuccessful && loadAddOnsSalesTaxProfiles.load()} />
          )}
          <Grid
            container
            direction="row"
            flex={1}
            component="header"
            width="100%"
            marginTop={2}
            marginBottom={2}
            py={2}
            px={4}
            borderRadius={2.5}
            sx={{ backgroundColor: BG_HIGHLIGHT }}
          >
            <Grid component="h2" marginTop={0}>
              {`Profile - ${profileName ?? ""}`}
            </Grid>
          </Grid>

          <Grid
            container
            direction="column"
            flex={1}
            marginTop={2}
            marginBottom={2}
            py={2}
            px={4}
            borderRadius={2.5}
            sx={{
              width: "100%",
              backgroundColor: BG_HIGHLIGHT,
            }}
          >
            <Grid
              container
              direction="column"
              onSubmit={handleSubmit(handleSubmitForm)}
              gap={3}
              flex={1}
              paddingY={2}
              component="form"
            >
              <Grid
                container
                direction="row"
                flex={1}
                justifyContent="start"
                alignItems="center"
                wrap="nowrap"
              >
                <Grid
                  component={Typography}
                  color={DCS_BLUE}
                  fontSize={18}
                  fontWeight={700}
                  alignItems="center"
                >
                  Sales Tax Settings
                </Grid>
                <Grid container direction="row" flex={1} alignItems="center" >
                  <Divider
                    sx={{
                      flex: 1,
                      maxHeight: "1px",
                      borderBottomWidth: "1px",
                      mx: 2,
                      bgcolor: DCS_BLUE,
                    }}
                  />
                  <Button type="button" label="Clear Taxes" onClick={handleClearTaxes} />
                </Grid>
              </Grid>

              <Grid container direction="row" flex={1} gap={3} paddingX={2}>
                <TopContainer />
              </Grid>

              <Grid
                container
                direction="row"
                flex={1}
                justifyContent="start"
                alignItems="center"
                wrap="nowrap"
              >
                <Grid
                  component={Typography}
                  color={DCS_BLUE}
                  fontSize={18}
                  fontWeight={700}
                  alignItems="center"
                >
                  Taxable Settings for Addons
                </Grid>

                <Divider
                  sx={{ alignSelf: "center", flex: 1, maxHeight: "1px", mx: 2, bgcolor: DCS_BLUE }}
                />
              </Grid>

              <Grid container direction="row" flex={1} gap={3} paddingX={2}>
                <BottomContainer />
              </Grid>

              <Grid container direction="row" justifyContent="end" gap={1}>
                <SubmitButton />
                <CancelButton onClick={onCancel} />
              </Grid>
            </Grid>

            {!isNew && <NavBlock blocker={blocker} />}
          </Grid>
        </>
      )}
    </>
  );
};

export default AddOnsSalesTaxDetail;
