import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
// utils
import { accountsService } from '@/services/accountsService';
import { collectionsService } from '@/services/collectionsService';
// state
import { useAccountsViewCtx, IAccountsViewCtx } from '../accountsViewState';
import { useAcctSubviewCtx, IAcctSubviewCtx } from './acctSubviewState';
import { useTableCtx, ITableCtx } from '../tableState';
// interfaces
import { AccountsGetPgReq } from './interfaces';
import { AcctsSubviewKey } from '../viewInfo';
import { AllowedFilterKey } from './default';
import { Filter } from '@/interfaces/requests';
import { useDebounceEffect } from '@/hooks';

/** ### Fetch information to be rendered in the list view */
const fetchAccountList = async (
  viewState: IAccountsViewCtx,
  tableState: ITableCtx,
  accountSubviewState: IAcctSubviewCtx
) => {
  const { setDbCt, setIsLoading, subviewInfo } = viewState;
  const { textSearchFilter, page, queryPageNumber, sort, filter } = tableState;
  const { setAccountList } = accountSubviewState;

  if (tableState.isDateFilterError) return;

  setIsLoading(true);

  const filterMap = new Map<AllowedFilterKey, string[]>();
  filter
    .filter((f) => f.operator === 'IN')
    .forEach((f) => {
      const field = f.field as AllowedFilterKey;
      const origValues = filterMap.get(field);
      const sqlValue = `'${f.value}'`;
      if (origValues) {
        const updated = [...origValues, sqlValue];
        filterMap.set(field, updated);
      } else {
        filterMap.set(field, [sqlValue]);
      }
    });

  const updatedFilter = filter.filter((f) => f.operator !== 'IN');
  filterMap.forEach((f, k) => {
    const newSqlStr = `(${f.join(',')})`;
    const newCombinedCategoryFilter: Filter = {
      field: k,
      operator: 'IN',
      value: newSqlStr,
    };
    updatedFilter.push(newCombinedCategoryFilter);
  });

  const payload: AccountsGetPgReq = {
    subview: subviewInfo.route as AcctsSubviewKey,
    search: textSearchFilter,
    page: queryPageNumber,
    perPage: page.take,
    sorts: sort.map((s) => s.toSortApi()),
    filters: updatedFilter,
  };

  try {
    const { data, total } = await accountsService.getAccountSubviewList(payload);

    // Prefetch colRecId for Deferred Downs
    if (subviewInfo.route === AcctsSubviewKey.deferredDown) {
      const updatedData = await Promise.all(
        data.map(async (row) => {
          try {
            const colRecId = await collectionsService.getColRecId(row.appRecId, 'LS');
            return { ...row, recId: colRecId }; 
          } catch (error) {
            console.error(`Failed to fetch colRecId for appRecId ${row.appRecId}:`, error);
            return row; // Return the original row if colRecId fetch fails
          }
        })
      );
      
      setAccountList(updatedData); 
    } else {
      setAccountList(data);
    }
    setDbCt(total);
  } catch (err_: unknown) {
    const err = err_ as AxiosError<AccountsGetPgReq>;
    if (err.code === 'ERR_NETWORK') {
      toast.error('Network error');
    } else {
      console.error(err.code);
      toast.error('Failed to load accounts');
    }
  }

  setIsLoading(false);
};

export const useAcctSubviewData = () => {
  const viewState = useAccountsViewCtx((s) => s);
  const tableState = useTableCtx((s) => s);
  const accountDataState = useAcctSubviewCtx((s) => s);

  // Effects
  useDebounceEffect(
    () => {
      if (tableState.sort.length > 0) {
        fetchAccountList(viewState, tableState, accountDataState);
      }
    },
    [
      tableState.filter.map((f) => `${f.field}-${f.operator}-${f.value}`).join(''),
      tableState.sort.map((s) => `${s.field}-${s.dir}`).join(''),
      tableState.page.skip,
      tableState.page.take,
      tableState.pageSizeValue,
      tableState.textSearchFilter,
    ],
    400
  );

  return accountDataState.accountList;
};
