import { Route, Routes } from "react-router-dom";
import AddOnsSalesTaxProvider from "./AddOnsSalesTaxProvider";
import AddOnsSalesTaxDetail from "./AddOnsSalesTaxDetail";
import AddOnsSalesTaxTable from "./AddOnsSalesTaxTable";

const AddOnsSalesTaxRouter = () => {
  return (
    <AddOnsSalesTaxProvider>
      <Routes>
        <Route index element={<AddOnsSalesTaxTable />} />
        <Route path=":id" element={<AddOnsSalesTaxDetail />} />
      </Routes>
    </AddOnsSalesTaxProvider>
  );
}

export default AddOnsSalesTaxRouter;