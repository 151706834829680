import { FC, PropsWithChildren, createContext, useState } from 'react';
import { useMatch } from 'react-router-dom';
// state
// utils
import useCtxFactory from '@/utils/ctxState/useCtxFactory';
import { getGpsInfo } from './requests';
// interfaces
import { GpsHistoryItemDeprec, GpsInformation, GpsTabKey } from './interfaces';

const useCtxState = () => {
  const match = useMatch({ path: '/:root/*' })?.params;
  const isInventory = match?.root === 'inventory';

  const [gpsLoading, setGpsLoading] = useState(false);
  const [gpsLastLocateLoading, setGpsLastLocateLoading] = useState(false);
  const [isGpsModalOpen, setIsGpsModalOpen] = useState(false);

  const [gpsTab, setGpsTab] = useState<GpsTabKey>('deviceInfo');
  const [optInAuthorization, setOptInAuthorization] = useState(false);
  const [gpsLastLocation, setGpsLastLocation] = useState<GpsHistoryItemDeprec | null>(null);
  const [gpsInformation, setGpsInformation] = useState<GpsInformation | null>(null);
  const [gpsHistory, setGpsHistory] = useState<GpsHistoryItemDeprec[]>([]);

  const loadGpsInfo = async (recId: number | null, recIdType: 'colRecId' | 'vehRecId') => {
    recId && (await getGpsInfo(recId, recIdType, setGpsLoading, setGpsInformation));
  };

  return {
    gpsLoading,
    setGpsLoading,
    gpsLastLocateLoading,
    setGpsLastLocateLoading,
    isGpsModalOpen,
    setIsGpsModalOpen,

    gpsTab,
    setGpsTab,

    optInAuthorization,
    setOptInAuthorization,

    gpsLastLocation,
    setGpsLastLocation,

    gpsInformation,
    gpsHistory,
    setGpsHistory,

    loadGpsInfo,
  };
};

export type IGpsCtx = ReturnType<typeof useCtxState>;
const Ctx = createContext<IGpsCtx | null>(null);
export const useGpsCtx = useCtxFactory(Ctx);
const GpsProvider: FC<PropsWithChildren> = ({ children }) => (
  <Ctx.Provider value={useCtxState()}>{children}</Ctx.Provider>
);

export default GpsProvider;
