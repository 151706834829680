// components
import SaleTypeColumnMenu from '../../SalesListViewTable/SaleTypeColumnMenu';
// utils
import { KendoColumnDef, newColumnDef } from '@/utils/tableLayout/utils';
// interfaces
import { ILhPhFundingDb } from './interfaces';

const buildDetailRoute: (dataItem: ILhPhFundingDb) => string = (dataItem) => {
  return dataItem.appRecId.toString();
};

export const lhPhFundingTableColumns: KendoColumnDef<keyof ILhPhFundingDb>[] = [
  newColumnDef('stockNum', 'Stk. #', 80),
  // @note disabled while detail views not implemented - keeping for reference
  newColumnDef('bNames', 'Name', 200, 'link', true, true, buildDetailRoute),
  newColumnDef('vehYmm', 'Year/Make/Model', 250),
  { field: 'saleType', title: 'Type', width: 110, columnMenu: SaleTypeColumnMenu },
  newColumnDef('saleDate', 'Date Sold', 120, 'date'),
  newColumnDef('fundDate', 'Funding On', 130, 'date'),
  newColumnDef('fundAmt', 'Amount', 100, 'accounting'),
  newColumnDef('fundPmt', 'Payment', 150, 'accounting'),
  newColumnDef('fundDone', 'Funding Status', 130),
  newColumnDef('code3', 'Lot', 'auto'),
];
