import { BasicTable } from "@/components/table/Table";
import { useVerification } from "./useVerification";
import styles from "./Verification.module.scss";
import { ReferenceVerificationForm } from "./verificationForm/referenceVerificationForm/ReferenceVerification";
import { LandlordVerificationForm } from "./verificationForm/landlordVerificationForm/LandlordVerification";
import { EmployerVerificationForm } from "./verificationForm/employerVerificationForm/EmployerVerificationForm";
import { VerificationItem } from "./VerificationItem";
import { salesActions, useSalesSelector } from "@/features/Sales/salesSlice";
import { useAppDispatch } from "@/store/store";

export const Verification = () => {
  const { columns, verificationList, onVerificationItemClick } = useVerification();
  const dispatch = useAppDispatch();
  const { verificationListItem, showVerificationForm } = useSalesSelector((s) => s);
  const goBackClick = () => dispatch(salesActions.setShowVerificationForm(false));
  enum contactType {
    Employer = "Employer",
    Landlord = "Landlord",
    Reference = "Reference",
  }

  if (showVerificationForm) {
    return (
      <VerificationItem
        Form={
          verificationListItem!.contactType === contactType.Employer ? (
            <EmployerVerificationForm />
          ) : verificationListItem!.contactType === contactType.Landlord ? (
            <LandlordVerificationForm />
          ) : verificationListItem!.contactType === contactType.Reference ? (
            <ReferenceVerificationForm />
          ) : undefined
        }
        goBackClick={goBackClick}
      />
    );
  }

  return (
    <div className={styles.verificationContainer}>
      <header className={styles.headerContainer}>
        <h2 className={styles.header}>Verification Dashboard</h2>
      </header>
      <main className={styles.body} style={{ height: "100vh" }}>
        <BasicTable
          data={verificationList}
          columns={columns}
          onRowClick={(e) => onVerificationItemClick(e)}
        />
      </main>
    </div>
  );
};
