import { FC, useState } from 'react';
import { toast } from 'react-toastify';

// mui
import Grid from '@mui/material/Unstable_Grid2';
// kendo
import { Button } from "@/components";
import { Spacer } from "@/components/spacer/Spacer";
import { Icons } from '@/components/icons';
import { CreditEducationInfo, CreditEducationSectionProps } from '@/interfaces/CreditEducation';
import { useCreditEducationCtx } from '../CreditEducationProvider';
import TitleHeader from './TitleHeader';
import CreditRepairCloudForm from '../creditRepairCloud/CreditRepairCloud';
import { applicationService } from '@/services/applicationService';
import ConfirmationModal from '@/components/confirmationModal/confirmationModal';
import CreditHeroScoreLogo from '@/assets/creditHeroScore.png';
import { collectionsService } from '@/services/collectionsService';
import { getRouteParamNum } from '@/utils/routing/formatting';
import { useParams } from 'react-router-dom';
import { accountsService } from '@/services/accountsService';

const IntegrationControl: FC<CreditEducationSectionProps> = (props) => {
  const { formData } = props;
  const colRecId = getRouteParamNum(useParams().colRecId);
  const [isUpdate, setIsUpdate] = useState(false);
  const { showCreditRepairForm, setShowCreditRepairForm } = useCreditEducationCtx((s) => s);
  const [showDeleteCreditRepairCloudConfirmationModal, setShowDeleteCreditRepairCloudConfirmationModal] = useState(false);
  const [ isDeleting, setIsDeleting ] = useState(false);
  const [ isTextLoading, setIsTextLoading ] = useState(false);
  const [ isEmailLoading, setIsEmailLoading ] = useState(false);
  const { applicationRecId } = useCreditEducationCtx((s) => s);
  const { fetchCreditEducationData } = useCreditEducationCtx((s) => s);

  const onDelete = async () => {
    setIsDeleting(true);

    try{
      if (!formData?.creditRepairCloudID) toast.info('Not yet registered.');

      const creditInfo = {
        appBuyersRecID: formData?.appBuyersRecID,
        creditEducationID: formData?.creditEducationID,
        creditRepairCloudID: formData?.creditRepairCloudID,
      } as CreditEducationInfo;

      const response = await applicationService.deleteCreditEducation(creditInfo);
      
      toast.success('Credit Repair Cloud deleted successfully');      
      
      await fetchCreditEducationData(applicationRecId);
    }
    finally {      
      setIsDeleting(false);
    }
  };

  return (
    <Grid container direction="column" flex={1} gap={3}>
      <TitleHeader label="Integration Control" />

      {!showCreditRepairForm && (
        <Grid container direction="row" flex={1} gap={4}>
          <Grid container direction="column" flex={1} gap={1}>
            <Grid container direction="row" flex={1} gap={2}>
              <Grid
                container
                direction="column"
                flex={1}
                alignItems={'center'}
                gap={2}
                flexWrap={'wrap'}
              >
                <Icons.CreditRepairCloud />
              </Grid>
            </Grid>
            <Spacer size={5} />
            <Grid container direction="row" flex={1} gap={2} marginX={12}>
              {!props.formData?.creditRepairCloudID && (
                <Grid container direction="column" flex={1} gap={2}>
                  <Button
                    label="Enroll"
                    type="button"
                    onClick={() => {
                      setIsUpdate(false);
                      setShowCreditRepairForm(true);
                    }}
                  />
                </Grid>
              )}
              {props.formData?.creditRepairCloudID && (
                <Grid container direction="column" flex={1} gap={2}>
                  <Button
                    label="Update"
                    type="button"
                    onClick={() => {
                      setIsUpdate(true);
                      setShowCreditRepairForm(true);
                    }}
                  />
                </Grid>
              )}
              <Grid container direction="column" flex={1} gap={2}>
                <Button
                  label="Delete"
                  loading={isDeleting}
                  onClick={() => {
                    if (!formData?.creditRepairCloudID){
                      toast.info('Not yet registered.');
                      return;
                    } 

                    setShowDeleteCreditRepairCloudConfirmationModal(true);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container direction="column" flex={1} gap={1}>
            <Grid container direction="row" flex={1} gap={1}>
              <Grid
                container
                direction="column"
                flex={1}
                alignItems={'center'}
                gap={2}
                flexWrap={'wrap'}
              >
                <img src={CreditHeroScoreLogo} style={{ width: "362px", height: "auto", maxHeight: "76px" }} />
              </Grid>
            </Grid>
            <Spacer size={11} />
            <Grid container direction="row" flex={1} gap={2} marginX={12}>
              <Grid container direction="column" flex={1} gap={2}>
                <Button 
                  label="Open Invite Link"
                  type="button"
                  onClick={() => {
                    if (!formData?.creditRepairCloudID) {
                      toast.info('Not yet registered.');
                      return;
                    }

                    if (!formData?.creditHeroInviteLink) {
                      toast.info('No invite link.');
                      return;
                    }

                    window.open(formData?.creditHeroInviteLink);
                  }}/>
              </Grid>
              <Grid container direction="column" flex={1} gap={2}>
                <Button 
                  label="Text Invite Link"
                  type="button"
                  loading={isTextLoading}
                  onClick={async () => {
                    if (!formData?.creditRepairCloudID) {
                      toast.info('Not yet registered.');
                      return;
                    }

                    if (!formData?.creditHeroInviteLink) {
                      toast.info('No invite link.');
                      return;
                    }                   

                    setIsTextLoading(true);
                    var appBuyerRecId = Number.parseInt(formData?.appBuyersRecID ?? "");
                    accountsService
                      .sendTextMessege(appBuyerRecId, formData?.creditHeroInviteLink ?? "")
                      .then(
                        () => {
                          toast.success('Text message sent successfully');
                        },
                        (e) => {
                          toast.error(e.response.data.message);
                        }
                      )
                      .finally(() => setIsTextLoading(false));
                  }}/>
              </Grid>
              <Grid container direction="column" flex={1} gap={2}>
                <Button 
                  label="Email Invite Link"
                  type="button"
                  loading={isEmailLoading}
                  onClick={() => {
                    if (!formData?.creditRepairCloudID) {
                      toast.info('Not yet registered.');
                      return;
                    }

                    if (!formData?.creditHeroInviteLink) {
                      toast.info('No invite link.');
                      return;
                    }
                    
                    if (!colRecId) return;
                    
                    setIsEmailLoading(true);
                    collectionsService
                      .sendEmail(colRecId, "Credit Hero Score Invite Link", `<a href="${formData?.creditHeroInviteLink ?? ""}">Credit Hero Invite Link</a>`)
                      .then(
                        () => {
                          toast.success('Email message sent successfully');
                        },
                        (e) => {
                          toast.error(e.response.data.message);
                        }
                      )
                      .finally(() => setIsEmailLoading(false));
                  }}/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      {!showCreditRepairForm && (
        <ConfirmationModal
        opened={showDeleteCreditRepairCloudConfirmationModal}
        setOpened={setShowDeleteCreditRepairCloudConfirmationModal}
        confirmationAction={onDelete}
        message="Are you sure you want to delete this Credit Repair Cloud?"
        cancelAction={() => {}}
        dimmerStyle={{ borderRadius: 8, marginBottom: 24 }}
        panelStyle={{ marginLeft: 24, marginRight: 24 }}
      />
      )}

      {showCreditRepairForm && <CreditRepairCloudForm formData={formData} isUpdate={isUpdate} />}
    </Grid>
  );
};

export default IntegrationControl;
