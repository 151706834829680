// mui
import { MuiColumnDef } from '@/mui/components/dataTable/interfaces';
import ButtonLinkCell from '@/mui/components/dataTable/cells/ButtonLinkCell';
// utils
// interfaces
import { CompanyAddOnsTax } from '@/interfaces/CtxAdd';

export const tableColumns: MuiColumnDef<CompanyAddOnsTax>[] = [
  {
    field: 'shortName',
    headerName: 'Company',
    flex: 1,
    minWidth: 120,
    valueFormatter: (p) => (p.value?.length > 0 ? p.value : '-') ?? '-',
  },
  {
    field: 'saleType',
    headerName: 'Profile',
    flex: 1,
    minWidth: 120,
    valueFormatter: (p) => (p.value?.length > 0 ? p.value : '-') ?? '-',
  },
  {
    field: 'recId',
    headerName: 'Actions',
    renderCell: ButtonLinkCell,
    align: 'center',
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
  },
];
