import { FC, useEffect, useState } from "react";
import { cloneDeep } from "lodash-es";
// kendo
import {
  DropDownList,
  Grid,
  GridColumn,
  GridColumnProps as KendoGridColumnProps,
  GridNoRecords,
  GridPageChangeEvent,
  GridPagerSettings,
  GridToolbar,
  Input,
  PagerTargetEvent,
} from "@progress/kendo-react-all";
import { CompositeFilterDescriptor, SortDescriptor } from "@progress/kendo-data-query";
import { Column } from "@/components/table/TableInterface";
import { Button } from "@/components/button/Button";
import { Checkbox } from "@/components/checkbox/Checkbox";
import { DropDownButton } from "@/components/dropDownButton/DropDownButton";
// components
import TypeFilter from "./TypeFilter";
import DateFilter from "./DateFilter";
// state
import { useInventoryViewCtx } from "../state";
// interfaces
import { CheckboxItem } from "./interfaces";
// style
import styles from "@/components/table/Table.module.scss";
import listStyles from "../ListStyles.module.scss";

interface TableProps {
  columns: Column[];
  data: any[];
  onRowClick?: (e: any) => void;
  pageable?: boolean | GridPagerSettings;
  sortable?: boolean;
  resizable?: boolean;
  reorderable?: boolean;
  filter?: CompositeFilterDescriptor;
  noResultsComponent?: JSX.Element;
  initPageSize?: number;
  initSort?: Array<SortDescriptor>;
  typeFilterList?: CheckboxItem[];
}

interface GridColumnProps extends KendoGridColumnProps {
  order: number;
  display: boolean;
  minWidth?: number; // Added minWidth property
  flexGrow?: number; // Added minWidth property
}

const STORAGE_KEY = "inventoryColumns1"; // Increment when modifying gridColumns so that client will disregard any previous incompatible column definition from localstorage

const InventoryTableLayout: FC<TableProps> = ({
  columns,
  data,
  onRowClick,
  pageable = false,
  sortable = false,
  resizable = true,
  reorderable = true,
  noResultsComponent,
  filter,
}) => {
  const localColumns = JSON.parse(localStorage.getItem(STORAGE_KEY)!);

  const sort = useInventoryViewCtx((s) => s.sort);
  const setSort = useInventoryViewCtx((s) => s.setSort);
  const page = useInventoryViewCtx((s) => s.page);
  const setPage = useInventoryViewCtx((s) => s.setPage);
  const pageSizeValue = useInventoryViewCtx((s) => s.pageSizeValue);
  const setPageSizeValue = useInventoryViewCtx((s) => s.setPageSizeValue);
  const listLength = useInventoryViewCtx((s) => s.listLength);
  const setFilterText = useInventoryViewCtx((s) => s.setFilterText);
  const subview = useInventoryViewCtx((s) => s.subview);

  const [gridSeed, setGridSeed] = useState(Math.random());
  const [userSettingsOpen, setUserSettingsOpen] = useState(false);
  const [gridColumns, setGridColumns] = useState<GridColumnProps[]>(
    localColumns
      ? localColumns
      : cloneDeep(
          columns.map((col, idx) => ({
            ...col,
            order: idx,
            display: true,
          })) as unknown as GridColumnProps[]
        )
  );

  const doesRenderTypeFilter = ["sold", "unsold", "all"].includes(subview);
  const doesRenderDateFilter = subview === "sold";

  const saveColumns = (newColumns: GridColumnProps[]) => {
    setGridColumns(newColumns);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(newColumns));
  };

  const handleUserSettingsRowClick = (column: GridColumnProps) => {
    const tempColumns = cloneDeep(gridColumns);
    const tempColumn = tempColumns.find((c) => c.field === column.field);
    if (!column) return;

    tempColumn!.display = !tempColumn!.display;
    saveColumns(tempColumns);
  };

  const handleColumnResize = (event: any) => {
    if (event.end !== true) {
      return;
    }
    const colIndex = event.index;
    const columnField = event.columns[colIndex].field;
    const newWidth = event.newWidth;

    const tempColumns = cloneDeep(gridColumns);
    const index = tempColumns.findIndex((c) => c.field === columnField);

    tempColumns[index]!.width = newWidth;

    saveColumns(tempColumns);
  };

  const handleColumnReorder = (event: any) => {
    const tempColumns = cloneDeep(gridColumns);
    event.columns.forEach((column: GridColumnProps) => {
      const index = tempColumns.findIndex((c) => c.field === column.field);
      tempColumns[index]!.order = column.orderIndex!;
    });

    saveColumns(tempColumns);
    setGridSeed(Math.random());
  };

  const resetGrid = () => {
    setGridColumns(
      cloneDeep(
        columns.map((col, idx) => ({
          ...col,
          order: idx,
          display: true,
        })) as unknown as GridColumnProps[]
      )
    );
    localStorage.removeItem(STORAGE_KEY);
    setGridSeed(Math.random());
    setFilterText("");
    setSort([{ field: "ProductName", dir: "asc" }]);
    setPage({
      skip: 0,
      take: 25,
    });
    setPageSizeValue(25);
  };

  const handleChangePage = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take = event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({ ...event.page, take });
  };

  const handleChangeSort = (updatedSort: SortDescriptor[]) => {
    if (updatedSort.length > 2) {
      updatedSort.shift();
    } else {
      updatedSort = updatedSort.filter((s) => s.dir !== undefined);
    }
    setSort(updatedSort.length < 1 ? null : updatedSort);
  };

  const handleInputChange = (event: any) => {
    const inputValue = event.value;
    setFilterText(inputValue);
  };

  const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      // Handle Enter key press
      setFilterText((event.currentTarget as HTMLInputElement).value);
    }
  };

  useEffect(() => {
    resetGrid();
  }, [subview]);

  const pageValues = !pageable
    ? false
    : {
        buttonCount: 4,
        pageSizes: [10, 25, 50],
        pageSizeValue: pageSizeValue,
      };

  const DropdownFilterCell = (props: any) => {
    const { column, onChange, value } = props;

    if (!column.filter) {
      return null;
    }

    // custom filter logic
    return (
      <DropDownList
        data={column.filter.options}
        onChange={(e) => {
          onChange({ value: e.target.value });
        }}
        value={value}
      />
    );
  };

  const columnElems = gridColumns
    .filter((x) => x.display)
    .sort((a, b) => a.order - b.order)
    .map((column) => {
      const filterConfig = column.filter ? { filter: column.filter } : {};
      const style = column.flexGrow
      ? {
          flexGrow: column.flexGrow,
          minWidth: column.minWidth || 50, // Prevent disappearing columns
        }
      : undefined;

      return (
        <GridColumn
          field={column.field}
          title={column.title}
          key={column.field}
          // @ts-ignore
          cells={(props: any) => <DropdownFilterCell {...props} column={column} />} // Use custom filter cell
          cell={column.cell}
          width={column?.width || undefined}
          filter={column.filter}
          // @ts-ignore
          columnMenu={column.columnMenu}
          style={style}
          {...filterConfig}
        />
      );
    });

  const userSettingsElems = gridColumns.map((c) => {
    return (
      <div
        className={listStyles.userSettingsCheckList}
        onClick={() => handleUserSettingsRowClick(c)}
        key={c.title}
      >
        <Checkbox checked={c.display} /> <div>{c.title}</div>
      </div>
    );
  });

  return (
    <div className={styles.grid}>
      <Grid
        style={{
          height: "100%",
          width: "100%",
          // display: "flex", // Allow flexible resizing
          // flexDirection: "column",
        }}
        data={data}
        sortable={sortable}
        sort={sort === null ? undefined : sort}
        onSortChange={(e) => {
          handleChangeSort(e.sort);
        }}
        pageable={pageValues}
        onPageChange={handleChangePage}
        skip={page.skip}
        take={page.take}
        total={listLength}
        onRowClick={onRowClick}
        key={gridSeed}
        resizable={resizable}
        onColumnResize={handleColumnResize}
        reorderable={reorderable}
        onColumnReorder={handleColumnReorder}
      >
        {/* Conditionally render if filter is provided */}
        {filter && (
          <GridToolbar>
            <Input
              onChange={handleInputChange}
              onKeyDown={handleEnterKeyPress}
              placeholder="Search filter"
            />
            {doesRenderTypeFilter ? <TypeFilter /> : <div />}
            {doesRenderDateFilter ? <DateFilter /> : <div />}
            <div style={{ flex: 1 }}></div>
            <div className={listStyles.userSettingsButton}>
              <div className={listStyles.columnSettings}>
                <DropDownButton
                  label="User Settings"
                  open={userSettingsOpen}
                  setOpen={setUserSettingsOpen}
                  buttonProps={{ secondary: true }}
                >
                  <div className={listStyles.userSettingsContent}>{userSettingsElems}</div>
                </DropDownButton>
              </div>
            </div>
            <Button secondary label="Reset Settings" themeColor="dark" onClick={resetGrid} />
          </GridToolbar>
        )}
        {noResultsComponent && <GridNoRecords>{noResultsComponent}</GridNoRecords>}
        {columnElems}
      </Grid>
    </div>
  );
};

export default InventoryTableLayout;
