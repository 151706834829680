export enum AccountTabRoutes {
  Activity = '',
  Contact = 'contact',
  Residence = 'residence',
  Employer = 'employer',
  Insurance = 'insurance',
  References = 'references',
  AccountInfo = 'account-info',
  Payment = 'payment',
  PaymentOnly = 'payment-only',
  PaymentHistory = 'payment-history',
  LeaseAmendment = 'lease-amendment',
  Gps = 'gps',
  OdometerTracking = 'odometer-tracking',
  CpiRefund = 'cpi-refund',
  PrincipalPayment = 'principal-payment',
  OutForRepo = 'out-for-repo',
  SavedPaymentMethods = 'saved-payment-methods',
  Cbr = 'cbr',
  LegalAccount = 'legal-account',
  FormsAndDocuments = 'forms-and-documents',
  TakePossession = 'take-possession',
  WarrantyRequest = 'warranty-request',
  Sidenotes = 'sidenotes',
  ChargeOff = 'charge-off',
  ReactivateChargeOff = 'reactivate-charge-off',
  ReleasePossession = 'release-possession',
  PaymentReversal = 'payment-reversal',
  MyCarPaySettings = 'mycarpay-settings',
  CustomFields = 'custom-fields',
  CreditEducation = 'credit-education',
}

export type AccountDetailRouteParams = { colRecId: string; '*': string };

export type AccountDetailRouteParamKey = keyof AccountDetailRouteParams;
