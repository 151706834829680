import { FC } from "react";
import { Route, Routes } from "react-router-dom";
// components
import CpiRefund from "@/components/cpiRefund/CpiRefund";
import ActivityPanel from "@/components/activityPanel/activityPanel";
import AccountDetailLayout from "./AccountDetailLayout";
import AccountDetailPaymentForm from "./components/AccountDetailPaymentForm";
import PaymentReversal from "./components/PaymentReversal";
import PrintLedger from "./components/printLedger/PrintLedger";
import { Contact } from "./components/contact/Contact";
import { Employers } from "./components/employer/Employer";
import { References } from "./components/references/References";
import AppInsurance from "./components/AcctInsurance";
import AccountInfo from "./AccountInfo";
import { PaymentHistory } from "./components/paymentHistory/PaymentHistory";
import LeaseAmendment from "./components/leaseAmendment/LeaseAmendment";
import GPS from "./components/GpsView";
import { OdometerTracking } from "./components/odometerTracking/OdometerTracking";
import SavedPaymentMethods from "./components/savedPaymentMethods/SavedPaymentMethods";
import { CreditReporting } from "./components/creditReporting/CreditReporting";
import { LegalAccount } from "./components/legalAccount/LegalAccount";
import { PrintDocuments } from "./components/printDocuments/PrintDocuments";
import TakePossession from "./components/takePossession/TakePossession";
import { WarrantyRequest } from "./components/warrantyRequest/WarrantyRequest";
import Sidenotes from "./components/Sidenotes";
import { ChargeOff } from "./components/chargeOff/ChargeOff";
import { ReactivateChargeOff } from "./components/reactivateChargeOff/ReactivateChargeOff";
import { ReleasePossession } from "./components/releasePossession/ReleasePossession";
import OutForRepoDetail from "./components/outForRepo";
import CreditEducationDetail from "./components/CreditEducationForm/CreditEducationDetail";
import PaymentOnlyLayout from "./PaymentOnlyLayout";
import { MyCarPaySettings } from "./components/myCarPaySettings/MyCarPaySettings";
// state
import AcctDetailProvider from "./AcctDetailProvider";
// utils
// interfaces
import { AccountTabRoutes } from "./interfaces";
import { CustomFieldsForm } from "./components/customFields/CustomFieldsForm";

/** ### 'Account Detail' view component
 * @note Applies `AcctDetail` context-state (`AcctDetailProvider`/`useAcctDetailCtx`)
 *
 * @todo Move `AccountDetail` dir to correct scope: Up one level (to `src/features/Accounts/`)
 * @todo Rename to AccountDetailR
 */
const AccountDetailRouter: FC = () => (
  <AcctDetailProvider>
    <Routes>
      <Route path="ledger" element={<PrintLedger />} />
      <Route path={AccountTabRoutes.PaymentOnly} element={<PaymentOnlyLayout />} />
      <Route path="/*" element={<AccountDetailLayout />}>
        <Route index element={<ActivityPanel />} />
        <Route path={AccountTabRoutes.Contact} element={<Contact />} />
        <Route path={AccountTabRoutes.Employer} element={<Employers />} />
        <Route path={AccountTabRoutes.Insurance} element={<AppInsurance />} />
        <Route path={AccountTabRoutes.References} element={<References />} />
        <Route path={AccountTabRoutes.AccountInfo} element={<AccountInfo />} />
        <Route path={AccountTabRoutes.PaymentHistory} element={<PaymentHistory />} />
        <Route path={AccountTabRoutes.LeaseAmendment} element={<LeaseAmendment />} />
        <Route path={AccountTabRoutes.Gps} element={<GPS />} />
        <Route path={AccountTabRoutes.OdometerTracking} element={<OdometerTracking />} />
        <Route path={AccountTabRoutes.SavedPaymentMethods} element={<SavedPaymentMethods />} />
        <Route path={AccountTabRoutes.Cbr} element={<CreditReporting />} />
        <Route path={AccountTabRoutes.LegalAccount} element={<LegalAccount />} />
        <Route path={AccountTabRoutes.FormsAndDocuments} element={<PrintDocuments />} />
        <Route path={AccountTabRoutes.TakePossession} element={<TakePossession />} />
        <Route path={AccountTabRoutes.WarrantyRequest} element={<WarrantyRequest />} />
        <Route path={`${AccountTabRoutes.Sidenotes}/*`} element={<Sidenotes />} />
        <Route path={AccountTabRoutes.ChargeOff} element={<ChargeOff />} />
        <Route path={AccountTabRoutes.ReactivateChargeOff} element={<ReactivateChargeOff />} />
        <Route path={AccountTabRoutes.ReleasePossession} element={<ReleasePossession />} />
        <Route path={`${AccountTabRoutes.PaymentReversal}/*`} element={<PaymentReversal />} />
        <Route path={AccountTabRoutes.MyCarPaySettings} element={<MyCarPaySettings />} />
        <Route path={AccountTabRoutes.CustomFields} element={<CustomFieldsForm />} />
        <Route path={AccountTabRoutes.CpiRefund} element={<CpiRefund />} />
        <Route path={AccountTabRoutes.CreditEducation} element={<CreditEducationDetail />} />
        <Route
          path={AccountTabRoutes.PrincipalPayment}
          element={<AccountDetailPaymentForm isPrinOnly />}
        />
        <Route path={AccountTabRoutes.OutForRepo} element={<OutForRepoDetail />} />
        <Route path={AccountTabRoutes.Payment} element={<AccountDetailPaymentForm />} />
      </Route>
    </Routes>
  </AcctDetailProvider>
);

export default AccountDetailRouter;
