import { FC } from "react";
// kendo
import { Loader } from "@/components";
import { Spacer } from "@/components/spacer/Spacer";
import { PageHeader } from "@/components/pageHeader/PageHeader";
import { SectionHeader } from "@/components/sectionHeader/SectionHeader";
// components
import GpsDeviceInfo from "@/features/Accounts/accountsSubviews/AccountDetail/components/GpsView/GpsDeviceInfo";
import GpsHistoryModal from "./GpsHistoryModal";
import { GpsHistory } from "@/features/Accounts/accountsSubviews/AccountDetail/components/GpsView/GpsHistory";
import GpsLocation from "@/features/Accounts/accountsSubviews/AccountDetail/components/GpsView/GpsLocation";
import { OdometerTrackingList } from "@/features/Accounts/accountsSubviews/AccountDetail/components/odometerTracking/OdometerTrackingList";
// state
import { useGpsCtx } from "@/features/Accounts/accountsSubviews/AccountDetail/components/GpsView/GpsProvider";
import { useInventoryDetailCtx } from "../../InventoryDetailProvider";
// utils
import { gpsTabMap } from "@/features/Accounts/accountsSubviews/AccountDetail/components/GpsView/interfaces";
// interfaces
import { StateNavTab } from "@/features/Accounts/accountsSubviews/AccountDetail/components/accountsMainPanel/interfaces";
// style
import styles from "./GpsAndOdometer.module.scss";
import inventoryPageStyles from "../InventoryPageStyles.module.scss";
// mui
import Grid from "@mui/material/Unstable_Grid2";
//style
import { BG_HIGHLIGHT } from "@/mui/theme/colors";

/** ###  */
const GpsAndOdometer: FC = () => {
  const generalInformation = useInventoryDetailCtx((s) => s.generalInformation);
  const gpsLoading = useGpsCtx((s) => s.gpsLoading);
  const gpsTab = useGpsCtx((s) => s.gpsTab);
  const setGpsTab = useGpsCtx((s) => s.setGpsTab);

  // Build tab list
  const navBarItems: StateNavTab[] = [];
  gpsTabMap.forEach((v, k) => {
    navBarItems.push({
      title: v,
      isActive: gpsTab === k,
      onClick: () => setGpsTab(k),
    });
  });

  const isSold = !generalInformation || generalInformation?.saleStatus.toLowerCase() === "s";

  const navTabElems: JSX.Element[] = [];
  gpsTabMap.forEach((title, tabId) => {
    const isActive = gpsTab === tabId;

    navTabElems.push(
      <div
        className={isActive ? styles.itemActive : styles.item}
        key={tabId}
        onClick={() => setGpsTab(tabId)}
      >
        <div>{title}</div>
      </div>
    );
  });

  return (
    <>
      <Grid
        container
        direction="column"
        gap={2}
        sx={{ height: "80vh", width: "100%", overflow: "hidden", padding: "20px" }}
      >
        {/* Header Section */}
        <Grid overflow={"hidden"}>
          <PageHeader headerLabel="GPS & Odometer Tracking" />
        </Grid>
        <Grid
          sx={{
            flex: 1, // Takes the remaining space
            borderRadius: 2.5,
            backgroundColor: BG_HIGHLIGHT,
            overflow: "scroll", // Prevent content overflow
          }}
        >
          <div className={inventoryPageStyles.body}>
            <section style={{ display: "flex", flexDirection: "column", width: "100%" }}>
              <SectionHeader title="General GPS Information" />
              {gpsLoading ? (
                <Loader size="large" />
              ) : (
                <>
                  <div className={styles.navBar}>
                    {!!navBarItems && !!navBarItems.length && (
                      <div className={styles.navBarItemsContainer}>{navTabElems}</div>
                    )}
                  </div>
                  <Spacer size={16} />
                  {gpsTab === "deviceInfo" && <GpsDeviceInfo isInventory={true} isSold={isSold} />}
                  {gpsTab === "locationHistory" && <GpsHistory isInventory={true} />}
                  {gpsTab === "location" && <GpsLocation isInventory={true} />}
                </>
              )}
            </section>

            <Spacer size={32} />

            <section>
              <SectionHeader title="Odometer Records" />
              <OdometerTrackingList isInventory={true} />
            </section>
          </div>
        </Grid>
      </Grid>

      <GpsHistoryModal />
    </>
  );
};

export default GpsAndOdometer;
