import { FC } from "react";
// state
// interfaces
import { CompanyAddOnsTaxForm } from "@/interfaces/CtxAdd";
// mui
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Button from "@/mui/components/buttons/MuiKendoButton";
import { Box } from "@mui/material";
import { Divider } from "@mui/material";
// components
import TaxFields from "../customFields/TaxFields";
// services
import { useAddOnsSalesTaxCtx } from "../AddOnsSalesTaxProvider";

const TopContainer: FC = () => {

  return (
    <Grid container direction="column" flex={1} boxSizing={"border-box"}>
      <Grid container direction="column" gap={3} flex={1} component="form">
        {/* Sales Tax Settings Header */}
        <Grid
          container
          direction="column"
          flex={1}
          sx={{ backgroundColor: "#eff0f4", padding: "0px 16px 0px 16px", borderRadius: "10px" }}
        >
          <Grid container direction="row" flex={1} padding="16px 32px 16px 32px">
            <TaxFields />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TopContainer;
