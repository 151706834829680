import { FC, useEffect } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
// components
// state
// utils
import LhPhDetailProvider, { useLhPhDetailCtx } from './LhPhDetailProvider';
import LhPhFundingForm from './LhPhFundingForm';
import { useSalesDetailCtx } from '../SalesDetailProvider';
import LhPhDetailHeader from './LhPhDetailHeader';

const LhPhDetailLayout: FC = () => {
  // form view state
  const setHeaderTitle = useSalesDetailCtx((s) => s.setHeaderTitle);
  const lhPhDetail = useLhPhDetailCtx((s) => s.lhPhDetail);
  const lhPhState = useLhPhDetailCtx((s) => s);

  // Initialize form on first render and anytime page changes
  useEffect(() => {
    setHeaderTitle(<LhPhDetailHeader lhPhState={lhPhState} />);
  }, [lhPhDetail?.appRecId]);

  return (
    <Grid
      container
      direction="column"
      flex={1}
      wrap="nowrap"
      sx={{ overflowX: 'hidden', overflowY: 'hidden' }}
    >
      
      <Outlet/>
    </Grid>
  );
};

const LhPhFundingDetail: FC = () => {
  return (
    <Routes>
      <Route element={<LhPhDetailLayout />}>
        <Route path={``} element={<LhPhFundingForm />} />
        <Route path="/" element={<Navigate to={`./`} replace={true} />} />
        <Route path="*" element={<Navigate to={`./`} replace={true} />} />
      </Route>
    </Routes>
  );
};
export default () => (
  <LhPhDetailProvider>
    <LhPhFundingDetail />
  </LhPhDetailProvider>
);
