import { FC, PropsWithChildren } from 'react';
import { Link, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
// mui
import Grid from '@mui/material/Unstable_Grid2';
import { BG_HIGHLIGHT, LINK_BLUE } from '@/mui/theme/colors';
// kendo
import { Spacer } from "@/components/spacer/Spacer";
// state
import { useAccountSelector } from '@/features/Accounts/accountSlice';
import { useGpsCtx } from '../GpsProvider';
// utils
import { DateFormat } from '@/utils/helpers/general';
// interfaces
import { AccountTabRoutes } from '../../../interfaces';

const linkStyle = {
  display: 'block',
  color: LINK_BLUE,
  fontSize: '10px',
  fontStyle: 'italic',
  textDecoration: 'underline',
  cursor: 'pointer',
};

const DetailRow: FC<PropsWithChildren> = ({ children }) => (
  <Grid fontSize={12} lineHeight="20px" whiteSpace="pre-line" letterSpacing={-0.24}>
    {children}
  </Grid>
);

/** ###  */
const GpsCard: FC = () => {
  const { pathname } = useLocation();

  const currentMiles = useAccountSelector((s) => s.accountInformation?.currentMiles);
  const lastKnownAddress = useAccountSelector((s) => s.accountInformation?.lastKnownAddress);

  const gpsLastLocation = useGpsCtx((s) => s.gpsLastLocation);
  const gpsLastLocateLoading = useGpsCtx((s) => s.gpsLastLocateLoading);
  const isUnavailable = !gpsLastLocation || !gpsLastLocation?.lastPing;
  const dateFmt = isUnavailable
    ? 'Unavailable'
    : gpsLastLocation.lastPing;

  const inGps = pathname.includes(AccountTabRoutes.Gps);

  return (
    <Grid
      container
      direction="column"
      px={3.25}
      py={1.5}
      borderRadius="10px"
      bgcolor={BG_HIGHLIGHT}
    >
      <Grid fontSize={20} fontWeight={700}>
        Last GPS Ping
      </Grid>

      <Spacer size={10} />

      {!lastKnownAddress ? (
        <Grid container direction="column" gap={1}>
          <DetailRow>GPS data is not available for this account</DetailRow>

          {!inGps && (
            <Link to={AccountTabRoutes.Gps} style={linkStyle}>
              Configure GPS
            </Link>
          )}
        </Grid>
      ) : (
        <Grid container direction="column" gap={1}>
          <Grid container direction="row" justifyContent="space-between">
            <Grid container direction="column">
              <DetailRow>{gpsLastLocateLoading ? 'Loading' : dateFmt}</DetailRow>
              <DetailRow>Mileage: {currentMiles || '-'}</DetailRow>
            </Grid>

            <Grid container direction="column">
              <DetailRow>{lastKnownAddress.replace(',', '\n')}</DetailRow>
            </Grid>
          </Grid>

          {!inGps && (
            <Link to={AccountTabRoutes.Gps} style={linkStyle}>
              GPS Details
            </Link>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default GpsCard;
