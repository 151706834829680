import { AiUnderwritingReportDb } from "../interfaces";
import { Alert } from "./interfaces";

export const getRecommendations = (currentAppEval: AiUnderwritingReportDb | null | undefined): Alert[] => {
  if (!currentAppEval) return [];

  const recommendations: Alert[] = [];
  currentAppEval.recommendedMaxLoanAmount &&
    recommendations.push(
      new Alert("recommendation", `Recommendation: Set max loan amount to: $${currentAppEval.recommendedMaxLoanAmount}`)
    );
  currentAppEval.recommendedInterestRate &&
    recommendations.push(
      new Alert("recommendation", `Recommendation: Set interest rate to: ${currentAppEval.recommendedInterestRate}%`)
    );
  currentAppEval.recommendedDownpayment &&
    recommendations.push(
      new Alert("recommendation", `Recommendation: Set down payment to: $ ${currentAppEval.recommendedDownpayment}`)
    );
  currentAppEval.recommendedTermOfLoan &&
    recommendations.push(
      new Alert("recommendation", `Recommendation: Set loan term to: ${currentAppEval.recommendedTermOfLoan} months`)
    );

  return recommendations;
};
