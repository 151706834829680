import React, { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Grid, FormControlLabel, Checkbox, Box, Typography, FormLabel, FormControl, InputLabel } from "@mui/material";
import { SectionHeader } from "@/components/sectionHeader/SectionHeader";
import { TextInput } from "@/components/inputs/text/TextInput";
import { Loader, NumberInput } from "@/components";
import { Button } from "@/components/button/Button";
import { toast } from "react-toastify";

import { useCompanyMgmtCtx } from "./CompanyMgmtProvider";
import { companyService } from "@/services/companyService";
import { ICompanyDefaults } from "@/interfaces/CompanyDefaults";
import { DropdownInput } from "@/components";
import { useBlocker } from "react-router-dom";
import ConfirmationModal from "@/mui/components/ConfirmationModal";
import { BG_HIGHLIGHT, DCS_BLUE } from "@/mui/theme/colors";
import NavBlock from "@/utils/forms/NavBlock";
import Divider from '@mui/material/Divider';

interface FormValues {
  // General Defaults
  lotType: string;
  intType: string;
  stockOpt: string;
  nextSkNum: string;
  useAcctNum: boolean;
  acctNum: string;
  maxPayPer: number;
  defaultGapPaidTo: string;

  // Default Terms
  def_APR: number;
  def_SP: number;
  def_PMT: number;
  defDown: number;
  defPDown: number;
  defSchedule: string;
  def_Term: number;

  // Late Defaults
  lateChgUsed: boolean;
  lateChgPerPmt: boolean;
  lateChgGrace: boolean;
  lateChgRate: number;
  lateChgMin: number;
  lateChgMax: number;
  lateGraceDays: number;

  // CP Defaults
  cP_Weekly: number;
  cP_BiWeekly: number;
  cP_SemiMonthly: number;
  cP_Monthly: number;

  // Lease Defaults
  leaseDispositionFee: number;
  leaseMilesAllowed: number;
  lease_MonthMiles: number;
  leaseMilesPer: number;
  lease_SecurityDeposit: number;
  leaseEarlyTermFee: number; // Added
  leasePurchaseOption: number; // Added

  // Default Fees
  nsfFee: number;
  cpI_Deductable: number;
  repoTitleFees: number;
  ins_Deductable: number;

  // GLBA Defaults
  glbA_OwnerName: string;
  glbA_ShortName: string;

  // Service Defaults
  contractInfo: string;
  defaultSCPaidTo: string;

  // Ecom Alert Defaults
  maxTotExp: number;
  maxExp: number;

  // Other Defaults
  mainMsg: string;

  // Taxes and Doc Stamps
  useFlDoc: boolean;
  useTXTax: boolean;
  useTXInvTax: boolean;
  invTaxRate: number | null;
  useYearlyPremium: boolean;
  rewriteAutoChange: boolean;
}

const CompanyDefaults: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { companyDetails } = useCompanyMgmtCtx((state) => ({
    companyDetails: state.companyDetails,
  }));

  const { control, handleSubmit, reset,formState } = useForm<FormValues>({
    defaultValues: {
      lotType: "",
      intType: "",
      stockOpt: "",
      nextSkNum: "",
      useAcctNum: false,
      acctNum: "",
      maxPayPer: 0,
      defaultGapPaidTo: "",

      def_APR: 0,
      def_SP: 0,
      def_PMT: 0,
      defDown: 0,
      defPDown: 0,
      defSchedule: "",
      def_Term: 0,

      lateChgUsed: false,
      lateChgPerPmt: false,
      lateChgGrace: false,
      lateChgRate: 0,
      lateChgMin: 0,
      lateChgMax: 0,
      lateGraceDays: 0,

      cP_Weekly: 0,
      cP_BiWeekly: 0,
      cP_SemiMonthly: 0,
      cP_Monthly: 0,

      leaseDispositionFee: 0,
      leaseMilesAllowed: 0,
      lease_MonthMiles: 0,
      leaseMilesPer: 0,
      lease_SecurityDeposit: 0,
      leaseEarlyTermFee: 0, // Added
      leasePurchaseOption: 0, // Added

      nsfFee: 0,
      cpI_Deductable: 0,
      repoTitleFees: 0,
      ins_Deductable: 0,

      glbA_OwnerName: "",
      glbA_ShortName: "",

      contractInfo: "",
      defaultSCPaidTo: "",

      maxTotExp: 0,
      maxExp: 0,

      mainMsg: "",

      useFlDoc: false,
      useTXTax: false,
      useTXInvTax: false,
      invTaxRate: null,

      useYearlyPremium: false,
      rewriteAutoChange: false,
    },
  });

  useEffect(() => {
    const fetchDefaults = async () => {
      if (!companyDetails?.recId) return;
      try {
        setIsLoading(true);
        const data = await companyService.getCompanyDefaults(companyDetails.recId);

        const mappedData: FormValues = {
          lotType: data.lotType || "",
          intType: data.intType || "",
          stockOpt: data.stockOpt || "",
          nextSkNum: data.nextSkNum || "",
          useAcctNum: data.useAcctNum ?? false,
          acctNum: data.acctNum || "",
          maxPayPer: data.maxPayPer ?? 0,
          defaultGapPaidTo: data.defaultGapPaidTo || "",

          def_APR: data.def_APR ?? 0,
          def_SP: data.def_SP ?? 0,
          def_PMT: data.def_PMT ?? 0,
          defDown: data.defDown ?? 0,
          defPDown: data.defPDown ?? 0,
          defSchedule: data.defSchedule || "",
          def_Term: data.def_Term ?? 0,

          lateChgUsed: data.lateChgUsed ?? false,
          lateChgPerPmt: data.lateChgPerPmt ?? false,
          lateChgGrace: data.lateChgGrace ?? false,
          lateChgRate: data.lateChgRate ?? 0,
          lateChgMin: data.lateChgMin ?? 0,
          lateChgMax: data.lateChgMax ?? 0,
          lateGraceDays: data.lateGraceDays ?? 0,

          cP_Weekly: data.cP_Weekly ?? 0,
          cP_BiWeekly: data.cP_BiWeekly ?? 0,
          cP_SemiMonthly: data.cP_SemiMonthly ?? 0,
          cP_Monthly: data.cP_Monthly ?? 0,

          leaseDispositionFee: data.leaseDispositionFee ?? 0,
          leaseMilesAllowed: data.leaseMilesAllowed ?? 0,
          lease_MonthMiles: data.lease_MonthMiles ?? 0,
          leaseMilesPer: data.leaseMilesPer ?? 0,
          lease_SecurityDeposit: data.lease_SecurityDeposit ?? 0,
          leaseEarlyTermFee: data.leaseEarlyTermFee ?? 0, // Added
          leasePurchaseOption: data.leasePurchaseOption || 0, // Added

          nsfFee: data.nsfFee ?? 0,
          cpI_Deductable: data.cpI_Deductable ?? 0,
          repoTitleFees: data.repoTitleFees ?? 0,
          ins_Deductable: data.ins_Deductable ?? 0,

          glbA_OwnerName: data.glbA_OwnerName || "",
          glbA_ShortName: data.glbA_ShortName || "",

          contractInfo: "",
          defaultSCPaidTo: data.defaultSCPaidTo || "",

          maxTotExp: data.maxTotExp ?? 0,
          maxExp: data.maxExp ?? 0,

          mainMsg: data.mainMsg || "",

          useFlDoc: data.useFlDoc ?? false,
          useTXTax: data.useTXTax ?? false,
          useTXInvTax: data.useTXInvTax ?? false,
          invTaxRate: data.invTaxRate ?? 0,

          useYearlyPremium: false,
          rewriteAutoChange: false,
        };

        reset(mappedData);
      } catch (err) {
        console.error("Failed to fetch company defaults:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDefaults();
  }, [companyDetails, reset]);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const handleCloseConfirmationModal = () => setIsConfirmOpen(false);

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return (
      (formState.isDirty ) &&
      currentLocation.pathname !== nextLocation.pathname
    );
  });

  const onSubmit = async (formData: FormValues) => {
    if (!companyDetails?.recId) {
      toast.error("No company selected.");
      return;
    }

    const updateModel: ICompanyDefaults = {
      recId: companyDetails.recId,
      lotType: formData.lotType,
      intType: formData.intType,
      stockOpt: formData.stockOpt,
      nextSkNum: formData.nextSkNum,
      useAcctNum: formData.useAcctNum,
      acctNum: formData.acctNum,
      maxPayPer: formData.maxPayPer,
      defaultGapPaidTo: formData.defaultGapPaidTo,

      def_APR: formData.def_APR,
      def_SP: formData.def_SP,
      def_PMT: formData.def_PMT,
      defDown: formData.defDown,
      defPDown: formData.defPDown,
      defSchedule: formData.defSchedule,
      def_Term: formData.def_Term,

      lateChgUsed: formData.lateChgUsed,
      lateChgPerPmt: formData.lateChgPerPmt,
      lateChgGrace: formData.lateChgGrace,
      lateChgRate: formData.lateChgRate,
      lateChgMin: formData.lateChgMin,
      lateChgMax: formData.lateChgMax,
      lateGraceDays: formData.lateGraceDays,

      cP_Weekly: formData.cP_Weekly,
      cP_BiWeekly: formData.cP_BiWeekly,
      cP_SemiMonthly: formData.cP_SemiMonthly,
      cP_Monthly: formData.cP_Monthly,

      leaseDispositionFee: formData.leaseDispositionFee,
      leaseMilesAllowed: formData.leaseMilesAllowed,
      lease_MonthMiles: formData.lease_MonthMiles,
      leaseMilesPer: formData.leaseMilesPer,
      lease_SecurityDeposit: formData.lease_SecurityDeposit,
      leasePurchaseOption: formData.leasePurchaseOption,
      leaseEarlyTermFee: formData.leaseEarlyTermFee,

      nsfFee: formData.nsfFee,
      cpI_Deductable: formData.cpI_Deductable,
      repoTitleFees: formData.repoTitleFees,
      ins_Deductable: formData.ins_Deductable,

      glbA_OwnerName: formData.glbA_OwnerName,
      glbA_ShortName: formData.glbA_ShortName,

      defaultSCPaidTo: formData.defaultSCPaidTo,

      maxTotExp: formData.maxTotExp,
      maxExp: formData.maxExp,

      mainMsg: formData.mainMsg,

      useFlDoc: formData.useFlDoc,
      useTXTax: formData.useTXTax,
      useTXInvTax: formData.useTXInvTax,
      invTaxRate: formData.invTaxRate ?? 0
    };

    try {
      await companyService.updateCompanyDefaults(updateModel);
      reset(formData); // Mark form as clean
      toast.success("Company defaults updated successfully.");
    } catch (error) {
      console.error(error);
      toast.error("Error updating company defaults.");
    }finally {
      handleCloseConfirmationModal();
    }
  };
  if (isLoading) {
    return <Loader size="large" />;
  }
  const scheduleOptions = [
    { label: "Weekly", value: "Weekly" },
    { label: "Bi-Weekly", value: "Bi-Weekly" },
    { label: "Semi-Monthly", value: "Semi-Monthly" },
    { label: "Monthly", value: "Monthly" },
  ];

  const interestTypeOptions = [
    { label: "Simple", value: "Simple" },
  ];

  const stockOptions = [
    { label: "Relational", value: "Relational" },
    { label: "Sequential", value: "Sequential" },
    { label: "Last 6 Vin", value: "Last 6 Vin" },
    { label: "Input", value: "Input" },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        direction="column"
        spacing={4}
        sx={{
          backgroundColor: BG_HIGHLIGHT,
          padding: 4,
          borderRadius: 2.5,
          boxShadow: 3,
          maxWidth: "1800px", 
          margin: "auto", 
          mt: 2, 
          "& > .MuiGrid-item": {
            paddingLeft: 0, // Remove the left padding
            paddingTop: 0,  // Remove the top padding
          },
        }}
      >
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start" 
        >
          <Typography
            variant="h6" 
            component="h2"
            sx={{
              fontWeight: 700, 
              marginTop: 0, 
            }}
          >
          </Typography>
        </Grid>
      <Grid container direction="column" spacing={2}>
        
            {/* General Defaults Section */}
        <Grid item container alignItems="center">
          <SectionHeader title="General Defaults" />
          <Divider sx={{ flex: 1, ml: 2 }} />
        </Grid>

        {/* Row 1: Type of Dealership / Use FL Doc Stamps */}
        <Grid item>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Controller
                name="lotType"
                control={control}
                render={({ field }) => (
                  <TextInput label="Type of Dealership" {...field} disabled />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                sx={{
                  marginLeft: 0,
                  "& .MuiFormControlLabel-label": {
                    fontSize: "14px",
                    color: "#111827",
                    fontFamily: "Inter, sans-serif",
                    fontWeight: 700
                  },
                }}
                label="Use FL Doc Stamps"
                labelPlacement="start" // label on left, box on right
                control={
                  <Controller
                    name="useFlDoc"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={!!field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Row 2: Interest Type / Use TX Tax */}
        <Grid item>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Controller
                name="intType"
                control={control}
                render={({ field }) => (
                  <DropdownInput
                    {...field}
                    value={field.value}
                    data={interestTypeOptions.map((opt) => opt.value)}
                    onChange={(event) => field.onChange(event.value)}
                    label="Interest Type"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                sx={{
                  marginLeft: 0,
                  "& .MuiFormControlLabel-label": {
                    fontSize: "14px",
                    color: "#111827",
                    fontFamily: "Inter, sans-serif",
                    fontWeight: 700
                  },
                }}
                label="Use TX Tax"
                labelPlacement="start"
                control={
                  <Controller
                    name="useTXTax"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={!!field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Row 3: Stock Options / Use TX Inv. Tax */}
        <Grid item>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Controller
                name="stockOpt"
                control={control}
                render={({ field }) => (
                  <DropdownInput
                    {...field}
                    value={field.value}
                    data={stockOptions.map((opt) => opt.value)}
                    onChange={(event) => field.onChange(event.value)}
                    label="Stock Options"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                sx={{
                  marginLeft: 0,
                  "& .MuiFormControlLabel-label": {
                    fontSize: "14px",
                    color: "#111827",
                    fontFamily: "Inter, sans-serif",
                    fontWeight: 700
                  },
                }}
                label="Use TX Inv. Tax"
                labelPlacement="start"
                control={
                  <Controller
                    name="useTXInvTax"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={!!field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Row 4: Next Stock Number / TX Inv. Tax Rate */}
        <Grid item>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Controller
                name="nextSkNum"
                control={control}
                render={({ field }) => (
                  <TextInput label="Next Stock Number" {...field} />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="invTaxRate"
                control={control}
                render={({ field }) => (
                  <NumberInput label="TX Inv. Tax Rate" {...field} />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Row 5: Use Account Number / Max Payment % */}
        <Grid item>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <FormControlLabel
                sx={{
                  marginLeft: 0,
                  "& .MuiFormControlLabel-label": {
                    fontSize: "14px",
                    color: "#111827",
                    fontFamily: "Inter, sans-serif",
                    fontWeight: 700
                  },
                }}
                label="Use Account Number"
                labelPlacement="start"
                control={
                  <Controller
                    name="useAcctNum"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={!!field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="maxPayPer"
                control={control}
                render={({ field }) => (
                  <NumberInput label="Max Payment %" {...field} />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Row 6: Account Number / GAP Paid To */}
        <Grid item>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Controller
                name="acctNum"
                control={control}
                render={({ field }) => (
                  <TextInput label="Account Number" {...field} />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="defaultGapPaidTo"
                control={control}
                render={({ field }) => (
                  <TextInput label="GAP Paid To" {...field} />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Optionally: Use Yearly Premium / Rewrite Auto Change */}
        <Grid item>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <FormControlLabel
                sx={{
                  marginLeft: 0,
                  "& .MuiFormControlLabel-label": {
                    fontSize: "14px",
                    color: "#111827",
                    fontFamily: "Inter, sans-serif",
                    fontWeight: 700
                  },
                }}
                label="Use Yearly Premium"
                labelPlacement="start"
                control={
                  <Controller
                    name="useYearlyPremium"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={!!field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                sx={{
                  marginLeft: 0,
                  "& .MuiFormControlLabel-label": {
                    fontSize: "14px",
                    color: "#111827",
                    fontFamily: "Inter, sans-serif",
                    fontWeight: 700
                  },
                }}
                label="Rewrite Auto Change"
                labelPlacement="start"
                control={
                  <Controller
                    name="rewriteAutoChange"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={!!field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
              />
            </Grid>
          </Grid>
        </Grid>


        {/* Default Terms Section */}
        <Grid item container alignItems="center">
            <SectionHeader title="Default Terms" />
            <Divider sx={{ flex: 1, ml: 2 }} />
          </Grid>
        {/* Row 1: APR (Left) / Schedule (Right) */}
        <Grid item>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Controller
                name="def_APR"
                control={control}
                render={({ field }) => <NumberInput label="APR" {...field} />}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="defSchedule"
                control={control}
                render={({ field }) => (
                  <DropdownInput
                    {...field}
                    value={field.value}
                    data={scheduleOptions.map((opt) => opt.value)}
                    onChange={(event) => field.onChange(event.value)}
                    label="Schedule"
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Row 2: Sales Price (Left) / Term Months (Right) */}
        <Grid item>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Controller
                name="def_SP"
                control={control}
                render={({ field }) => <NumberInput label="Sales Price" {...field} />}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="def_Term"
                control={control}
                render={({ field }) => <NumberInput label="Term (Months)" {...field} />}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Row 3: Payment Amount (Left) / Down Payment (Right) */}
        <Grid item>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Controller
                name="def_PMT"
                control={control}
                render={({ field }) => <NumberInput label="Payment Amount" {...field} />}
              />
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="subtitle2" fontWeight="bold">
                  Down Payment (Fixed or %)
                </Typography>
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography>$</Typography>
                  <Controller
                    name="defDown"
                    control={control}
                    render={({ field }) => <NumberInput {...field} />}
                  />
                  <Typography>or</Typography>
                  <Controller
                    name="defPDown"
                    control={control}
                    render={({ field }) => <NumberInput {...field} />}
                  />
                  <Typography>%</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {/* Late Defaults Section */}
        <Grid item container alignItems="center">
          <SectionHeader title="Late Defaults" />
          <Divider sx={{ flex: 1, ml: 2 }} />
        </Grid>

        {/* Row 1: Charge Used (Left) / Charge Min (Right) */}
        <Grid item>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <FormControlLabel
                sx={{
                  marginLeft: 0,
                  "& .MuiFormControlLabel-label": {
                    fontSize: "14px",
                    color: "#111827",
                    fontFamily: "Inter, sans-serif",
                    fontWeight: 700
                  },
                }}
                label="Charge Used"
                labelPlacement="start"
                control={
                  <Controller
                    name="lateChgUsed"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={!!field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="lateChgMin"
                control={control}
                render={({ field }) => (
                  <NumberInput label="Charge Min" {...field} />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Row 2: Charge per Payment (Left) / Charge Max (Right) */}
        <Grid item>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <FormControlLabel
                sx={{
                  marginLeft: 0,
                  "& .MuiFormControlLabel-label": {
                    fontSize: "14px",
                    color: "#111827",
                    fontFamily: "Inter, sans-serif",
                    fontWeight: 700
                  },
                }}
                label="Charge per Payment"
                labelPlacement="start"
                control={
                  <Controller
                    name="lateChgPerPmt"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={!!field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="lateChgMax"
                control={control}
                render={({ field }) => (
                  <NumberInput label="Charge Max" {...field} />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Row 3: Charge Grace (Left) / Grace Days (Right) */}
        <Grid item>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <FormControlLabel
                sx={{
                  marginLeft: 0,
                  "& .MuiFormControlLabel-label": {
                    fontSize: "14px",
                    color: "#111827",
                    fontFamily: "Inter, sans-serif",
                    fontWeight: 700
                  },
                }}
                label="Charge Grace"
                labelPlacement="start"
                control={
                  <Controller
                    name="lateChgGrace"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={!!field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="lateGraceDays"
                control={control}
                render={({ field }) => (
                  <NumberInput label="Grace Days" {...field} />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Row 4: Charge Rate (Left) / (Empty) */}
        <Grid item>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Controller
                name="lateChgRate"
                control={control}
                render={({ field }) => (
                  <NumberInput label="Charge Rate" {...field} />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              {/* Empty to maintain layout */}
            </Grid>
          </Grid>
        </Grid>

       {/* CP Defaults Section */}
       <Grid item>
          <SectionHeader title="CP Defaults" />
        </Grid>
        {/* Row 1: Weekly Rate (Left) / Bi-Weekly Rate (Right) */}
        <Grid item>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Controller
                name="cP_Weekly"
                control={control}
                render={({ field }) => <NumberInput label="Weekly Rate" {...field} />}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="cP_BiWeekly"
                control={control}
                render={({ field }) => <NumberInput label="Bi-Weekly Rate" {...field} />}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Row 2: Monthly Rate (Left) / Semi-Monthly Rate (Right) */}
        <Grid item>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Controller
                name="cP_Monthly"
                control={control}
                render={({ field }) => <NumberInput label="Monthly Rate" {...field} />}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="cP_SemiMonthly"
                control={control}
                render={({ field }) => <NumberInput label="Semi-Monthly Rate" {...field} />}
              />
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ marginY: 4 }} />

        {/* Lease Defaults Section */}
        <Grid item>
          <SectionHeader title="Lease Defaults" />
        </Grid>
        {/* Row 1: Disposition Fee (Left) / Excess Miles Cost (Right) */}
        <Grid item>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Controller
                name="leaseDispositionFee"
                control={control}
                render={({ field }) => <NumberInput label="Disposition Fee" {...field} />}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="leaseMilesPer"
                control={control}
                render={({ field }) => <NumberInput label="Excess Miles Cost" {...field} />}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Row 2: Miles Allowed (Left) / Early Term Fee (Right) */}
        <Grid item>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Controller
                name="leaseMilesAllowed"
                control={control}
                render={({ field }) => <NumberInput label="Miles Allowed" {...field} />}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="leaseEarlyTermFee"
                control={control}
                render={({ field }) => <NumberInput label="Early Term Fee" {...field} />}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Row 3: Month Miles (Left) / Security Deposit (Right) */}
        <Grid item>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Controller
                name="lease_MonthMiles"
                control={control}
                render={({ field }) => <NumberInput label="Month Miles" {...field} />}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="lease_SecurityDeposit"
                control={control}
                render={({ field }) => <NumberInput label="Security Deposit" {...field} />}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Row 4: Purchase Option (Left) / Empty (Right) */}
        <Grid item>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Controller
                name="leasePurchaseOption"
                control={control}
                render={({ field }) => <NumberInput label="Purchase Option" {...field} />}
              />
            </Grid>
            <Grid item xs={6}>
              {/* Empty to maintain layout */}
            </Grid>
          </Grid>
        </Grid>


        {/* Default Fees Section */}
        <Grid item>
          <SectionHeader title="Default Fees" />
        </Grid>
        {/* Row 1: NSF (Left) / CPI Deductible (Right) */}
        <Grid item>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Controller
                name="nsfFee"
                control={control}
                render={({ field }) => <NumberInput label="NSF Fee" {...field} />}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="cpI_Deductable"
                control={control}
                render={({ field }) => <NumberInput label="CPI Deductible" {...field} />}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Row 2: Repo Title (Left) / Insurance Deductible (Right) */}
        <Grid item>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Controller
                name="repoTitleFees"
                control={control}
                render={({ field }) => <NumberInput label="Repo Title Fees" {...field} />}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="ins_Deductable"
                control={control}
                render={({ field }) => <NumberInput label="Insurance Deductible" {...field} />}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* GLBA Defaults Section */}
        <Grid item container alignItems="center">
            <SectionHeader title="GLBA Defaults" />
            <Divider sx={{ flex: 1, ml: 2 }} />
          </Grid>
        {/* Row: Owner's Name (Left) / Company Short Name (Right) */}
        <Grid item>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Controller
                name="glbA_OwnerName"
                control={control}
                render={({ field }) => <TextInput label="Owner's Name" {...field} />}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="glbA_ShortName"
                control={control}
                render={({ field }) => <TextInput label="Company Short Name" {...field} />}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Service Defaults Section */}
        <Grid item container alignItems="center">
            <SectionHeader title="Service Defaults" />
            <Divider sx={{ flex: 1, ml: 2 }} />
          </Grid>
        {/* Row: Contract Paid To (Left) / Contract Info (Right) */}
        <Grid item>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Controller
                name="defaultSCPaidTo"
                control={control}
                render={({ field }) => <TextInput label="Contract Paid To" {...field} />}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="contractInfo"
                control={control}
                render={({ field }) => <TextInput label="Contract Info" {...field} />}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Ecom Alert Defaults Section */}
        <Grid item container alignItems="center">
            <SectionHeader title="Ecom Alert Defaults" />
            <Divider sx={{ flex: 1, ml: 2 }} />
          </Grid>
        {/* Row: Max Exp. Amount (Left) / Total Exp. Amount (Right) */}
        <Grid item>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Controller
                name="maxExp"
                control={control}
                render={({ field }) => <NumberInput label="Max Exp. Amount" {...field} />}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="maxTotExp"
                control={control}
                render={({ field }) => <NumberInput label="Total Exp. Amount" {...field} />}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Other Defaults Section */}
        <Grid item container alignItems="center">
            <SectionHeader title="Other Defaults" />
            <Divider sx={{ flex: 1, ml: 2 }} />
          </Grid>
        {/* Row: Note for All Payment Receipts (Left) / Empty (Right) */}
        <Grid item>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Controller
                name="mainMsg"
                control={control}
                render={({ field }) => <TextInput label="Note for All Payment Receipts" {...field} />}
              />
            </Grid>
            <Grid item xs={6}>
              
              {/* Empty to maintain layout */}
            </Grid>
          </Grid>
        </Grid>
        
        {/* Buttons */}
        <Grid container justifyContent="flex-end" spacing={2} sx={{ mt: 4 }}>
          <Grid item>
            <Button type="submit" label="Save" buttonStyle={{ width: "140px" }} />
          </Grid>
          <Grid item>
            <Button label="Cancel" color="secondary" buttonStyle={{ width: "140px" }} />
          </Grid>
        </Grid>
      </Grid> 
      </Grid>
        <NavBlock blocker={blocker} />
        <ConfirmationModal
          isOpen={isConfirmOpen}
          setIsOpen={setIsConfirmOpen}
          title="Confirm Submission"
          message="You are about to save company details. Do you want to proceed?"
          onConfirm={handleSubmit(onSubmit)}
          isSubmitting={formState.isSubmitting}
        />
</form>  
  );
};

export default CompanyDefaults;
