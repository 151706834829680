import React, { FC, useEffect, useState } from "react";
import {
  Grid,
  GridColumn,
  GridNoRecords,
  GridToolbar,
  Input,
  Loader,
} from "@progress/kendo-react-all";
import { useInventoryDetailCtx } from "../../../InventoryDetailProvider";
import { Button } from "@/components";
import { CheckBoxCell, CommentCell, DeleteCell } from "./cellTypes";
import styles from "./CheckInGrid.module.scss";
import { CheckInItem } from "@/interfaces";
import { inventoryService } from "@/services/inventoryService";
import InspectorSelector from "./InspectorSelector";
import stylesContainer from "../CheckInOptions.module.scss";

const CheckInGrid: FC = () => {
  const isLoading = useInventoryDetailCtx((s) => s.isLoading);
  const checkInList = useInventoryDetailCtx((s) => s.checkInList);
  const setCheckInList = useInventoryDetailCtx((s) => s.setCheckInList);
  const setCheckInState = useInventoryDetailCtx((s) => s.setCheckInState);
  const inventoryVehicle = useInventoryDetailCtx((s) => s.inventoryVehicle);
  const setIsDeleting = useInventoryDetailCtx((s) => s.setIsDeleting);
  const [newItemValue, setNewItemValue] = useState("");
  const [maxHeight, setMaxHeight] = useState("");

  const addRow = async () => {
    const newSorder = Math.max(...checkInList.map((item: any) => item.sOrder)) + 1;
    const rowToAdd = new CheckInItem(
      undefined,
      inventoryVehicle.orgId,
      null,
      null,
      inventoryVehicle.recId,
      newSorder,
      newItemValue,
      false,
      "",
      false,
      null,
      null,
      ""
    );
    const res = await inventoryService.insertCheckInItem(rowToAdd);
    setCheckInList(res);
    setCheckInState(res);
    setNewItemValue(""); // Clear newItemValue after adding the row
  };

  const handleDelete = async (recIdToDelete: number) => {
    const res = await inventoryService.deleteSingleCheckInOption(
      Number(recIdToDelete),
      Number(inventoryVehicle.recId)
    );
    setIsDeleting(true);
    setCheckInState(res);
    setCheckInList(res);
  };

  const handleRefresh = async () => {
    const res = await inventoryService.repopulateCheckInOptions(
      Number(inventoryVehicle.recId),
      Number(inventoryVehicle.orgId)
    );
    setCheckInState(res);
    setCheckInList(res);
  };

  const handleDataChange = (event: any) => {
    // Handle data changes here, such as updating the state
    setCheckInList(event.data);
  };

  useEffect(() => {
    const handleResize = () => {
      const screenHeight = window.innerHeight;

      if (screenHeight <= 500) {
        setMaxHeight("30vh");
      } else if (screenHeight > 500 && screenHeight <= 650) {
        setMaxHeight("45vh");
      } else {
        setMaxHeight("55vh");
      }
    };

    // Call handleResize once to set initial maxHeight
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={stylesContainer.vehicleDetailsCard}>
      <div className={stylesContainer.formWrapper}>
        <Grid
          data={checkInList}
          editField="inEdit"
          onItemChange={handleDataChange}
        >
          <GridColumn field="ckItem" title="Option" editable={false} />

          {/* Needs Attention Column */}
          <GridColumn
            field="fix"
            title="Needs Attention"
            editable={true}
            cell={CheckBoxCell}
            headerClassName={styles.centeredHeader}
            width="150px"
          />

          {/* Comments Column */}
          <GridColumn field="comments" title="Comments" editable={true} cell={CommentCell} />

          {/* Delete Column */}
          <GridColumn
            field="delete"
            title="Delete"
            editable={true}
            cell={(props) => <DeleteCell {...props} onDelete={handleDelete} />}
            headerClassName={styles.centeredHeader}
            width="100px"
          />

          <GridNoRecords>
            {isLoading ? <Loader size="large" /> : "No Check In Options Found"}
          </GridNoRecords>

          {/* Toolbar for Adding or Refreshing Rows */}
          <GridToolbar>
            <InspectorSelector />
            <div style={{ flex: 1 }}></div>
            <Input
              value={newItemValue}
              onChange={(e) => setNewItemValue(e.target.value as string)}
              placeholder="Enter item value"
            />
            <Button
              secondary
              label="Add Row"
              themeColor="dark"
              onClick={addRow}
              disabled={!newItemValue.trim()}
            />
            <Button secondary label="Repopulate" themeColor="dark" onClick={handleRefresh} />
          </GridToolbar>
        </Grid>
      </div>
    </div>
  );
};

export default CheckInGrid;
