import { FC } from "react";
import { Controller } from "react-hook-form";
// kendo
import { DropdownInput } from "@/components/inputs/dropdown/DropdownInput";
// components
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { NumberInput } from "@/components";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
// state
import { useAddOnsSalesTaxCtx } from "../AddOnsSalesTaxProvider";
import { CompanyAddOnsTaxForm } from "@/interfaces/CtxAdd";

// Unified Styles for Labels
const gridLabelStyle = {
  fontSize: "15px",
  fontWeight: "700",
  display: "flex",
  alignItems: "center",
  fontStyle: "italic",
  paddingBottom: "8px",
  minHeight: "38px", // Makes all label rows uniform in height
};

const containerInputStyle = {
  width: "100%",
  paddingTop: "1px",
  paddingBottom: "1px",
};

const TaxName: FC = () => (
  <Grid container direction="column" spacing={2}>
    <Grid sx={{ ...gridLabelStyle, visibility: "hidden" }}>Where</Grid>
    {["State", "County", "City", "Location"].map((label, idx) => (
      <Grid key={idx} sx={{ ...gridLabelStyle, padding: "15.75px 8px 15.75px 8px" }}>
        {label}
      </Grid>
    ))}
  </Grid>
);

const TaxRate: FC = () => {
  const { control } = useAddOnsSalesTaxCtx((s) => s.addOnsSalesTaxForm);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid sx={gridLabelStyle}>Rate</Grid>
      <Grid key={"stateRate"}>
        <Controller
          name={"stateRate"}
          control={control}
          render={({ field }) => (
            <NumberInput isPercentage isPercentageLarge rightAlignInput {...field} />
          )}
        />
      </Grid>
      <Grid key={"countyRate"}>
        <Controller
          name={"countyRate"}
          control={control}
          render={({ field }) => (
            <NumberInput isPercentage isPercentageLarge rightAlignInput {...field} />
          )}
        />
      </Grid>
      <Grid key={"cityRate"}>
        <Controller
          name={"cityRate"}
          control={control}
          render={({ field }) => (
            <NumberInput isPercentage isPercentageLarge rightAlignInput {...field} />
          )}
        />
      </Grid>
      <Grid key={"locRate"}>
        <Controller
          name={"locRate"}
          control={control}
          render={({ field }) => (
            <NumberInput isPercentage isPercentageLarge rightAlignInput {...field} />
          )}
        />
      </Grid>
    </Grid>
  );
};

const TaxOn: FC = () => {
  const { control } = useAddOnsSalesTaxCtx((s) => s.addOnsSalesTaxForm);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid sx={gridLabelStyle}>On</Grid>
      {["stateOn", "countyOn", "cityOn", "locOn"].map((name) => (
        <Grid key={name}>
          <Controller
            name={name as keyof CompanyAddOnsTaxForm}
            control={control}
            render={({ field }) => (
              <DropdownInput
                {...field}
                value={field.value}
                data={["Sales Price", "Sales Price Less Trade"]}
              />
            )}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const TaxMin: FC = () => {
  const { control } = useAddOnsSalesTaxCtx((s) => s.addOnsSalesTaxForm);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid sx={gridLabelStyle}>Min</Grid>
      <Grid key={"stateMin"}>
        <Controller
          name={"stateMin"}
          control={control}
          render={({ field }) => (
            <CurrencyInput {...field} rightAlignInput containerStyle={containerInputStyle} />
          )}
        />
      </Grid>
      <Grid key={"countyMin"}>
        <Controller
          name={"countyMin"}
          control={control}
          render={({ field }) => (
            <CurrencyInput {...field} rightAlignInput containerStyle={containerInputStyle} />
          )}
        />
      </Grid>
      <Grid key={"cityMin"}>
        <Controller
          name={"cityMin"}
          control={control}
          render={({ field }) => (
            <CurrencyInput {...field} rightAlignInput containerStyle={containerInputStyle} />
          )}
        />
      </Grid>
      <Grid key={"locMin"}>
        <Controller
          name={"locMin"}
          control={control}
          render={({ field }) => (
            <CurrencyInput {...field} rightAlignInput containerStyle={containerInputStyle} />
          )}
        />
      </Grid>
    </Grid>
  );
};

const TaxMax: FC = () => {
  const { control } = useAddOnsSalesTaxCtx((s) => s.addOnsSalesTaxForm);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid sx={gridLabelStyle}>Max</Grid>
      <Grid key={"stateMax"}>
        <Controller
          name={"stateMax"}
          control={control}
          render={({ field }) => (
            <CurrencyInput {...field} rightAlignInput containerStyle={containerInputStyle} />
          )}
        />
      </Grid>
      <Grid key={"countyMax"}>
        <Controller
          name={"countyMax"}
          control={control}
          render={({ field }) => (
            <CurrencyInput {...field} rightAlignInput containerStyle={containerInputStyle} />
          )}
        />
      </Grid>
      <Grid key={"cityMax"}>
        <Controller
          name={"cityMax"}
          control={control}
          render={({ field }) => (
            <CurrencyInput {...field} rightAlignInput containerStyle={containerInputStyle} />
          )}
        />
      </Grid>
      <Grid key={"locMax"}>
        <Controller
          name={"locMax"}
          control={control}
          render={({ field }) => (
            <CurrencyInput {...field} rightAlignInput containerStyle={containerInputStyle} />
          )}
        />
      </Grid>
    </Grid>
  );
};

const TaxMaxTrade: FC = () => {
  const { control } = useAddOnsSalesTaxCtx((s) => s.addOnsSalesTaxForm);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid sx={gridLabelStyle}>Max Trade</Grid>
      <Grid>
        <Controller
          name="maxTradeAllow"
          control={control}
          render={({ field }) => (
            <CurrencyInput {...field} rightAlignInput containerStyle={containerInputStyle} />
          )}
        />
      </Grid>
    </Grid>
  );
};

/** ###  */
const TaxFields: FC = () => [
  <Grid container direction="row" spacing={2} flex={1}>
    <Grid sx={{ maxWidth: "150px" }} flex={1}>
      <TaxName />
    </Grid>
    <Grid xs={0.75} flex={1}>
      <TaxRate />
    </Grid>
    <Grid xs={1.5} flex={1}>
      <TaxOn />
    </Grid>
    <Grid xs={0.75} flex={1}>
      <TaxMax />
    </Grid>
    <Grid xs={0.75} flex={1}>
      <TaxMin />
    </Grid>
    <Grid xs={0.75} flex={1}>
      <TaxMaxTrade />
    </Grid>
  </Grid>,
];

export default TaxFields;
