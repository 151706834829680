import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
// mui
import DateInput from "@/mui/components/form/MuiKendoDateField";
import Grid from "@mui/material/Unstable_Grid2";
// kendo
import {   Button, Checkbox, DropdownInput, NumberInput, } from "@/components";
import { Spacer } from "@/components/spacer/Spacer";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import { formatCurrency, roundToTwoDecimals, scheduleData } from '@/utils/helpers/general';
import { calculateMonthlyRentCharge, calculateMonthlyDepreciation } from '@/utils/helpers/sales';
import { dateGTETodayValidation } from '@/utils/helpers/formValidation';
import { MultipleInputRow } from '../../../multipleInputRow/MultipleInputRow';
import { salesActions, useSalesSelector } from '@/features/Sales/salesSlice';
import { systemService } from '@/services/systemService';
import { salesService } from '@/services/salesService';
import { useAuthSelector } from '@/features/auth/authSlice';
import { useAppDispatch } from '@/store/store';
import { CustomerTaxInfo, QuoteData } from '@/interfaces';
import { Loader } from '@progress/kendo-react-all';
import { getQuoteData } from '@/features/Sales/salesActionCreator';
import { AppStatus } from '@/features/Sales/enums';
// style
import salesTabStyles from '../../SalesTab.module.scss';
import { useAuthCtx } from '@/AppProviders/AuthProvider';
import { BG_HIGHLIGHT } from "@/mui/theme/colors";

const formDefaultValues = {
  dateOfSale: dayjs().format('YYYY-MM-DD'),
  leasePrice: 0,
  customField1: 0,
  customField2: 0,
  customField3: 0,
  customField4: 0,
  customField5: 0,
  serviceContract: 0,
  addedSalesTax: 0,
  capitalizedCost: 0,
  tradeAllowance: 0,
  tradePayoff: 0,
  deferred: 0,
  cashDown: 0,
  capCostReduction: 0,
  residual: 0,
  residualRate: 0.35,
  adjCapCost: 0,
  salesTaxOnDown: 0,
  moneyDueAtInceptionField1: 0,
  moneyDueAtInceptionField2: 0,
  moneyDueAtInceptionField3: 0,
  securityDeposit: 0,
  dueToday: 0,
  apr: 0,
  dateStarted: '',
  schedule: 'Weekly',
  leasePayment: 0,
  term: 0,
  numberOfPayments: 0,
  basePayment: 0,
  salesTax: 0,
  taxRate: 0,
  totalDepreciation: 0,
  totalRentCharge: 0,
  guaranteedRefi: false,
  deferredDownPlan1: 0,
  deferredDownPlan2: 0,
  deferredDownPlan3: 0,
  deferredDownPlan4: 0,
  deferredDownDate1: '',
  deferredDownDate2: '',
  deferredDownDate3: '',
  deferredDownDate4: '',
  deferredDownWithTax: 0,
};

export const LeaseTerm = () => {
  const { quoteData, saleData, saleDataLoading, quoteDataLoading } = useSalesSelector((s) => s);
  const { compId } = useAuthSelector((s) => s);
  const selectedCompanyOrgId = useAuthCtx((s) => s.selectedCompany?.orgId);
  const disableDate = !(selectedCompanyOrgId === 2 || selectedCompanyOrgId === 10102 || selectedCompanyOrgId === 10112); //TODO: Get UserSecurities for this flag -- DMS_BackDateSale
  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const appRecId = Number(params.id);
  const [customField1Name, setCustomField1Name] = useState('');
  const [customField2Name, setCustomField2Name] = useState('');
  const [customField3Name, setCustomField3Name] = useState('');
  const [customField4Name, setCustomField4Name] = useState('');
  const [customField5Name, setCustomField5Name] = useState('');
  const [leaseQuoteData, setLeaseQuoteData] = useState<QuoteData | undefined>(undefined);
  const [moneyDueAtInceptionField1Name, setMoneyDueAtInceptionField1Name] = useState('');
  const [moneyDueAtInceptionField2Name, setMoneyDueAtInceptionField2Name] = useState('');
  const [moneyDueAtInceptionField3Name, setMoneyDueAtInceptionField3Name] = useState('');
  const [paymentPlan, setPaymentPlan] = useState('');
  const [isResidual, setIsResidual] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [totalTaxRate, setTotalTaxRate] = useState(0);
  const [quotePrinting, setQuotePrinting] = useState(false);
  const [pageError, setPageError] = useState('');

  const {
    control,
    watch,
    setValue,
    reset,
    handleSubmit,
    getValues,
    trigger,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: formDefaultValues,
  });

  const leasePrice = watch('leasePrice');
  const customField1 = watch('customField1');
  const customField2 = watch('customField2');
  const customField3 = watch('customField3');
  const customField4 = watch('customField4');
  const customField5 = watch('customField5');
  const serviceContract = watch('serviceContract');
  const moneyDueAtInceptionField1 = watch('moneyDueAtInceptionField1');
  const moneyDueAtInceptionField2 = watch('moneyDueAtInceptionField2');
  const moneyDueAtInceptionField3 = watch('moneyDueAtInceptionField3');
  const securityDeposit = watch('securityDeposit');
  const dueToday = watch('dueToday');
  const capitalizedCost = watch('capitalizedCost');
  const capCostReduction = watch('capCostReduction');
  const schedule = watch('schedule');
  const addedSalesTax = watch('addedSalesTax');
  const residual = watch('residual');
  const numberOfPayments = watch('numberOfPayments');
  const leasePayment = watch('leasePayment');
  const dateStarted = watch('dateStarted');

  const fieldOnBlur = async (isTerm?: boolean, newNumberOfPayments?: number) => {
    const formValues = getValues();
    const result = await salesService.leaseSaleCalc({
      salesPrice: formValues.leasePrice,
      adminFee: formValues.customField3,
      otherFee: formValues.customField2,
      licReg: formValues.moneyDueAtInceptionField2,
      title: formValues.moneyDueAtInceptionField1,
      dueToday: formValues.dueToday,
      // Stored procedure needs to take whole number instead of percentage
      intRate: formValues.apr * 100,
      schedule: formValues.schedule,
      leasePayment: formValues.leasePayment,
      term: Math.round(formValues.term),
      numberOfPayments: newNumberOfPayments ? newNumberOfPayments : formValues.numberOfPayments,
      compId: compId!,
      zip: saleData.buyer?.b_ZIP || '',
      defDown: formValues.deferred,
      tradeAllow: formValues.tradeAllowance,
      tradePayoff: formValues.tradePayoff,
      // Stored procedure needs to take whole number instead of percentage
      resRate: formValues.residualRate * 100,
      addon4: formValues.moneyDueAtInceptionField3,
      addon5: formValues.customField1,
      addon6: formValues.customField2,
      addon7: formValues.customField3,
      addon8: formValues.customField4,
      addon9: formValues.customField5,
      addonSC: formValues.serviceContract,
      securityDeposit: formValues.securityDeposit,
    });

    setValue('cashDown', result.cashNow);
    setValue('salesTaxOnDown', result.taxDown);
    !isResidual && setValue('residual', result.baseRes);
    setValue('basePayment', result.leaseChg + result.leaseDep);
    setValue('salesTax', result.leaseTax);
    setValue('numberOfPayments', result.numPmts);
    setValue('totalDepreciation', result.leaseDep * result.numPmts);
    setValue('totalRentCharge', result.leaseChg * result.numPmts);
    const vddPd = formValues.deferred - result.newDefDown;
    setValue(
      'deferredDownWithTax',
      roundToTwoDecimals((formValues.deferred - vddPd) / (1 + result.taxRate))
    );
    setValue('addedSalesTax', result.totalTax);
    setValue('leasePayment', result.leaseChg + result.leaseDep + result.leaseTax);
    setValue(
      'deferred',
      formValues.deferredDownPlan1 +
        formValues.deferredDownPlan2 +
        formValues.deferredDownPlan3 +
        formValues.deferredDownPlan4
    );
    let leaseTerm = 0;
    let newNumPayments = 0;
    switch (formValues.schedule) {
      case 'Weekly':
        leaseTerm = roundToTwoDecimals(result.numPmts / (4 + 1 / 3));
        newNumPayments = Math.round(formValues.term * 4.3);
        break;
      case 'Bi-Weekly':
        leaseTerm = roundToTwoDecimals(result.numPmts / (2 + 1 / 6));
        newNumPayments = Math.round(formValues.term * 2.15);
        break;
      case 'Semi-Monthly':
        leaseTerm = result.numPmts / 2;
        newNumPayments = Math.round(formValues.term * 2);
        break;
      default:
        leaseTerm = result.numPmts;
        newNumPayments = Math.round(formValues.term);
        break;
    }
    if (!isTerm) {
      setValue('term', leaseTerm);
    } else {
      setValue('numberOfPayments', newNumPayments);
      fieldOnBlur(false, newNumPayments);
    }
  };

  const initData = async () => {
    try {
      setMoneyDueAtInceptionField1Name(leaseQuoteData?.adD2 || '');
      setMoneyDueAtInceptionField2Name(leaseQuoteData?.adD3 || '');
      setMoneyDueAtInceptionField3Name(leaseQuoteData?.adD4 || '');
      setCustomField1Name(leaseQuoteData?.adD5 || '');
      setCustomField2Name(leaseQuoteData?.adD6 || '');
      setCustomField3Name(leaseQuoteData?.adD7 || '');
      setCustomField4Name(leaseQuoteData?.adD8 || '');
      setCustomField5Name(leaseQuoteData?.adD9 || '');
      const dueToday =
        (leaseQuoteData?.pricE2 || 0) +
        (leaseQuoteData?.pricE3 || 0) +
        (leaseQuoteData?.pricE4 || 0) +
        (leaseQuoteData?.lease_SecurityDeposit || 0);
      const aprRate = (leaseQuoteData?.def_APR || 0) / 100;

      let customerTaxInfo: CustomerTaxInfo | undefined;
      if (saleData.buyer?.b_ZIP) {
        customerTaxInfo = await salesService.getCustomerTaxInfo(saleData.buyer?.b_ZIP);
      }

      const totalTaxRate = customerTaxInfo
        ? customerTaxInfo!.taxRate!
        : leaseQuoteData?.totalTaxRate || 0;

      reset({
        dateOfSale: dayjs().format('YYYY-MM-DD'),
        leasePrice: saleData.sale?.salesPrice || 0,
        customField1:
          saleData.sale?.addon5 !== undefined && saleData.sale?.addon5 !== null
            ? saleData.sale?.addon5
            : leaseQuoteData?.pricE5,
        customField2:
          saleData.sale?.addon6 !== undefined && saleData.sale?.addon6 !== null
            ? saleData.sale?.addon6
            : leaseQuoteData?.pricE6,
        customField3:
          saleData.sale?.addon7 !== undefined && saleData.sale?.addon7 !== null
            ? saleData.sale?.addon7
            : leaseQuoteData?.pricE7,
        customField4:
          saleData.sale?.addon8 !== undefined && saleData.sale?.addon8 !== null
            ? saleData.sale?.addon8
            : leaseQuoteData?.pricE8,
        customField5:
          saleData.sale?.addon9 !== undefined && saleData.sale?.addon9 !== null
            ? saleData.sale?.addon9
            : leaseQuoteData?.pricE9,
        serviceContract:
          saleData.sale?.servCont !== undefined && saleData.sale?.servCont !== null
            ? saleData.sale?.servCont
            : leaseQuoteData?.pricE10 !== undefined && leaseQuoteData?.pricE10 !== null
            ? leaseQuoteData?.pricE10
            : 0,
        addedSalesTax: /*saleData.sale?.ltStax ||*/ 0,
        capitalizedCost: saleData.sale?.grossCapCost || 0,
        tradeAllowance: saleData.sale?.tradeAllow || 0,
        tradePayoff: saleData.sale?.tradePayoff || 0,
        deferred: saleData.sale?.defDown || 0,
        cashDown: saleData.sale?.cashDown || 0,
        capCostReduction: saleData.sale?.totalDown || 0,
        residual: saleData.sale?.residual || 0,
        residualRate: (saleData.sale?.resRate || 0) / 100 || 0.35,
        adjCapCost: saleData.sale?.amtFin || 0,
        salesTaxOnDown: saleData.sale?.lDownTax || 0,
        moneyDueAtInceptionField1:
          saleData.sale?.title !== undefined || saleData.sale?.title !== null
            ? saleData.sale?.title
            : leaseQuoteData?.pricE2,
        moneyDueAtInceptionField2:
          saleData.sale?.registration !== undefined || saleData.sale?.registration !== null
            ? saleData.sale?.registration
            : leaseQuoteData?.pricE3,
        moneyDueAtInceptionField3:
          saleData.sale?.addon4 !== undefined || saleData.sale?.addon4 !== null
            ? saleData.sale?.addon4
            : leaseQuoteData?.pricE4,
        securityDeposit: saleData.sale?.secDeposit ?? leaseQuoteData?.lease_SecurityDeposit,
        dueToday: saleData.sale?.cod || dueToday,
        apr: saleData.sale?.intRate ? (saleData.sale?.intRate || 0) / 100 : aprRate,
        dateStarted: saleData.sale?.firstpmtdue
          ? dayjs(saleData.sale.firstpmtdue).utc().format('YYYY-MM-DD')
          : '',
        schedule: saleData.sale?.sched || 'Weekly',
        leasePayment: saleData.sale?.pmtAmt || 0,
        term: saleData.sale?.term || 36,
        numberOfPayments: saleData.sale?.numPmts || 157,
        basePayment: 0,
        salesTax: saleData.sale?.lStax || 0,
        taxRate: totalTaxRate,
        totalDepreciation: saleData.sale?.ltDepr || 0,
        totalRentCharge: saleData.sale?.ltRent || 0,
        guaranteedRefi: saleData.sale?.rfGuarantee || false,
        deferredDownPlan1: saleData.sale?.ddAmt1 || 0,
        deferredDownPlan2: saleData.sale?.ddAmt2 || 0,
        deferredDownPlan3: saleData.sale?.ddAmt3 || 0,
        deferredDownPlan4: saleData.sale?.ddAmt4 || 0,
        deferredDownDate1: saleData.sale?.ddDue1
          ? dayjs(saleData.sale.ddDue1).utc().format('YYYY-MM-DD')
          : '',
        deferredDownDate2: saleData.sale?.ddDue2
          ? dayjs(saleData.sale.ddDue2).utc().format('YYYY-MM-DD')
          : '',
        deferredDownDate3: saleData.sale?.ddDue3
          ? dayjs(saleData.sale.ddDue3).utc().format('YYYY-MM-DD')
          : '',
        deferredDownDate4: saleData.sale?.ddDue4
          ? dayjs(saleData.sale.ddDue4).utc().format('YYYY-MM-DD')
          : '',
      });

      setTotalTaxRate(totalTaxRate);
      fieldOnBlur();
    } catch (err) {
      setPageError('Unable to load data for terms');
    }
  };

  const printQuote = async () => {
    setQuotePrinting(true);
    try {
      if (isDirty) {
        toast.error('Form has updates that must be submitted before printing quote');
        return;
      }
      const validForm = await trigger();
      if (!validForm) {
        toast.error('Quote has errors, please review');
        return;
      }
      await fieldOnBlur();
      await updateLeaseTerms(getValues());
      const formIds = await systemService.getFormIds(saleData.sale!.compid!);
      const quoteUrl = await salesService.printSalesDoc({
        formIds: [formIds.company.formQuoteLSId!],
        appRecId,
      });
      window.open(quoteUrl);
      await salesService.setHasQuote(appRecId);
      dispatch(salesActions.setHasQuote(true));
    } catch (err) {
      toast.error('Unable to print quote');
    } finally {
      setQuotePrinting(false);
    }
  };

  useEffect(() => {
    dispatch(getQuoteData(compId!));
  }, []);

  useEffect(() => {
    if (!quoteData || !quoteData.length) return;
    const leaseQuoteData = quoteData.find((value) => value.stype === 'Lease Sale');
    setLeaseQuoteData(leaseQuoteData);
  }, [quoteData]);

  useEffect(() => {
    if (!leaseQuoteData) return;
    initData();
  }, [leaseQuoteData]);

  useEffect(() => {
    const capCost =
      leasePrice +
      customField1 +
      customField2 +
      customField3 +
      customField4 +
      customField5 +
      serviceContract +
      addedSalesTax;
    setValue('capitalizedCost', capCost);
  }, [
    leasePrice,
    customField1,
    customField2,
    customField3,
    customField4,
    customField5,
    serviceContract,
    addedSalesTax,
  ]);

  // Calculate cap cost reduction
  useEffect(() => {
    const capCostReduction =
      watch('tradeAllowance') -
      watch('tradePayoff') +
      watch('deferredDownWithTax') +
      watch('cashDown');

    setValue('capCostReduction', capCostReduction);
  }, [
    watch('tradeAllowance'),
    watch('tradePayoff'),
    watch('deferredDownWithTax'),
    watch('cashDown'),
  ]);

  // Calculate Due Today and cash down
  useEffect(() => {
    const moneyDueAtInceptionFees =
      moneyDueAtInceptionField1 +
      moneyDueAtInceptionField2 +
      moneyDueAtInceptionField3 +
      securityDeposit;

    if (dueToday < moneyDueAtInceptionFees) {
      setValue('dueToday', moneyDueAtInceptionFees);
    }
    setValue('cashDown', dueToday - moneyDueAtInceptionFees);
  }, [
    moneyDueAtInceptionField1,
    moneyDueAtInceptionField2,
    moneyDueAtInceptionField3,
    securityDeposit,
    dueToday,
  ]);

  // Calculate Adjusted Cap Cost
  useEffect(() => {
    const adjCapCost = capitalizedCost - capCostReduction;

    setValue('adjCapCost', adjCapCost);
  }, [capitalizedCost, capCostReduction]);

  // Set Payment Plan
  useEffect(() => {
    setPaymentPlan(
      `${numberOfPayments} ${schedule} Payments of ${formatCurrency(leasePayment)} Beg. ${
        dateStarted ? dayjs(dateStarted).format('MM/DD/YYYY') : ''
      }`
    );
  }, [numberOfPayments, schedule, leasePayment, dateStarted]);

  // Calculate residuals
  useEffect(() => {
    if (isResidual) {
      leasePrice
        ? setValue('residualRate', residual / leasePrice)
        : setValue('residualRate', undefined as unknown as number);
    }
  }, [isResidual, residual, leasePrice]);

  const updateLeaseTerms = async (data: typeof formDefaultValues) => {
    setSubmitLoading(true);
    try {
      const res = await salesService.updateLeaseTerms({
        appRecId: appRecId,
        compId: compId!,
        saleDate: data.dateOfSale !== 'Invalid Date' ? data.dateOfSale : null,
        salesPrice: roundToTwoDecimals(data.leasePrice),
        totalTax: roundToTwoDecimals(data.addedSalesTax),
        customField1: roundToTwoDecimals(data.customField1),
        customField2: roundToTwoDecimals(data.customField2),
        customField3: roundToTwoDecimals(data.customField3),
        customField4: roundToTwoDecimals(data.customField4),
        customField5: roundToTwoDecimals(data.customField5),
        serviceContract: roundToTwoDecimals(data.serviceContract),
        capCost: roundToTwoDecimals(data.capitalizedCost),
        tradeAllowance: roundToTwoDecimals(data.tradeAllowance),
        tradePayoff: roundToTwoDecimals(data.tradePayoff),
        cashDown: roundToTwoDecimals(data.cashDown),
        totalDown: roundToTwoDecimals(data.capCostReduction),
        downTax: roundToTwoDecimals(data.salesTaxOnDown),
        title: roundToTwoDecimals(data.moneyDueAtInceptionField1),
        registration: roundToTwoDecimals(data.moneyDueAtInceptionField2),
        addon4: roundToTwoDecimals(data.moneyDueAtInceptionField3),
        securityDeposit: roundToTwoDecimals(data.securityDeposit),
        dueAtLeaseSigning: roundToTwoDecimals(data.dueToday),
        interestRate: roundToTwoDecimals(Number((data.apr * 100).toFixed(2))),
        firstPaymentDue:
          data.dateStarted && data.dateStarted !== 'Invalid Date' ? data.dateStarted : null,
        paymentSchedule: data.schedule,
        term: roundToTwoDecimals(data.term),
        numberOfPayments: roundToTwoDecimals(data.numberOfPayments),
        paymentAmount: roundToTwoDecimals(data.leasePayment),
        salesTax: roundToTwoDecimals(data.salesTax),
        taxRate: roundToTwoDecimals(data.taxRate),
        residual: roundToTwoDecimals(data.residual),
        residualRate: roundToTwoDecimals(data.residualRate * 100),
        adjustedCapCost: roundToTwoDecimals(data.adjCapCost),
        depreciation: roundToTwoDecimals(
          calculateMonthlyDepreciation(data.adjCapCost, data.residual, data.term)
        ),
        rentCharge: roundToTwoDecimals(
          calculateMonthlyRentCharge(data.adjCapCost, data.residual, data.apr)
        ),
        totalDepreciation: roundToTwoDecimals(data.totalDepreciation),
        totalRentCharge: roundToTwoDecimals(data.totalRentCharge),
        deferredDownAmount1: roundToTwoDecimals(data.deferredDownPlan1),
        deferredDownAmount2: roundToTwoDecimals(data.deferredDownPlan2),
        deferredDownAmount3: roundToTwoDecimals(data.deferredDownPlan3),
        deferredDownAmount4: roundToTwoDecimals(data.deferredDownPlan4),
        deferredDownDue1:
          data.deferredDownDate1 && data.deferredDownDate1 !== 'Invalid Date'
            ? data.deferredDownDate1
            : null,
        deferredDownDue2:
          data.deferredDownDate2 && data.deferredDownDate2 !== 'Invalid Date'
            ? data.deferredDownDate2
            : null,
        deferredDownDue3:
          data.deferredDownDate3 && data.deferredDownDate3 !== 'Invalid Date'
            ? data.deferredDownDate3
            : null,
        deferredDownDue4:
          data.deferredDownDate4 && data.deferredDownDate4 !== 'Invalid Date'
            ? data.deferredDownDate4
            : null,
        paymentPlan: paymentPlan,
        totalOfPayments: roundToTwoDecimals(data.leasePayment * numberOfPayments),
        guaranteedRefinance: data.guaranteedRefi,
        totalDeferredDown: roundToTwoDecimals(data.deferred),
      });
      dispatch(salesActions.setSaleData(res));
      toast.success('Lease Terms Updated');
      reset(getValues()); // clear dirty state - why react hook form doesn't do this automatically after submit i do not understand
    } catch (err) {
      toast.error('Unable to update lease terms');
    } finally {
      setSubmitLoading(false);
    }
  };

  const salePosted = saleData.sale?.salestatus === AppStatus.Posted;

  return saleDataLoading || quoteDataLoading ? (
    <div className={salesTabStyles.loader}>
      <Loader size="large" />
    </div>
  ) : pageError ? (
    <div>{pageError}</div>
  ) : (
    <>
    <Grid container direction="column" gap={2} sx={{ flex: 1 }}>
      <header className={salesTabStyles.headerContainer}>
        <h2 className={salesTabStyles.header}>{saleData.sale?.saletype || ''} Terms</h2>
        <div className={salesTabStyles.headerButtonContainer}>
          <Button label="Trade In" onClick={() => navigate('trade-in')} />
          <Button label="Print Quote" onClick={printQuote} loading={quotePrinting} />
        </div>
      </header>
      </Grid>
      <Grid py={2} px={4} borderRadius={2.5} sx={{ backgroundColor: BG_HIGHLIGHT, overflow: 'scroll' }}>
      <main className={salesTabStyles.body}>
        <form onSubmit={handleSubmit(updateLeaseTerms)}>
          <div className={salesTabStyles.bodyContainer} style={{ gap: '160px' }}>
            <div className={salesTabStyles.sectionContainer}>
              <div className={salesTabStyles.bodyChild}>
                <h3 className={salesTabStyles.subHeading}>Capitalized Cost</h3>
                <Controller
                  name="dateOfSale"
                  control={control}
                  render={({ field }) => <DateInput label="Date of Sale" readOnly={disableDate} {...field} />}
                />

                <Controller
                  name="leasePrice"
                  control={control}
                  render={({ field }) => (
                    <CurrencyInput
                      label="Lease Price"
                      rightAlignInput
                      {...field}
                      onBlur={() => fieldOnBlur()}
                      disabled={salePosted}
                    />
                  )}
                />

                <Controller
                  name="customField1"
                  control={control}
                  render={({ field }) => (
                    <CurrencyInput
                      label={customField1Name}
                      rightAlignInput
                      {...field}
                      onBlur={() => fieldOnBlur()}
                      disabled={salePosted}
                    />
                  )}
                />

                <Controller
                  name="customField2"
                  control={control}
                  render={({ field }) => (
                    <CurrencyInput
                      label={customField2Name}
                      rightAlignInput
                      {...field}
                      onBlur={() => fieldOnBlur()}
                      disabled={salePosted}
                    />
                  )}
                />

                <Controller
                  name="customField3"
                  control={control}
                  render={({ field }) => (
                    <CurrencyInput
                      label={customField3Name}
                      rightAlignInput
                      {...field}
                      onBlur={() => fieldOnBlur()}
                      disabled={salePosted}
                    />
                  )}
                />

                <Controller
                  name="customField4"
                  control={control}
                  render={({ field }) => (
                    <CurrencyInput
                      label={customField4Name}
                      rightAlignInput
                      {...field}
                      onBlur={() => fieldOnBlur()}
                      disabled={salePosted}
                    />
                  )}
                />

                <Controller
                  name="customField5"
                  control={control}
                  render={({ field }) => (
                    <CurrencyInput
                      label={customField5Name}
                      rightAlignInput
                      {...field}
                      onBlur={() => fieldOnBlur()}
                      disabled={salePosted}
                    />
                  )}
                />

                <Controller
                  name="serviceContract"
                  control={control}
                  render={({ field }) => (
                    <CurrencyInput
                      label="Service Contract"
                      rightAlignInput
                      {...field}
                      onBlur={() => fieldOnBlur()}
                      disabled={salePosted}
                    />
                  )}
                />

                <Controller
                  name="addedSalesTax"
                  control={control}
                  render={({ field }) => (
                    <CurrencyInput
                      label="Added Sales Tax"
                      rightAlignInput
                      readOnly
                      {...field}
                      onBlur={() => fieldOnBlur()}
                      disabled={salePosted}
                    />
                  )}
                />

                <Controller
                  name="capitalizedCost"
                  control={control}
                  render={({ field }) => (
                    <CurrencyInput
                      label="Capitalized Cost"
                      rightAlignInput
                      readOnly
                      {...field}
                      onBlur={() => fieldOnBlur()}
                      disabled={salePosted}
                    />
                  )}
                />
              </div>

              <div className={salesTabStyles.bodyChild}>
                <h3 className={salesTabStyles.subHeading}>Cap Cost Reduction</h3>
                <Controller
                  name="tradeAllowance"
                  control={control}
                  render={({ field }) => (
                    <CurrencyInput
                      label="Trade Allowance"
                      rightAlignInput
                      readOnly
                      {...field}
                      onBlur={() => fieldOnBlur()}
                      disabled={salePosted}
                    />
                  )}
                />

                <Controller
                  name="tradePayoff"
                  control={control}
                  render={({ field }) => (
                    <CurrencyInput
                      label="Trade Payoff"
                      rightAlignInput
                      readOnly
                      {...field}
                      onBlur={() => fieldOnBlur()}
                      disabled={salePosted}
                    />
                  )}
                />

                <Controller
                  name="deferredDownWithTax"
                  control={control}
                  render={({ field }) => (
                    <CurrencyInput
                      label="Deferred"
                      rightAlignInput
                      readOnly
                      {...field}
                      onBlur={() => fieldOnBlur()}
                      disabled={salePosted}
                    />
                  )}
                />

                <Controller
                  name="cashDown"
                  control={control}
                  render={({ field }) => (
                    <CurrencyInput
                      label="Cash Down"
                      rightAlignInput
                      readOnly
                      {...field}
                      onBlur={() => fieldOnBlur()}
                      disabled={salePosted}
                    />
                  )}
                />

                <Controller
                  name="capCostReduction"
                  control={control}
                  render={({ field }) => (
                    <CurrencyInput
                      label="Cap Cost Reduction"
                      rightAlignInput
                      readOnly
                      {...field}
                      onBlur={() => fieldOnBlur()}
                      disabled={salePosted}
                    />
                  )}
                />
              </div>

              <div className={salesTabStyles.bodyChild}>
                <h3 className={salesTabStyles.subHeading}>Depreciation and Amortized Amounts</h3>
                <MultipleInputRow label="Residual">
                  <Controller
                    name="residual"
                    control={control}
                    render={({ field }) => (
                      <CurrencyInput
                        rightAlignInput
                        readOnly={!isResidual}
                        {...field}
                        onBlur={() => {
                          fieldOnBlur();
                        }}
                        disabled={salePosted}
                      />
                    )}
                  />
                  <div style={{ flex: 0 }}>
                    <Checkbox
                      onChange={() => setIsResidual((prev) => !prev)}
                      disabled={salePosted}
                    />
                  </div>
                </MultipleInputRow>
                <Controller
                  name="residualRate"
                  control={control}
                  render={({ field }) => (
                    <NumberInput
                      label="Residual Rate"
                      rightAlignInput
                      readOnly={isResidual}
                      format="p2"
                      {...field}
                      onBlur={() => fieldOnBlur()}
                      disabled={salePosted}
                    />
                  )}
                />
                <Controller
                  name="adjCapCost"
                  control={control}
                  render={({ field }) => (
                    <CurrencyInput
                      label="Adj Cap Cost"
                      rightAlignInput
                      readOnly
                      {...field}
                      onBlur={() => fieldOnBlur()}
                      disabled={salePosted}
                    />
                  )}
                />
              </div>
              <div className={salesTabStyles.bodyChild}>
                <h3 className={salesTabStyles.subHeading}>Deferred Down Payments</h3>
                <MultipleInputRow label="Amount/Date">
                  <Controller
                    name="deferredDownPlan1"
                    control={control}
                    render={({ field }) => (
                      <CurrencyInput
                        rightAlignInput
                        {...field}
                        onBlur={() => {
                          fieldOnBlur();
                        }}
                        disabled={salePosted}
                      />
                    )}
                  />
                  <Controller
                    name="deferredDownDate1"
                    control={control}
                    render={({ field }) => (
                      <DateInput
                        {...field}
                        onBlur={() => {
                          fieldOnBlur();
                        }}
                        disabled={salePosted}
                      />
                    )}
                  />
                </MultipleInputRow>
                <MultipleInputRow label="Amount/Date">
                  <Controller
                    name="deferredDownPlan2"
                    control={control}
                    render={({ field }) => (
                      <CurrencyInput
                        rightAlignInput
                        {...field}
                        onBlur={() => {
                          fieldOnBlur();
                        }}
                        disabled={salePosted}
                      />
                    )}
                  />
                  <Controller
                    name="deferredDownDate2"
                    control={control}
                    render={({ field }) => (
                      <DateInput
                        {...field}
                        onBlur={() => {
                          fieldOnBlur();
                        }}
                        disabled={salePosted}
                      />
                    )}
                  />
                </MultipleInputRow>
                <MultipleInputRow label="Amount/Date">
                  <Controller
                    name="deferredDownPlan3"
                    control={control}
                    render={({ field }) => (
                      <CurrencyInput
                        rightAlignInput
                        {...field}
                        onBlur={() => {
                          fieldOnBlur();
                        }}
                        disabled={salePosted}
                      />
                    )}
                  />
                  <Controller
                    name="deferredDownDate3"
                    control={control}
                    render={({ field }) => (
                      <DateInput
                        {...field}
                        onBlur={() => {
                          fieldOnBlur();
                        }}
                        disabled={salePosted}
                      />
                    )}
                  />
                </MultipleInputRow>
                <MultipleInputRow label="Amount/Date">
                  <Controller
                    name="deferredDownPlan4"
                    control={control}
                    render={({ field }) => (
                      <CurrencyInput
                        rightAlignInput
                        {...field}
                        onBlur={() => {
                          fieldOnBlur();
                        }}
                        disabled={salePosted}
                      />
                    )}
                  />
                  <Controller
                    name="deferredDownDate4"
                    control={control}
                    render={({ field }) => (
                      <DateInput
                        {...field}
                        onBlur={() => {
                          fieldOnBlur();
                        }}
                        disabled={salePosted}
                      />
                    )}
                  />
                </MultipleInputRow>
                <Controller
                  name="deferred"
                  control={control}
                  render={({ field }) => (
                    <CurrencyInput
                      label="Total Deferred"
                      rightAlignInput
                      readOnly
                      {...field}
                      onBlur={() => fieldOnBlur()}
                      disabled={salePosted}
                    />
                  )}
                />
              </div>
            </div>

            <div className={salesTabStyles.sectionContainer}>
              <div className={salesTabStyles.bodyChild}>
                <h3 className={salesTabStyles.subHeading}>Due at Lease Signing</h3>
                <Controller
                  name="cashDown"
                  control={control}
                  render={({ field }) => (
                    <CurrencyInput
                      label="Due Cash Down"
                      rightAlignInput
                      readOnly
                      {...field}
                      onBlur={() => fieldOnBlur()}
                      disabled={salePosted}
                    />
                  )}
                />

                <Controller
                  name="salesTaxOnDown"
                  control={control}
                  render={({ field }) => (
                    <CurrencyInput
                      label="Sales Tax On Down"
                      rightAlignInput
                      readOnly
                      {...field}
                      onBlur={() => fieldOnBlur()}
                      disabled={salePosted}
                    />
                  )}
                />

                <Controller
                  name="moneyDueAtInceptionField2"
                  control={control}
                  render={({ field }) => (
                    <CurrencyInput
                      label={moneyDueAtInceptionField2Name}
                      rightAlignInput
                      {...field}
                      onBlur={() => fieldOnBlur()}
                      disabled={salePosted}
                    />
                  )}
                />

                <Controller
                  name="moneyDueAtInceptionField1"
                  control={control}
                  render={({ field }) => (
                    <CurrencyInput
                      label={moneyDueAtInceptionField1Name}
                      rightAlignInput
                      {...field}
                      onBlur={() => fieldOnBlur()}
                      disabled={salePosted}
                    />
                  )}
                />

                <Controller
                  name="moneyDueAtInceptionField3"
                  control={control}
                  render={({ field }) => (
                    <CurrencyInput
                      label={moneyDueAtInceptionField3Name}
                      rightAlignInput
                      {...field}
                      onBlur={() => fieldOnBlur()}
                      disabled={salePosted}
                    />
                  )}
                />

                <Controller
                  name="securityDeposit"
                  control={control}
                  render={({ field }) => (
                    <CurrencyInput
                      label="Security Deposit"
                      rightAlignInput
                      {...field}
                      onBlur={() => fieldOnBlur()}
                      disabled={salePosted}
                    />
                  )}
                />

                <Controller
                  name="dueToday"
                  control={control}
                  render={({ field }) => (
                    <CurrencyInput
                      label="Due Today"
                      rightAlignInput
                      {...field}
                      onBlur={() => fieldOnBlur()}
                      disabled={salePosted}
                    />
                  )}
                />
              </div>

              <div className={salesTabStyles.bodyChild}>
                <h3 className={salesTabStyles.subHeading}>Lease Payment</h3>
                <Controller
                  name="apr"
                  control={control}
                  render={({ field }) => (
                    <NumberInput
                      label="APR"
                      rightAlignInput
                      format="p2"
                      {...field}
                      onBlur={() => fieldOnBlur()}
                      disabled={salePosted}
                    />
                  )}
                />

                <Controller
                  name="dateStarted"
                  control={control}
                  rules={dateGTETodayValidation}
                  render={({ field }) => (
                    <DateInput
                      label="Date Started"
                      errors={errors.dateStarted?.message}
                      {...field}
                      onBlur={() => fieldOnBlur()}
                      disabled={salePosted}
                    />
                  )}
                />

                <Controller
                  name="schedule"
                  control={control}
                  render={({ field }) => (
                    <DropdownInput
                      label="Schedule"
                      data={scheduleData}
                      {...field}
                      onBlur={() => fieldOnBlur()}
                      disabled={salePosted}
                    />
                  )}
                />

                <Controller
                  name="leasePayment"
                  control={control}
                  render={({ field }) => (
                    <CurrencyInput
                      label="Lease Payment"
                      rightAlignInput
                      {...field}
                      onBlur={() => fieldOnBlur()}
                      disabled={salePosted}
                    />
                  )}
                />

                <Controller
                  name="term"
                  control={control}
                  render={({ field }) => (
                    <NumberInput
                      label="Term (months)"
                      rightAlignInput
                      {...field}
                      onBlur={() => {
                        fieldOnBlur(true);
                      }}
                      disabled={salePosted}
                    />
                  )}
                />

                <Controller
                  name="numberOfPayments"
                  control={control}
                  render={({ field }) => (
                    <NumberInput
                      label="Number of Payments"
                      rightAlignInput
                      {...field}
                      onBlur={() => fieldOnBlur()}
                      disabled={salePosted}
                    />
                  )}
                />

                <Controller
                  name="basePayment"
                  control={control}
                  render={({ field }) => (
                    <CurrencyInput
                      label="Base Payment"
                      rightAlignInput
                      {...field}
                      onBlur={() => fieldOnBlur()}
                      disabled={salePosted}
                    />
                  )}
                />

                <Controller
                  name="salesTax"
                  control={control}
                  render={({ field }) => (
                    <CurrencyInput
                      label="Sales Tax"
                      rightAlignInput
                      readOnly
                      {...field}
                      onBlur={() => fieldOnBlur()}
                      disabled={salePosted}
                    />
                  )}
                />

                <Controller
                  name="taxRate"
                  control={control}
                  render={({ field }) => (
                    <NumberInput
                      label="Tax Rate"
                      rightAlignInput
                      readOnly
                      format="p4"
                      {...field}
                      onBlur={() => fieldOnBlur()}
                      disabled={salePosted}
                    />
                  )}
                />
              </div>

              <div className={salesTabStyles.bodyChild}>
                <h3 className={salesTabStyles.subHeading}>Total of Lease Payments</h3>
                <Controller
                  name="totalDepreciation"
                  control={control}
                  render={({ field }) => (
                    <CurrencyInput
                      label="Total Depreciation"
                      rightAlignInput
                      readOnly
                      {...field}
                      onBlur={() => fieldOnBlur()}
                      disabled={salePosted}
                    />
                  )}
                />

                <Controller
                  name="totalRentCharge"
                  control={control}
                  render={({ field }) => (
                    <CurrencyInput
                      label="Total Rent Charge"
                      rightAlignInput
                      readOnly
                      {...field}
                      onBlur={() => fieldOnBlur()}
                      disabled={salePosted}
                    />
                  )}
                />

                <Controller
                  name="guaranteedRefi"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      labelStyles={{ fontWeight: 'bold' }}
                      labelPlacement="before"
                      label="Guaranteed Refinance"
                      {...field}
                      onBlur={() => fieldOnBlur()}
                      disabled={salePosted}
                    />
                  )}
                />
              </div>
              <div className={salesTabStyles.bodyChild}>
                <h3 className={salesTabStyles.subHeading}>Payment Schedule</h3>
                <div className={salesTabStyles.paymentSchedule}>{paymentPlan}</div>
              </div>
            </div>
          </div>
          <Spacer size={20} />
          <div className={salesTabStyles.buttonContainer}>
            <Button label="Submit" buttonStyle={{ width: '134px' }} loading={submitLoading} />
          </div>
        </form>
      </main>
      </Grid>
    </>
  );
};
