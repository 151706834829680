import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
// components
import DateFilter from '../tableFilterComponents/DateFilter/DateFilter';
import CategoryFilter from '../tableFilterComponents/CategoryFilter';
// state
import { useAccountsViewCtx } from '../accountsViewState';
// interfaces
import { AcctsSubviewKey, SubviewKey } from '../viewInfo';
import { AllowedCategoryFilterKey } from './default';

// @todo move to view-info
const subviewsWithDateFilters: SubviewKey[] = [AcctsSubviewKey.terminations];

/** ### Set table filters here
 * - Renders filter(s) based on the types set in the column spec
 * - Handles conditional logic for determining if current subview allows filters
 * - String filters have a set amount column filter is a string filter
 */
const TableFilters: FC = () => {
  const subview = useAccountsViewCtx((s) => s.subview);
  const initDateFilterFields = useAccountsViewCtx((s) => s.subviewInfo.initDateFilterFields[0]!);

  // @todo should prevent rendering when filters don't exist
  const isDateFilterEnabled = subviewsWithDateFilters.includes(subview);

  const categoryFilterField = (
    subview === AcctsSubviewKey.unpaidSales
      ? 'spStatus'
      : subview === AcctsSubviewKey.legalAccounts
      ? 'status'
      : subview === AcctsSubviewKey.recurringPayments
      ? 'recurringStatus'
      : 'saleType'
  ) as AllowedCategoryFilterKey;

  return (
    <Grid container direction="row" gap={1}>
      <CategoryFilter field={categoryFilterField} />
      {isDateFilterEnabled && <DateFilter field={initDateFilterFields} />}
    </Grid>
  );
};

export default TableFilters;
