import { FC } from "react";
import { Link, useParams } from "react-router-dom";
// mui
import Grid from "@mui/material/Unstable_Grid2";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { DCS_BLUE, BORDER_GREY } from "@/mui/theme/colors";

const navOptions = [
  { label: "Vehicle", value: "vehicle", shouldDisplay: () => true },
  { label: "Expenses", value: "expenses", shouldDisplay: () => true },
  { label: "Vehicle Check-In", value: "vehicleCheckIn", shouldDisplay: () => true },
  { label: "Vehicle Options", value: "vehicleOptions", shouldDisplay: () => true },
  { label: "Title & Tag", value: "titleTag", shouldDisplay: () => true },
  { label: "Gps & Odometer", value: "gpsOdometer", shouldDisplay: () => true },
  { label: "Suggested Sale/Sold Vehicle", value: "suggestedSold", shouldDisplay: () => true },
  { label: "Buyer's Guide", value: "buyersGuide", shouldDisplay: () => true },
  { label: "Documents", value: "documents", shouldDisplay: () => true },
];

const useRouteMatch = (patterns: readonly string[]) => {
  const tabRoute = useParams()["*"];
  for (let idx = 0; idx < patterns.length; idx++) {
    const origRoute = patterns[idx];
    if (origRoute === tabRoute) return origRoute;
  }
  return null;
};

const NavTabs: FC = () => {
  const appliedNavOptions = navOptions.filter((o) => o.shouldDisplay());
  const currentTab =
    useRouteMatch(appliedNavOptions.map((t) => t.value)) || appliedNavOptions[0]?.value;

  const tabElems = appliedNavOptions.map((t) => (
    <Tab
      label={t.label}
      value={t.value}
      role="navigation"
      component={Link}
      to={"./" + t.value}
      relative="path"
      sx={{
        // Ensure text and wrapper alignment
        textAlign: "left",
        "& .MuiTab-wrapper": {
          textAlign: "left",
          alignItems: "start",
        },
        // Container styles
        alignItems: "start",
        borderBottom: "1px solid rgba(0, 0, 0, 0.0)",
        padding: "20px 30px",
        // Text styles
        textTransform: "none",
        fontSize: 12,
        fontWeight: 700,
        // Selected tab styles
        "&.MuiTab-root.Mui-selected": {
          color: DCS_BLUE,
          backgroundColor: "rgba(41, 70, 149, 0.2)",
        },
      }}
      key={t.value}
    />
  ));

  return (
    <Tabs
      value={currentTab}
      orientation="vertical"
      variant="scrollable"
      aria-label="sidebar-nav-tabs"
    >
      {tabElems}
    </Tabs>
  );
};

const InventoryDetailsNavTabs: FC = () => (
  <Grid
    container
    direction="column"
    sx={{ minWidth: "10%", flexShrink: 0, width: "200px", borderRight: `1px solid ${BORDER_GREY}` }}
  >
    <Grid component="h3" sx={{ pl: 4, fontWeight: 700 }}>
      Inventory
    </Grid>
    <NavTabs />
  </Grid>
);

export default InventoryDetailsNavTabs;
