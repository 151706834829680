import { useState, useEffect } from "react";
import { companyService } from "@/services/companyService";
import { ICompany } from "@/interfaces";
import { useAuthCtx } from "@/AppProviders/AuthProvider";
import { SelectedCompany, UserInfo } from "@/interfaces/System";
import { Dayjs } from "dayjs";
import { toast } from "react-toastify";
import React, { FC, PropsWithChildren, createContext } from "react";
import useCtxFactory from "@/utils/ctxState/useCtxFactory";

const useCtxState = () => {
  const [companyDetails, setCompanyDetails] = useState<ICompany | null>(null);
  const [userInfoState, setUserInfoState] = useState<UserInfo | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Access userInfo from Auth Context
  const userInfo = useAuthCtx((state) => state.userInfo);

  // Function to map API response to ICompany
  const mapSelectedCompanyToICompany = (data: any): ICompany => ({
    recId: data.recId,
    compId: data.recId, // Assuming `recId` serves as `compId`
    orgRecId: data.orgRecId ?? 0,
    companyName: data.companyName || "",
    shortName: data.shortName || "",
    legalName: data.legalName || "",
    abbreviation: data.abv_Name || "",
    address: data.address || "",
    city: data.city || "",
    state: data.state || "",
    zip: data.zip || "",
    county: data.county || "",
    phone: data.phone || "",
    phone800: data.phone800 || "",
    fax: data.fax || "",
    federalId: data.fedID || "",
    dealerNumber: data.dealerNum || "",
    contactName: data.contactName || null,
    contactEmail: data.contactEmail || null,
    contactPhone: data.contactPhone || "",
    lessorNumber: data.lessorNum || "",
    timeZone: data.timeZone || "",
    dayLight: data.dayLight ?? false,
    companyLogo: data.companyLogo || "",
    voicePhone: data.twilioPhoneNumber || "",
    forwardCallsTo: data.twilioForwardIncomingCallsTo || "",
    emailFrom: data.email_From || "",
    emailDisplayName: data.email_From_Address || "",
    minCreditCardAmount: data.minCreditCardAmount || 0,
    salesTaxNum: data.salesTaxNum || "",
  });

  const fetchCompanyDetails = async (compId: number) => {
    try {
      setIsLoading(true); // show loader
      const companyData = await companyService.getCompanyDetails(compId);
      const mappedCompany = mapSelectedCompanyToICompany(companyData);
      setCompanyDetails(mappedCompany);
    } catch (e) {
      setError(e as Error);
      toast.error("Failed to fetch company details.");
    } finally {
      setIsLoading(false); // hide loader
    }
  };

  const refetchCompanyDetails = () => {
    if (userInfo?.selectedCompany?.compId) {
      fetchCompanyDetails(userInfo.selectedCompany.compId);
    }
  };

  useEffect(() => {
    const initialFetch = async () => {
      if (userInfo?.selectedCompany?.orgRecId) {
        setUserInfoState(userInfo);
        refetchCompanyDetails(); // use the new refetch function
      } else {
        setError(new Error("Company details or RecId are not available in userInfo."));
      }
    };

    if (userInfo) {
      initialFetch();
    } else {
      setError(new Error("User information is not available."));
    }
  }, [userInfo]);

  return {
    companyDetails,
    userInfo: userInfoState,
    error,
    // ADDED: expose isLoading & refetch method
    isLoading,
    refetchCompanyDetails,
  };
};

type ICtx = ReturnType<typeof useCtxState>;
const Ctx = createContext<ICtx | null>(null);

export type CompanyMgmtCtx = ICtx;
export const useCompanyMgmtCtx = useCtxFactory(Ctx);

const CompanyMgmtProvider: FC<PropsWithChildren> = ({ children }) => (
  <Ctx.Provider value={useCtxState()}>{children}</Ctx.Provider>
);

export default CompanyMgmtProvider;
