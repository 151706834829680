import { ReactComponent as Star } from '@assets/icons/icon-star.svg';
import { ReactComponent as X } from '@assets/icons/icon-x.svg';
import { ReactComponent as PopOut } from '@assets/icons/icon-popout.svg';
import { ReactComponent as Collections } from '@assets/icons/icon-collections.svg';
import { ReactComponent as Logout } from '@assets/icons/icon-logout.svg';
import { ReactComponent as PiggyBank } from '@assets/icons/icon-piggy-bank.svg';
import { ReactComponent as Sales } from '@assets/icons/icon-sales.svg';
import { ReactComponent as ShieldCheck } from '@assets/icons/icon-shield-check.svg';
import { ReactComponent as Users } from '@assets/icons/icon-users.svg';
import { ReactComponent as Graph } from '@assets/icons/icon-graph.svg';
import { ReactComponent as Dollar } from '@assets/icons/icon-dollar.svg';
import { ReactComponent as Document } from '@assets/icons/icon-document.svg';
import { ReactComponent as Mail } from '@assets/icons/icon-mail.svg';
import { ReactComponent as TextBubble } from '@assets/icons/icon-text-bubbles.svg';
import { ReactComponent as DollarCircle } from '@assets/icons/icon-dollar-circle.svg';
import { ReactComponent as CreditCard } from '@assets/icons/icon-credit-card.svg';
import { ReactComponent as Check } from '@assets/icons/icon-check.svg';
import { ReactComponent as Phone } from '@assets/icons/icon-phone.svg';
import { ReactComponent as Gears } from '@assets/icons/icon-gears.svg';
import { ReactComponent as WarningCircleOutline } from '@assets/icons/icon-warning-circle-outline.svg';
import { ReactComponent as DollarCircleOutline } from '@assets/icons/icon-dollar-circle-outline.svg';
import { ReactComponent as User } from '@assets/icons/icon-user.svg';
import { ReactComponent as Note } from '@assets/icons/icon-note.svg';
import { ReactComponent as Message } from '@assets/icons/icon-message.svg';
import { ReactComponent as DownArrow } from '@assets/icons/icon-down-arrow.svg';
import { ReactComponent as ArrowLeftDouble } from '@assets/icons/icon-arrow-left-double.svg';
import { ReactComponent as ArrowRightDouble } from '@assets/icons/icon-arrow-right-double.svg';
import { ReactComponent as ArrowLeft } from '@assets/icons/icon-arrow-left.svg';
import { ReactComponent as ArrowRight } from '@assets/icons/icon-arrow-right.svg';
import { ReactComponent as Print } from '@assets/icons/icon-print.svg';
import { ReactComponent as Bell } from '@assets/icons/icon-bell.svg';
import { ReactComponent as Eye } from '@assets/icons/icon-eye.svg';
import { ReactComponent as EyeX } from '@assets/icons/icon-eye-x.svg';
import { ReactComponent as Checkmark } from '@assets/icons/icon-checkmark.svg';
import { ReactComponent as RoundX } from '@assets/icons/icon-round-x.svg';
import { ReactComponent as FilledCheckmark } from '@assets/icons/icon-checkmark-filled.svg';
import { ReactComponent as XFilled } from '@assets/icons/icon-x-filled.svg';
import { ReactComponent as DocuSign } from '@assets/icons/icon-docusign.svg';
import { ReactComponent as QuestionMark } from '@assets/icons/icon-question-mark.svg';
import { ReactComponent as CarWithKey } from '@assets/icons/icon-car-with-key.svg';
import { ReactComponent as Calendar } from '@assets/icons/icon-calendar.svg';
import { ReactComponent as DollarDouble } from '@assets/icons/icon-dollar-double.svg';
import { ReactComponent as Pin } from '@assets/icons/icon-pin.svg';
import { ReactComponent as KendoSortArrow } from '@assets/icons/icon-kendo-sort-arrow.svg';
import { ReactComponent as Shield } from '@assets/icons/shield-check.svg';
import { ReactComponent as List } from '@assets/icons/icon-list.svg';
import { ReactComponent as Service } from '@assets/icons/icon-service.svg';
import { ReactComponent as Car } from '@assets/icons/icon-car.svg';
import { ReactComponent as House } from '@assets/icons/icon-house.svg';
import { ReactComponent as LineChart } from '@assets/icons/chart-line-solid.svg';
import { ReactComponent as BarChart } from '@assets/icons/chart-simple-solid.svg';
import { ReactComponent as UserSolid } from '@assets/icons/user-solid.svg';
import { ReactComponent as Gauge } from '@assets/icons/icon-gauge-solid.svg';
import { ReactComponent as CreditRepairCloud } from '@assets/icons/credit-repair-cloud.svg';
import { ReactComponent as SmartCredit } from '@assets/icons/smart-credit.svg';
import { ReactComponent as Trash } from '@assets/icons/icon-trash.svg';

export const Icons = {
  Star,
  X,
  PopOut,
  Collections,
  Logout,
  PiggyBank,
  Sales,
  ShieldCheck,
  Users,
  Graph,
  Dollar,
  Document,
  Mail,
  TextBubble,
  DollarCircle,
  CreditCard,
  Check,
  Phone,
  Gears,
  WarningCircleOutline,
  DollarCircleOutline,
  User,
  Shield,
  Note,
  Message,
  DownArrow,
  ArrowLeft,
  ArrowRight,
  ArrowLeftDouble,
  ArrowRightDouble,
  Print,
  Bell,
  Eye,
  EyeX,
  Checkmark,
  RoundX,
  FilledCheckmark,
  XFilled,
  DocuSign,
  QuestionMark,
  CarWithKey,
  Calendar,
  DollarDouble,
  Pin,
  KendoSortArrow,
  List,
  Service,
  Car,
  House,
  LineChart,
  BarChart,
  UserSolid,
  Gauge,
  CreditRepairCloud,
  SmartCredit,
  Trash
};
