import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Divider from '@mui/material/Divider';
// kendo
import { Button } from '@/components';
// state
import { useSalesDetailCtx } from "./SalesDetailProvider";
// utils
import { useNavUp } from '@/utils/routing/useNavUpEvent';

/** ### Displays title */
const Header: FC = () => {
  const headerTitle = useSalesDetailCtx((s) => s.headerTitle);

  return (
    <Grid
      container
      direction="row"
      borderRadius="30px 30px 0 0"
      px={1}
      alignItems="center"
      wrap="nowrap"
      // flex={1}
      sx={{ background: '#EFF0F4', borderBottom: '4px solid #e6e6e6' }}
    >
      {/* @todo convert to <BackLink /> */}
      {headerTitle}
    </Grid>
  );
};

export default Header;
