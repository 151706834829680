import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
// kendo
import LightModal from '@/components/modals/LightModal';
import { DropdownInput, TextInput } from '@/components/inputs';
import { Icons } from '@/components/icons';
// components
import EmailEditor from '@/components/emailEditor/EmailEditor';
// state
import { useAuthSelector } from '@/features/auth/authSlice';
import { useAccountSelector } from '@/features/Accounts/accountSlice';
// utils
import { DynamicData, EmailTemplate, collectionsService } from '@/services/collectionsService';
// style
import styles from './emailModal.module.scss';
import { accountsService } from '@/services/accountsService';

interface ContactOption {
  colRecId: number;
  appBuyerRecId: number | null;
  displayName: string;
  email: string;
  canEmail: boolean;
}

/** @deprecated needs refactor */
const EmailModal: FC<{
  colRecId: number;
  open: boolean;
  initEmailData: () => void;
  onClose: () => void;
  canEmailCoBuyer: boolean;
  canEmailBuyer: boolean;
}> = ({ colRecId, open, onClose, initEmailData, canEmailBuyer, canEmailCoBuyer }) => {
  const accountInformation = useAccountSelector((s) => s.accountInformation)!;
  const [emails, setEmails] = useState<ContactOption[]>([]);
  const { compId } = useAuthSelector((state) => state);
  const [dynamicData, setDynamicData] = useState<DynamicData[]>([]);
  const [emailTemplate, setEmailTemplate] = useState<EmailTemplate[]>([]);
  const [emailSubject, setEmailSubject] = useState('');
  const [emailTo, setEmailTo] = useState<ContactOption | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState<{ input: string; message: string }[]>([]);

  const initData = async () => {
    setIsLoading(true);
    const [
      getDynamicData,
      getEmailTemplates,
      { buyer: buyerRefs, coBuyer: coBuyerRefs }
    ] = await Promise.all([
      collectionsService.getDynamicData(),
      collectionsService.getEmailTemplates(compId!),
      accountsService.getReferences(colRecId),
    ]);   

    setDynamicData(getDynamicData);
    setEmailTemplate(getEmailTemplates.filter((t) => t.category === 'Collections'));
    const { buyer, coBuyer } = accountInformation;

    setEmails([{
      colRecId,
      appBuyerRecId: buyer.appBuyerRecId,
      displayName: `Buyer - ${buyer.firstName} ${buyer.lastName} - ${buyer.email}`,
      email: buyer.email,
      canEmail: canEmailBuyer,
    }]);

    if (accountInformation.coBuyer.email) {
      setEmails((prevEmails) => [...prevEmails, {
        colRecId,
        appBuyerRecId: coBuyer.appBuyerRecId,
        displayName: `Co-Buyer - ${coBuyer.firstName} ${coBuyer.lastName} - ${coBuyer.email}`,
        email: coBuyer.email,
        canEmail: canEmailCoBuyer,
      }]);
    }

    var references = buyerRefs
      .map((ref) => {
        return {
          colRecId: colRecId,
          appBuyerRecId: null,
          displayName: `Buyer Reference - ${ref.name} - ${ref.email}`,
          email: ref.email,
          canEmail: !ref.noEmails,
        } as ContactOption;
      });

    var cobuyerReferences = coBuyerRefs
        .map((ref) => {
          return {
            colRecId: colRecId,
            appBuyerRecId: null,
            displayName: `Co-Buyer Reference - ${ref.name} - ${ref.email}`,
            email: ref.email,
            canEmail: !ref.noEmails,
          } as ContactOption;
        });
    references = [...references, ...cobuyerReferences];

    setEmails((prevEmails) => [...prevEmails.filter(cc => cc.canEmail), ...references.filter(ref => ref.canEmail)]);
    setIsLoading(false);
  };

  const sendEmail = (emailSubject: string, emailMessage: string) => {
    if (emailSubject === '') {
      setErrors([{ input: 'subject', message: 'Email subject is required' }]);
      return;
    } else {
      if (!emailTo || !emailTo.email) return;

      collectionsService
        .sendEmailTo(colRecId, emailTo.email, emailSubject, emailMessage)
        .then(
          () => {
            toast.success('Email messages sent successfully');
            initEmailData();
          },
          (e) => {
            toast.error(e.response.data.message);
          }
        )
        .finally(() => {
          closeEmail();
        });
    }
  };

  const removeErrors = () => {
    if (errors.length > 0) {
      setErrors([]);
    }
  };

  const closeEmail = () => {
    setEmailSubject('');
    setErrors([]);
    onClose();
  };

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    if(emails.length > 0) {
      setEmailTo(emails[0] ?? null);
    }
  }, [emails])

  return (
    <LightModal open={open} closeModal={() => onClose()}>
      <div className={styles.emailModalContainer}>
          <div className={styles.header}>
            <button className={styles.closeButton} onClick={() => onClose()}>
              <Icons.X />
            </button>
          </div>
          <div className={styles.title}>Email</div>
            <div className={styles.inputRow}>
              <label className={styles.label}>To:</label>
              <div className={styles.input}>
                <DropdownInput
                  loading={isLoading}
                  
                  value={emailTo}              
                  data={emails}
                  dataItemKey='email'
                  textField='displayName'
                  onChange={(e) => {
                    setEmailTo(e.target.value)}
                  }    
                  disabled={!canEmailBuyer && !canEmailCoBuyer}
                />
                {(!canEmailBuyer || !canEmailCoBuyer) && (
                  <small className={styles.disclaimer}>{`${
                    canEmailBuyer ? 'Co-buyer' : 'Buyer'
                  } has do not email preference on`}</small>
                )}
              </div>
            </div>
            <div className={styles.inputRow}>
              <label className={styles.label}>Subject:</label>
              <div className={styles.input}>
                <TextInput
                  onBlur={(e) => setEmailSubject(e.currentTarget.value)}
                  errors={errors.find((e) => e.input === 'subject')?.message}
                  onChange={removeErrors}
                />
              </div>
            </div>

            <EmailEditor
              emailSubject={emailSubject}
              dynamicData={dynamicData}
              emailTemplate={emailTemplate}
              sendEmail={sendEmail}
              closeEmail={closeEmail}
            />
        </div>
    </LightModal>
  );
};

export default EmailModal;
