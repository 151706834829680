import { FC } from "react";
// kendo
import { GridCellProps, GridColumnProps } from "@progress/kendo-react-all";
// components
import DateCell from "@/components/kendoCells/DateCell";
import NumberCell from "@/components/kendoCells/NumberCell";
import LinkCell from "@/components/kendoCells/LinkCell";
import PercentCell from "@/components/kendoCells/PercentCell";
import CurrencyCell from "@/components/kendoCells/CurrencyCell";
import TextCell from "@/components/kendoCells/TextCell";
import ButtonLinkCell from "@/components/kendoCells/ButtonLinkCell";

/** @todo Move to general location, as this can be used for all kendo column definitions */
export type KendoColumnDef<FieldKey extends string = string> = Omit<GridColumnProps, "field" | "cell" | "width"> & {
  field: FieldKey;
  width?: number | "auto";
  minWidth?: number;
  flexGrow?: number;
  cell?: FC<GridCellProps>;
  display?: boolean;
  order?: number;
  navRouteBuilder?: (dataItem: any) => string;
};

type CellType =
  | "date"
  | "datetime"
  | "number"
  | "link"
  | "buttonLink"
  | "percent"
  | "accounting"
  | "text";

  export const newColumnDef = <FieldKey extends string = string>(
    field: KendoColumnDef<FieldKey>["field"],
    title: KendoColumnDef["title"],
    width: KendoColumnDef["width"] = "auto",
    cell?: KendoColumnDef["cell"] | CellType,
    sortable = true,
    display = true,
    navRouteBuilder?: KendoColumnDef["navRouteBuilder"],
    minWidth: number = 80, // Default minWidth
    flexGrow: number = 1, // Default flexGrow
    order?: number // Optional order parameter
  ): KendoColumnDef<FieldKey> => {
    let cellComponent: FC<GridCellProps> | undefined;
    const key = `${field}${title}`;
  
    switch (cell) {
      case "date":
        cellComponent = (p) => <DateCell {...p} key={key} />;
        break;
      case "datetime":
        cellComponent = (p) => <DateCell {...p} isDatetime key={key} />;
        break;
      case "number":
        cellComponent = (p) => <NumberCell {...p} key={key} />;
        break;
      case "link":
        if (navRouteBuilder === undefined) {
          throw new Error("newColumnDef()-link: `navRouteBuilder` field cannot be empty.");
        }
        cellComponent = (p) => <LinkCell {...p} navRouteBuilder={navRouteBuilder} key={key} />;
        break;
      case "buttonLink":
        if (navRouteBuilder === undefined) {
          throw new Error("newColumnDef()-buttonLink: `navRouteBuilder` field cannot be empty.");
        }
        cellComponent = (p) => <ButtonLinkCell {...p} navRouteBuilder={navRouteBuilder} key={key} />;
        break;
      case "percent":
        cellComponent = (p) => <PercentCell {...p} key={key} />;
        break;
      case "accounting":
        cellComponent = (p) => <CurrencyCell {...p} key={key} />;
        break;
      case "text":
        cellComponent = (p) => <TextCell {...p} key={key} />;
        break;
      case undefined:
        cellComponent = (p) => <TextCell {...p} key={key} />;
        break;
      default:
        cellComponent = cell;
        break;
    }
  
    return {
      field,
      title,
      width,
      minWidth,
      flexGrow,
      sortable,
      display,
      order, // Add the order property here
      cell: cellComponent,
      cells: { data: cellComponent },
    };
  };
