import { toast } from "react-toastify";
import { BlobServiceClient } from "@azure/storage-blob";
// utils
import { AxiosService } from "@services/axiosService";
// interfaces
import {
  GpsDevice,
  PasstimeGetLastLocateRes,
} from "@/features/Accounts/accountsSubviews/AccountDetail/components/GpsView/interfaces";
import { ApiResponse } from "@/interfaces/Api";
import {
  FormIds,
  GenericPasstimeResponse,
  GenericSpireonResponse,
  GetPasstimeCustomerResponse,
  GetPasstimeGpsHistoryResponse,
  PasstimeCodesPayload,
  PasstimeTotalMilesResponse,
  PasstimeWarnPayload,
  PhotoInfo,
  PhotoOutput,
  UserInfo,
} from "@/interfaces/System";
import { isAxiosError } from "axios";
import dayjs from "dayjs";
import { GpsHistoryItemDeprec } from "@/interfaces";
import { GetSpireonAssetResponse, GetSpireonEventsResponse } from "@/interfaces/Gps/Spireon";
import { IturanDeviceActivityResponse } from "@/interfaces/Gps/Ituran";
import { DateFormat } from "@/utils/helpers/general";

class SystemService extends AxiosService {
  public constructor() {
    super();
  }

  async getGpsModels(provider: GpsDevice) {
    try {
      const { data } = await this.axios.get<{ recId: number; model: string; provider: string }[]>(
        "/System/GpsModels",
        {
          params: { provider },
        }
      );
      return data ? data.map((d) => d.model) : [];
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getPasstimeTotalMiles(inventoryRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<PasstimeTotalMilesResponse>>(
        "/System/GetPasstimeTotalMiles",
        {
          params: { inventoryRecId },
        }
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      toast.error("Unable to get Passtime total miles");
      throw e;
    }
  }

  async setPasstimeTotalMiles(inventoryRecId: number, totalMiles: number) {
    try {
      const { data } = await this.axios.post<ApiResponse<GenericPasstimeResponse>>(
        "/System/SetPasstimeTotalMiles",
        { inventoryRecId, totalMiles }
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      toast.error("Unable to set Passtime total miles");
      throw e;
    }
  }

  async addPasstimeCustomer(inventoryRecId: number) {
    try {
      const { data } = await this.axios.post<ApiResponse<GenericPasstimeResponse>>(
        "/System/AddPasstimeCustomer",
        {
          params: { inventoryRecId },
        }
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      toast.error("Unable to add Passtime customer");
      throw e;
    }
  }

  async getPasstimeCustomer(inventoryRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<GetPasstimeCustomerResponse>>(
        "/System/GetPasstimeCustomer",
        {
          params: { inventoryRecId },
        }
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      toast.error("Unable to get Passtime customer data");
      throw e;
    }
  }

  async updatePasstimeCustomer(inventoryRecId: number) {
    try {
      const { data } = await this.axios.post<ApiResponse<GenericPasstimeResponse>>(
        "/System/UpdatePasstimeCustomer",
        {},
        { params: { inventoryRecId } }
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      toast.error(`Unable to update Passtime customer with invRecId: ${inventoryRecId}`);
      throw e;
    }
  }

  async deletePasstimeCustomer(inventoryRecId: number) {
    try {
      const { data } = await this.axios.post<ApiResponse<GenericPasstimeResponse>>(
        "/System/DeletePasstimeCustomer",
        {
          params: { inventoryRecId },
        }
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      toast.error("Unable to delete Passtime customer");
      throw e;
    }
  }

  async updatePasstimeMap(inventoryRecId: number) {
    try {
      const res = await this.axios.post<ApiResponse<GenericPasstimeResponse>>(
        "/System/UpdatePasstimeMap",
        {},
        { params: { inventoryRecId } }
      );
      if (!res.data.data?.success) {
        console.error(res.data.data);
        throw new Error("Passtime error");
      }
    } catch (e) {
      toast.error("Passtime error");
      console.error(e);
      throw e;
    }
  }

  async getPasstimeLastLocate(inventoryRecId: number) {
    try {
      const res = await this.axios.get<ApiResponse<PasstimeGetLastLocateRes<string>>>(
        "/System/GetPasstimeLastLocate",
        { params: { inventoryRecId } }
      );

      var passTimeResponse = res.data.data;

      if(!passTimeResponse) return undefined;

      var response: GpsHistoryItemDeprec = {
        address: passTimeResponse.address,
        odometer: passTimeResponse.totalMiles,
        lastPing: dayjs(passTimeResponse.eventDate).format(DateFormat.SimpleDateTime),
        course: passTimeResponse.course,
        speed: passTimeResponse.speed,
        latitude: passTimeResponse.lat,
        longitude: passTimeResponse.long,
      };

      return response;
    } catch (e) {
      console.error(e);
      // @todo re-enable after determining state values used to trigger request
      // toast.error("Unable to get GPS last location (PassTime)");
      throw e;
    }
  }

  async getPasstimeGpsHistoryByDate(inventoryRecId: number, beginDate: string, endDate: string) {
    try {
      const { data } = await this.axios.get<ApiResponse<GetPasstimeGpsHistoryResponse>>(
        "/System/GetPasstimeGpsHistoryByDate",
        {
          params: { inventoryRecId, beginDate, endDate },
        }
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      toast.error("Unable to get Passtime customer GPS history");
      throw e;
    }
  }

  async getPasstimeGpsHistory(inventoryRecId: number) {
    const history = await this.getPasstimeGpsHistoryByDate(
      inventoryRecId,
      '2010-01-01',
      dayjs().format("YYYY-MM-DD")
    );

    if (history.some(h => h.callResult === "false")) 
      return [];

    const standardGpsHistory: GpsHistoryItemDeprec[] = history.map((h) => ({
      address: h.address,
      odometer: h.totalMiles,
      lastPing: dayjs(h.utCGpsDate).toString(),
      course: '',
      speed: h.speed,
      latitude: h.latitude,
      longitude: h.longitude,
    }));

    return standardGpsHistory;
  }

  async enableDisablePasstimeDevice(inventoryRecId: number, enable: boolean) {
    try {
      const { data } = await this.axios.post<ApiResponse<GenericPasstimeResponse>>(
        "/System/EnableDisablePasstimeDevice",
        {},
        { params: { inventoryRecId, enable } }
      );
      if (!data.data?.success) throw new Error();
      toast.success(`Vehicle ${enable ? "enabled" : "disabled"}`);
    } catch (e) {
      console.error(e);
      toast.error(
        `Unable to ${enable ? "enable" : "disable"} passtime device for invRecId: ${inventoryRecId}`
      );
      throw e;
    }
  }

  async sendPasstimeEmergencyCode(inventoryRecId: number) {
    try {
      const { data } = await this.axios.post<ApiResponse<GenericPasstimeResponse>>(
        "/System/SendPasstimeEmergencyCode",
        {},
        {
          params: { inventoryRecId },
        }
      );
      if (!data.data?.success) throw new Error();
    } catch (e) {
      console.error(e);
      toast.error(`Unable to send Passtime emergency code for invRecId: ${inventoryRecId}`);
      throw e;
    }
  }

  async sendPasstimeWarningNoDisable(payload: PasstimeWarnPayload) {
    try {
      const { data } = await this.axios.post<ApiResponse<GenericPasstimeResponse>>(
        "/System/SendPasstimeWarningNoDisable",
        payload
      );
      if (!data.data?.success) throw new Error();
    } catch (e) {
      console.error(e);
      toast.error(`Unable to send Passtime warning`);
      throw e;
    }
  }

  async generatePasstimeCode(payload: PasstimeCodesPayload) {
    // No one is sure what this route is for ... but it exists
    try {
      const { data } = await this.axios.post<ApiResponse<GenericPasstimeResponse>>(
        "/System/GeneratePasstimeCodes",
        payload
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      toast.error(`Unable to generate passtime codes`);
      throw e;
    }
  }

  async getSpireonAssetLocateEvents(inventoryRecId: number, startDate: string, endDate: string) {
    try {
      const { data } = await this.axios.get<ApiResponse<GetSpireonEventsResponse>>(
        '/System/GetSpireonAssetLocateEvents',
        {
          params: { inventoryRecId, startDate, endDate },
        }
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      toast.error('Unable to get Spireon device location history');
      throw e;
    }
  }

  async getSpireonGpsHistory(inventoryRecId: number) {
    const locateEvents = await this.getSpireonAssetLocateEvents(
      inventoryRecId,
      '2010-01-01',
      dayjs().format("YYYY-MM-DD")
    );

    const standardGpsHistory: GpsHistoryItemDeprec[] = locateEvents.events.map((event) => {
      const { location } = event;
      const { address } = location;
      return {
        address: `${address.line1} ${address.city} ${address.stateOrProvince} ${address.postalCode}`,
        odometer: event.odometer,
        lastPing: event.date,
        course: '',
        speed: (event.speed ?? '').toString(),
        latitude: location.lat,
        longitude: location.lng,
      };
    });

    return standardGpsHistory;
  }

  async getSpireonAsset(inventoryRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<GetSpireonAssetResponse>>(
        '/System/GetSpireonAsset',
        {
          params: { inventoryRecId },
        }
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      toast.error('Unable to get Spireon device data');
      throw e;
    }
  }

  async getSpireonLastLocate(inventoryRecId: number) {
    try {
      const res = await this.getSpireonAsset(inventoryRecId);
      const spireonAsset = res?.asset;

      if (!spireonAsset || !spireonAsset.lastLocation || !spireonAsset.lastLocation.address)
        return undefined;

      const { lastLocation } = spireonAsset;

      const { address } = lastLocation;

      const standardLastLocate: GpsHistoryItemDeprec = {
        address: `${address.line1} ${address.city} ${address.stateOrProvince} ${address.postalCode}`,
        odometer: spireonAsset.odometer,
        lastPing: dayjs(spireonAsset.locationLastReported).format(DateFormat.SimpleDateTime),
        course: '', // Doesn't seem to exist for Spireon
        speed: (spireonAsset.speed ?? '').toString(),
        latitude: lastLocation.lat,
        longitude: lastLocation.lng,
      };

      return standardLastLocate;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async updateSpireonMap(inventoryRecId: number) {
    try {
      const res = await this.axios.post<ApiResponse<GenericSpireonResponse>>(
        "/System/SendSpireonLocateCommand",
        {},
        { params: { inventoryRecId } }
      );
      if (!res.data.data?.success) {
        console.error(res.data.data);
        throw new Error("Spireon error");
      }
    } catch (e) {
      toast.error("Spireon error");
      console.error(e);
      throw e;
    }
  }

  async getIturanDeviceActivity(inventoryRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<IturanDeviceActivityResponse>>(
        '/System/GetIturanDeviceActivity',
        {
          params: { inventoryRecId },
        }
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      toast.error('Unable to get Ituran device activity');
      throw e;
    }
  }

  async getIturanGpsHistory(inventoryRecId: number) {
    const ituranActivityRes = await this.getIturanDeviceActivity(inventoryRecId);

    const standardGpsHistory: GpsHistoryItemDeprec[] = ituranActivityRes.deviceActivities.map((a) => {
      const { location } = a;
      return {
        address: location.address,
        odometer: location.odometer,
        lastPing: location.date,
        course: '',
        speed: (location.speed ?? '').toString(),
        latitude: location.lat,
        longitude: location.lon,
      };
    });

    return standardGpsHistory;
  }

  async getIturanLastLocate(inventoryRecId: number) {
    try {
      // in theory we should be able to use the location from getIturanDeviceDetail,
      // but for some reason that is always missing a date... so we need to get the most recent activity that has
      // all the necessary fields
      const ituranActivityRes = await this.getIturanDeviceActivity(inventoryRecId);

      if (!ituranActivityRes || !ituranActivityRes.deviceActivities) return undefined;

      const latestActivity = ituranActivityRes.deviceActivities.find((activity) => {
        const { location } = activity ?? {};
        return location.lat && location.lon && location.address && location.date;
      });

      if (!latestActivity) return undefined;

      const standardLastLocate: GpsHistoryItemDeprec = {
        address: latestActivity.location.address,
        odometer: latestActivity.location.odometer,
        lastPing: dayjs(latestActivity.location.date).format(DateFormat.SimpleDateTime),
        course: '', // Doesn't seem to exist for Ituran - maybe `heading` is degrees ...?
        speed: (latestActivity.location.speed ?? '').toString(),
        latitude: latestActivity.location.lat,
        longitude: latestActivity.location.lon,
      };

      return standardLastLocate;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getBlobUploadUrl(orgId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<string>>("/System/GetSasUploadUrl", {
        params: { orgId },
      });
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async uploadBlob(blob: File, orgId: number, fileNameCloud: string) {
    try {
      const sasUrl = await this.getBlobUploadUrl(orgId);
      const blobServiceClient = new BlobServiceClient(sasUrl);
      const containerClient = blobServiceClient.getContainerClient(""); // The container name is already set on the URL that was returned
      const blockBlobClient = containerClient.getBlockBlobClient(fileNameCloud);

      await blockBlobClient.upload(blob, blob.size, {
        blobHTTPHeaders: { blobContentType: blob.type },
      });

      const sasUrlWithoutToken = sasUrl.split("?")[0];
      const fileUrl = `${sasUrlWithoutToken}/${fileNameCloud}`;
      return fileUrl;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async getBlobReadUrl(orgId: number, blobName: string) {
    try {
      const { data } = await this.axios.get<ApiResponse<string>>("/System/GetSasReadUrl", {
        params: { orgId, blobName },
      });
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getBlobReadUrls(photoList: PhotoInfo[]) {
    try {
      const { data } = await this.axios.post<ApiResponse<PhotoOutput[]>>(
        "/System/GetSasReadUrls",
        photoList
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getUserInfo() {
    try {
      const res = await this.axios.get<ApiResponse<UserInfo>>("/System/UserInfo");
      if (!res.data.data) throw new Error("UserInfo response is empty");

      return res.data.data;
    } catch (e) {
      if (!isAxiosError(e)) throw e;
      toast.error("Error fetching user info");
      console.warn("e.response", e.response);
      console.warn("e.response?.data", e.response?.data);
      console.warn("e.response?.data.Type", e.response?.data.Type);
      const isRedisTimeout = e.response?.data.Type === "RedisTimeoutException";
      console.error(e);
      throw e;
    }
  }

  async getFormIds(compId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<FormIds>>("/System/FormIds", {
        params: { compId },
      });
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async mappedCompanies() {
    try {
      const { data } = await this.axios.get<ApiResponse<[]>>("/System/MappedCompanies");
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getSignalrToken() {
    try {
      const { data } = await this.axios.get<ApiResponse<string>>("/System/GetSignalrToken");
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}

export const systemService = new SystemService();
