import { FC, useEffect } from 'react';
// kendo
import { AccountsMainPanel } from '@/features/Accounts/accountsSubviews/AccountDetail/components/accountsMainPanel/AccountsMainPanel';
// components
import { GpsHistory } from './GpsHistory';
import GpsLocation from './GpsLocation';
import GpsDeviceInfo from './GpsDeviceInfo';
// state
import { useGpsCtx } from './GpsProvider';
// utils
import { gpsTabMap } from './interfaces';
// interfaces
import { StateNavTab } from '../accountsMainPanel/interfaces';

/** ###  */
const GpsView: FC = () => {
  const gpsTab = useGpsCtx((s) => s.gpsTab);
  const setGpsTab = useGpsCtx((s) => s.setGpsTab);
  const gpsInformation = useGpsCtx((s) => s.gpsInformation);

  useEffect(() => {
    return () => {
      setGpsTab('deviceInfo');
    };
  }, []);

  // Build tab list
  const navBarItems: StateNavTab[] = [];
  gpsTabMap.forEach((v, k) => {
    navBarItems.push({
      title: v,
      isActive: gpsTab === k,
      onClick: () => setGpsTab(k),
    });
  });

  return (
    <AccountsMainPanel
      navBarTitle="GPS"
      navBarItems={navBarItems}
      navBarItemsStyle={{ justifyContent: 'center', gap: '5%' }}
      containerStyle={{overflow: 'hidden'}}
    >
      {gpsTab === 'deviceInfo' && <GpsDeviceInfo />}
      {gpsTab === 'location' && <GpsLocation />}
      {gpsTab === 'locationHistory' && <GpsHistory gpsDevice={gpsInformation?.device} />}
    </AccountsMainPanel>
  );
};

export default GpsView;
