import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { inventoryService } from '@/services/inventoryService';
import CheckInGrid from './checkInGrid/checkInGrid';
import { useInventoryDetailCtx } from '../../InventoryDetailProvider';
import { CheckInItem } from '@/interfaces';
import { PageHeader } from "@/components/pageHeader/PageHeader";
// mui
import Grid from "@mui/material/Unstable_Grid2";
//style
import { BG_HIGHLIGHT } from "@/mui/theme/colors";

const loadCheckInViewData = async (
  invRecId: number | undefined,
  setIsLoading: (isLoading: boolean) => void,
  setCheckInState: (checkInState: CheckInItem[]) => void,
  setCheckInList: (checkInList: CheckInItem[]) => void
) => {
  if (!invRecId) {
    return;
  }

  try {
    setIsLoading(true);
    const initCheckInList = await inventoryService.getCheckInList(invRecId);
    const validatedCheckInList = initCheckInList.map(
      (c) =>
        new CheckInItem(
          c.recId,
          c.orgId,
          c.locationId,
          c.compId,
          c.vehRecId,
          c.sOrder,
          c.ckItem,
          c.fix,
          c.comments,
          c.fixed,
          c.fixedOn,
          c.fixedById,
          c.fixedByName
        )
    );

    setCheckInState(validatedCheckInList);
    setCheckInList(validatedCheckInList);

    setIsLoading(false);
  } catch (error) {
    console.error(error);
  }
};

export const CheckIn = () => {
  const { invRecId } = useParams();
  const setIsLoading = useInventoryDetailCtx((s) => s.setIsLoading);
  const setCheckInState = useInventoryDetailCtx((s) => s.setCheckInState);
  const setCheckInList = useInventoryDetailCtx((s) => s.setCheckInList);
  const inventoryVehicle = useInventoryDetailCtx((s) => s.inventoryVehicle);

  useEffect(() => {
    loadCheckInViewData(Number(invRecId), setIsLoading, setCheckInState, setCheckInList);
  }, [inventoryVehicle.vin]);

  return (
    <Grid container direction="column" gap={2} sx={{ height: "80vh", overflow: "hidden", padding: "20px" }}>
      {/* Header Section */}
      <Grid overflow={"hidden"}>
          <PageHeader headerLabel="Vehicle Check-In" />
      </Grid>

      {/* OptionGrid Section */}
      <Grid
        sx={{
          flex: 1, // Takes the remaining space
          borderRadius: 2.5,
          backgroundColor: BG_HIGHLIGHT,
          overflow: "scroll", // Prevent content overflow
        }}
      >
        <CheckInGrid />
      </Grid>
    </Grid>
  );
};

export default CheckIn;
