// components/modals/UploadModal.tsx
import React, { FC } from 'react';
import { Modal } from '@/components/modals/Modal';
import { Spacer } from '@/components/spacer/Spacer';
import { FileInput } from '@/components/inputs/fileInput/FileInput';
import { TextInput } from '@/components/inputs/text/TextInput';
import { Button } from '@/components';

interface UploadModalProps {
  isOpen: boolean;
  onClose: () => void;
  onUpload: () => void;
  file: File | null;
  setFile: (file: File | null) => void;
  fileName?: string;
  fileInputError?: boolean;
  description?: string;
  setDescription?: (desc: string) => void;
  descriptionError?: boolean;
  submitLabel?: string;
  loading?: boolean;
}

const UploadModal: FC<UploadModalProps> = ({
  isOpen,
  onClose,
  onUpload,
  file,
  setFile,
  fileName,
  fileInputError,
  description,
  setDescription,
  descriptionError,
  submitLabel = 'Submit',
  loading = false,
}) => {
  if (!isOpen) return null; // Prevent rendering if not open

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0] || null;
    setFile(selectedFile);
  };

  return (
    <Modal
      isOpen={isOpen}
      onCloseButtonClick={onClose}
      closeButton
      centerModal
      title="Upload New"
      panelStyle={{ width: 600 }}
    >
      <div>
        <Spacer size={5} />
        <FileInput
          onChange={handleFileChange}
          fileName={fileName}
          errors={fileInputError}
        />
        {description && setDescription && (
          <>
            <Spacer size={20} />
            <TextInput
              required
              label="Description"
              value={description}
              horizontalLabel={false}
              onChange={(e) => setDescription(e.target.value)}
              errors={descriptionError}
            />
          </>
        )}
        <Spacer size={20} />
        <div style={{ width: 100, marginLeft: 'auto' }}>
          <Button 
            type="button" // Explicitly set the type to "button"
            label={submitLabel} 
            loading={loading} 
            onClick={onUpload} 
          />
        </div>
      </div>
    </Modal>
  );
};

export default UploadModal;
