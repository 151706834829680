import { FC, useState } from "react";
// mui
import { DataGrid, GridSortModel } from "@mui/x-data-grid";
import TableToolbar from "@/mui/components/dataTable/TableToolbar";
import Grid from "@mui/material/Unstable_Grid2";
import { getRowId } from "@/mui/components/dataTable/utils";
// components
import { Loader } from "@/components";
// state
import { useAddOnsSalesTaxCtx } from "../AddOnsSalesTaxProvider";
// utils
import { tableColumns } from "./tableColumns";
import { initPage, tableStyle } from "@/features/Settings/default";
import { BG_HIGHLIGHT } from "@/mui/theme/colors";

const rowHeightPx = 60;

const AddOnsSalesTaxTable: FC = () => {
  const profileRows = useAddOnsSalesTaxCtx((s) => s.profiles ?? []);
  const isLoading = useAddOnsSalesTaxCtx((s) => s.isLoading);
  const onRowClick = useAddOnsSalesTaxCtx((s) => s.handleRowClick);

  // Table params
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [pageModel, setPageModel] = useState(initPage);

  return (
    <Grid py={2} px={4} borderRadius={2.5} sx={{ backgroundColor: BG_HIGHLIGHT }}>
      <Grid component="h3">Add Ons & Sales Tax</Grid>
      {isLoading ? (
        <Loader size="large" />
      ) : (
        <DataGrid
          rowHeight={rowHeightPx}
          loading={isLoading}
          rows={profileRows}
          columns={tableColumns}
          getRowId={getRowId("recId")}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          paginationModel={pageModel}
          onPaginationModelChange={setPageModel}
          slots={{ toolbar: TableToolbar }}
          slotProps={{ toolbar: { placeholder: "Search filter" }}}
          sx={tableStyle}
          onRowClick={(params) => onRowClick(params.row.recId)}
          style={{ cursor: "pointer" }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                recId: false, // Hides the recId column
              },
            },
          }}
        />
      )}
    </Grid>
  );
};

export default AddOnsSalesTaxTable;
