import { FC, useEffect, useState } from "react";
import { GridCustomCellProps, Input } from "@progress/kendo-react-all";
import { useInventoryDetailCtx } from "../../../InventoryDetailProvider";
import { Checkbox } from "@/components";
import { FaDeleteLeft } from "react-icons/fa6";
import styles from "./CheckInGrid.module.scss";
import { inventoryService } from "@/services/inventoryService";
import { debounce } from "lodash-es";

export const CheckBoxCell: FC<GridCustomCellProps> = ({ dataIndex, ...props }) => {
  const checkInList = useInventoryDetailCtx((s) => s.checkInList);
  const setCheckInList = useInventoryDetailCtx((s) => s.setCheckInList);
  const setCheckInState = useInventoryDetailCtx((s) => s.setCheckInState);

  const isChecked = checkInList[dataIndex]!.fix;

  return (
    <td
      {...props.tdProps}
      style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Checkbox
        name={dataIndex.toString()}
        checked={isChecked}
        onChange={async (e) => {
          const updatedCheckInItem = { ...checkInList[dataIndex]!, fix: e.value };
          const res = await inventoryService.updateCheckInOption(updatedCheckInItem);
          setCheckInList(res);
          setCheckInState(res);
        }}
      />
    </td>
  );
};

export const VerifyCell: FC<GridCustomCellProps> = ({ dataIndex, ...props }) => {
  const optionsList = useInventoryDetailCtx((s) => s.optionsList);
  const setOptionState = useInventoryDetailCtx((s) => s.setOptionsState);
  const setOptionsList = useInventoryDetailCtx((s) => s.setOptionsList);

  const isVerified = optionsList[dataIndex]!.verified;

  return (
    <td
      {...props.tdProps}
      style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Checkbox
        name={dataIndex.toString()}
        checked={isVerified}
        onChange={async (e) => {
          const updatedOptionsItem = { ...optionsList[dataIndex]!, verified: e.value };
          const res = await inventoryService.updateVehicleOption(updatedOptionsItem);
          setOptionsList(res);
          setOptionState(res);
        }}
      />
    </td>
  );
};

export const CommentCell: FC<GridCustomCellProps> = ({ dataIndex, ...props }) => {
  const checkInList = useInventoryDetailCtx((s) => s.checkInList);
  const setCheckInList = useInventoryDetailCtx((s) => s.setCheckInList);
  const setCheckInState = useInventoryDetailCtx((s) => s.setCheckInState);
  const [inputValue, setInputValue] = useState<string>(checkInList[dataIndex]?.comments || "");

  const debouncedOnChange = debounce(async (value: string) => {
    const updatedCheckInItem = { ...checkInList[dataIndex]!, comments: value };
    const res = await inventoryService.updateCheckInOption(updatedCheckInItem);
    setCheckInList(res);
    setCheckInState(res);
  }, 400);

  const handleInputChange = (e: any) => {
    const value = e.target.value;
    setInputValue(value);
    debouncedOnChange(value);
  };

  useEffect(() => {
    setInputValue(checkInList[dataIndex]?.comments || "");
  }, [dataIndex]);

  return (
    <td {...props.tdProps} className={styles.commentCell}>
      <Input
        name={dataIndex.toString()}
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Enter comment"
      />
    </td>
  );
};

export const DeleteCell: FC<
  { onDelete: (recIdToDelete: number) => void } & GridCustomCellProps
> = ({ onDelete, ...props }) => {
  return (
    <td
      {...props.tdProps}
      style={{ textAlign: "center", cursor: "pointer" }}
      onClick={() => onDelete(props.dataItem.recId)}
    >
      <FaDeleteLeft size={24} />
    </td>
  );
};
