import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { inventoryService } from "@/services/inventoryService";
import { useInventoryDetailCtx } from "../../InventoryDetailProvider";
import OptionGrid from "./checkInGrid/optionsGrid";
import { PageHeader } from "@/components/pageHeader/PageHeader";
import { OptionsItem } from "@/interfaces";
// mui
import Grid from "@mui/material/Unstable_Grid2";
//style
import { BG_HIGHLIGHT } from "@/mui/theme/colors";

export const loadCheckInViewData = async (
  invRecId: number | undefined,
  setIsLoading: (isLoading: boolean) => void,
  setOptionsState: (optionsState: OptionsItem[]) => void,
  setOptionsList: (optionsList: OptionsItem[]) => void
) => {
  if (!invRecId) {
    return;
  }

  try {
    setIsLoading(true);
    const initVehicleOptions = await inventoryService.getVehicleOptionsList(invRecId);

    const validatedOptionsList = initVehicleOptions.map(
      (v) =>
        new OptionsItem(
          v.orgId,
          v.locIdAfc,
          v.recId,
          v.compIdAfc,
          v.compIdSolutions,
          v.locIdSolutions,
          v.vehRecId,
          v.invId,
          v.timeUploaded,
          v.userRecId,
          v.sOrder,
          v.option,
          v.verified,
          v.display,
          v.vin,
          v.compId,
          v.locId
        )
    );

    setOptionsState(validatedOptionsList);
    setOptionsList(validatedOptionsList);

    setIsLoading(false);
  } catch (error) {
    console.error(error);
  }
};

const VehicleOptions = () => {
  const { invRecId } = useParams();
  const setIsLoading = useInventoryDetailCtx((s) => s.setIsLoading);
  const setOptionsState = useInventoryDetailCtx((s) => s.setOptionsState);
  const setOptionsList = useInventoryDetailCtx((s) => s.setOptionsList);
  const inventoryVehicle = useInventoryDetailCtx((s) => s.inventoryVehicle);

  useEffect(() => {
    loadCheckInViewData(Number(invRecId), setIsLoading, setOptionsState, setOptionsList);
  }, [inventoryVehicle.vin]);

  return (
    <Grid container direction="column" gap={2} sx={{ height: "80vh", width: "100%", overflow: "hidden", padding: "20px" }}>
      {/* Header Section */}
      <Grid overflow={"hidden"}>
          <PageHeader headerLabel="Vehicle Options" />
      </Grid>

      {/* OptionGrid Section */}
      <Grid
        sx={{
          flex: 1, // Takes the remaining space
          borderRadius: 2.5,
          backgroundColor: BG_HIGHLIGHT,
          overflow: "hidden", // Prevent content overflow
        }}
      >
        <OptionGrid />
      </Grid>
    </Grid>
  );
};

export default VehicleOptions;
