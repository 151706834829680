import { FC, useEffect } from 'react';
import { Controller, useWatch } from 'react-hook-form';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
// kendo
import { Button, Checkbox, TextInput, NumberInput } from "@/components";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import DateInput from "@/mui/components/form/MuiKendoDateField";
import { useLhPhDetailCtx } from './LhPhDetailProvider';
import dayjs, { Dayjs } from 'dayjs';
import { LhPhSectionHeader } from './LhPhSectionHeader';
import { getLHPHPayment } from './utils';

const subListStyles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
} as const;

const subListColumn = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'stretch',
  flexWrap: 'wrap',
  flex: '1',
  padding: '20px',
} as const;

const headingSubColumn = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'stretch',
  flexWrap: 'wrap',
  flex: '1',
} as const;

const checkboxLabelStyle = { flex: '1' } as const;
const checkDivStyle = { flex: '1.25' } as const;

const LhPhFundingForm: FC = () => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useLhPhDetailCtx((s) => s.lhPhForm);
  
  const lhPhDetail = useLhPhDetailCtx((s) => s.lhPhDetail);
  const getFundAllowance = useLhPhDetailCtx((s) => s.getFundAllowance);
  const saveLhPhData = useLhPhDetailCtx((s) => s.saveLhPhData);
  
  // Watch individual form fields
  const purprice = useWatch({
    control,
    name: 'purprice',
    defaultValue: 0,
  });

  const lhphFundRate = useWatch({
    control,
    name: 'lhphFundRate',
    defaultValue: 0,
  });

  const fundExpAllow = useWatch({
    control,
    name: 'fundExpAllow',
    defaultValue: 0,
  });

  const totexp = useWatch({
    control,
    name: 'totexp',
    defaultValue: 0,
  });

  const fundRepoSwap = useWatch({
    control,
    name: 'fundRepoSwap',
    defaultValue: false,
  });

  // Calculate fundAllowance based on watched form values
  const computedFundAllowance = getFundAllowance(
    purprice || 0,
    lhphFundRate || 0,
    fundExpAllow || 0,
    totexp || 0
  );

  const isFunded = lhPhDetail?.fundDone === 'FUNDING';

  // Recalculate fundAmt when dependencies change
  useEffect(() => {
    const recalculatedFundAmt = getFundAllowance(
      purprice || 0,
      lhphFundRate || 0,
      fundExpAllow || 0,
      totexp || 0
    );
    setValue("fundAmt", recalculatedFundAmt);
  }, [purprice, lhphFundRate, fundExpAllow, totexp, setValue, getFundAllowance]);

  // Watch specific fields for fundPmt calculation
  const currentFundAmt = useWatch({
    control,
    name: 'fundAmt',
    defaultValue: 0,
  });
  const currentFundDate = useWatch({
    control,
    name: 'fundDate',
    defaultValue: '',
  });
  const currentFundDueDate = useWatch({
    control,
    name: 'fundDueDate',
    defaultValue: '',
  });

  // Update fundPmt based on current values
  useEffect(() => {
    if (currentFundDate && currentFundDueDate && currentFundAmt) {
      const updatedFundPmt = getLHPHPayment(
        currentFundAmt,
        Math.min(lhPhDetail?.lhphMaxTerm || 0, lhPhDetail?.term || 0),
        dayjs(currentFundDate).startOf('day'),
        dayjs(currentFundDueDate).startOf('day'),
        lhPhDetail?.lhphIntRate || 0
      );
      setValue("fundPmt", updatedFundPmt);
    }
  }, [
    currentFundAmt,
    currentFundDate,
    currentFundDueDate,
    lhPhDetail?.lhphIntRate,
    lhPhDetail?.lhphMaxTerm,
    lhPhDetail?.term,
    setValue
  ]);
  return (
    <Grid
      container
      direction="column"
      flex={1}
      flexWrap="nowrap"
      sx={{
        padding: '40px',
        background: '#EFF0F4',
        borderRadius: '0px 0px 12px 12px',
        overflowX: 'auto',
        overflowY: 'scroll',
      }}
      component="form"
      onSubmit={handleSubmit(saveLhPhData)}
    >
      <Grid container direction="column" flex={1}>
        <LhPhSectionHeader title="Vehicle Cost" showBar />
        <Grid sx={subListStyles} container direction="row" flex={1}>
          <Grid
            sx={subListColumn}
            justifyContent="space-between"
            alignItems="stretch"
            gap={1}
            p="20px"
            container
            direction="column"
          >
            <CurrencyInput readOnly label="Vehicle Purchase Price" value={lhPhDetail?.purprice} />
            <CurrencyInput readOnly label="Total Expense" value={lhPhDetail?.totexp} />
            <Controller
              name="fundExpAllow"
              control={control}
              rules={{
                validate: (value) => {
                  if (!lhPhDetail || lhPhDetail.totexp === null || lhPhDetail.totexp === 0) return true;
                  return value <= lhPhDetail.totexp || "Cannot be greater than Total Expenses";
                },
              }}
              render={({ field: { onChange, value, ...field } }) => (
                <CurrencyInput
                  {...field}
                  value={value || 0}
                  required
                  label="Funded Recon (exp)"
                  onChange={(e) => {
                    const newValue = e.value ?? 0;
                    onChange(newValue);
                    const recalculatedFundAmt = getFundAllowance(
                      purprice || 0,
                      lhphFundRate || 0,
                      newValue,
                      totexp || 0
                    );
                    setValue("fundAmt", recalculatedFundAmt);
                  }}
                  errors={errors.fundExpAllow?.message}
                />
              )}
            />
          </Grid>
          <Grid
            sx={subListColumn}
            justifyContent="space-between"
            alignItems="stretch"
            gap={1}
            p="20px"
            container
            direction="column"
          >
            <CurrencyInput readOnly label="Fund Allowance" value={computedFundAllowance} />
            <NumberInput
              readOnly
              isPercentage
              label="LHPH Interest Rate"
              value={lhPhDetail?.lhphIntRate ? lhPhDetail.lhphIntRate / 100 : 0}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="column" flex={1}>
        <LhPhSectionHeader title="Funding Request" showBar />
        <Grid sx={subListStyles} container direction="row">
          <Grid
            sx={subListColumn}
            justifyContent="space-between"
            alignItems="stretch"
            gap={1}
            p="20px"
            container
            direction="column"
          >
            <Controller
              name="fundRequested"
              control={control}
              disabled={isFunded}
              render={({ field: { onChange, ...field } }) => (
                <Checkbox
                  {...field}
                  checkboxInputWraperStyle={checkDivStyle}
                  labelStyles={checkboxLabelStyle}
                  labelPlacement={'before'}
                  label="Request Funding"
                  onChange={(e) => {
                    onChange(e.value);
                    setValue('fundRepoSwap', !e.value);
                  }}
                />
              )}
            />
            <TextInput
              readOnly
              label="Funding Status"
              value={!lhPhDetail?.fundDone ? '' : lhPhDetail.fundDone.toString()}
            />
            <Controller
              name="fundRepoSwap"
              control={control}
              disabled={isFunded}
              render={({ field: { onChange, value, ...field } }) => (
                <Checkbox
                  {...field}
                  checked={value}
                  labelStyles={checkboxLabelStyle}
                  checkboxInputWraperStyle={checkDivStyle}
                  labelPlacement={'before'}
                  label="Repo Swap"
                  onChange={(e) => {
                    onChange(e.value);
                    setValue('fundRequested', !e.value);
                  }}
                />
              )}
            />
            <Controller
              name="fundDate"
              control={control}
              rules={{
                required: 'Date is required',
              }}
              render={({ field: { value, onChange, ...field } }) => {
                const handleDateChange = (dateValue: Dayjs | null, context: any) => {
                  const formattedDate = dateValue ? dateValue.format('YYYY-MM-DD') : '';
                  onChange(formattedDate);
                };
                return (
                  <DateInput
                    {...field}
                    value={dayjs(value).isValid() ? dayjs(value) : null}
                    onChange={handleDateChange}
                    disabled={isFunded}
                    label="Fund Date"
                    required
                    errors={errors.fundDate?.message}
                  />
                );
              }}
            />
          </Grid>

          {/* Conditional Rendering Based on fundRepoSwap */}
          {!fundRepoSwap ? (
            <Grid
              sx={subListColumn}
              justifyContent="space-between"
              alignItems="stretch"
              gap={1}
              p="20px"
              container
              direction="column"
            >
              <NumberInput
                readOnly
                label="Funding Term"
                value={Math.min(lhPhDetail?.lhphMaxTerm ?? 0, lhPhDetail?.term ?? 0) || 0}
              />
              <Controller
                name="fundPmt"
                control={control}
                render={({ field }) => (
                  <CurrencyInput {...field} readOnly label="Funding Payment" />
                )}
              />
              <Controller
                name="fundDueDate"
                control={control}
                rules={{
                  required: 'Date is required',
                }}
                render={({ field: { value, onChange, ...field } }) => {
                  const handleDateChange = (dateValue: Dayjs | null, context: any) => {
                    const formattedDate = dateValue ? dateValue.format('YYYY-MM-DD') : '';
                    onChange(formattedDate);
                  };
                  return (
                    <DateInput
                      {...field}
                      value={dayjs(value).isValid() ? dayjs(value) : null}
                      onChange={handleDateChange}
                      label="Fund Due Date"
                      required
                      errors={errors.fundDueDate?.message}
                    />
                  );
                }}
              />
              <Controller
                name="fundAmt"
                control={control}
                disabled={isFunded}
                rules={{
                  required: 'Funding Amount is required',
                  max: { value: computedFundAllowance, message: 'Cannot be greater than Fund Allowance' },
                }}
                render={({ field, ...other }) => (
                  <CurrencyInput
                    label="Funding Amount"
                    required
                    {...field}
                    errors={errors.fundAmt?.message}
                  />
                )}
              />
            </Grid>
          ) : (
            <Grid sx={subListColumn} container direction="column"></Grid>
          )}
        </Grid>
      </Grid>
      <Grid container direction="column" flex={1}>
        <LhPhSectionHeader title="Paid Off Information" showBar />
        <Grid sx={subListStyles} container direction="row">
          <Grid sx={headingSubColumn} container direction="column" flex={1}>
            <Grid
              justifyContent="space-between"
              alignItems="stretch"
              gap={1}
              p="20px"
              container
              direction="column"
            >
              <Controller
                name="fundPO"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checkboxInputWraperStyle={checkDivStyle}
                    labelStyles={checkboxLabelStyle}
                    labelPlacement={'before'}
                    label="Pay-Off Funded"
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                  />
                )}
              />
              <Controller
                name="fundPOAmt"
                control={control}
                render={({ field }) => (
                  <CurrencyInput label="Funded Pay Off Amount" {...field} />
                )}
              />
              <Controller
                name="fundPODate"
                control={control}
                render={({ field: { value, onChange, ...field } }) => {
                  const handleDateChange = (dateValue: Dayjs | null, context: any) => {
                    const formattedDate = dateValue ? dateValue.format('YYYY-MM-DD') : '';
                    onChange(formattedDate);
                  };
                  return (
                    <DateInput
                      {...field}
                      value={dayjs(value).isValid() ? dayjs(value) : null}
                      onChange={handleDateChange}
                      label="Date Pay-Off Funded"
                      errors={errors.fundPODate?.message}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid sx={headingSubColumn} container direction="column" flex={1}>
            <Grid
              justifyContent="space-between"
              alignItems="stretch"
              gap={1}
              p="20px"
              container
              direction="column"
            >
              <Controller
                name="funded"
                control={control}
                disabled
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checkboxInputWraperStyle={checkDivStyle}
                    labelStyles={checkboxLabelStyle}
                    labelPlacement={'before'}
                    label="Funded"
                  />
                )}
              />
              <Controller
                name="fundedon"
                control={control}
                disabled
                render={({ field: { value, onChange, ...field } }) => {
                  const handleDateChange = (dateValue: Dayjs | null, context: any) => {
                    const formattedDate = dateValue ? dateValue.format('YYYY-MM-DD') : '';
                    onChange(formattedDate);
                  };
                  return (
                    <DateInput
                      {...field}
                      value={dayjs(value).isValid() ? dayjs(value) : null}
                      onChange={handleDateChange}
                      label="Date Funded"
                      errors={errors.fundedon?.message}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-end">
          <Button
            label="Submit"
            type="submit"
            buttonStyle={{ width: '134px', margin: '0px 10px' }}
          />
          <Button
            label="Cancel"
            type="button"
            buttonStyle={{ width: '134px', margin: '0px 10px' }}
            onClick={() => {
              handleSubmit(saveLhPhData)(); // Optionally save or reset
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LhPhFundingForm;