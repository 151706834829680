import React, { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Grid, Box, IconButton } from "@mui/material";
import { Icons } from "@/components/icons";
import { SectionHeader } from "@/components/sectionHeader/SectionHeader";
import { TextInput } from "@/components/inputs/text/TextInput";
import { Loader } from "@/components";
import { Button } from "@/components/button/Button";
import { toast } from "react-toastify";

import { useCompanyMgmtCtx } from "./CompanyMgmtProvider";
import { companyService } from "@/services/companyService";
import { DCS_BLUE } from "@/mui/theme/colors";

interface ChecklistItem {
  recID: number;
  orgID: number;
  compID: number;
  pOrder: number;
  body: string;
  type: string;
}

interface FormValues {
  openingChecklist: ChecklistItem[];
  closingChecklist: ChecklistItem[];
}

const CompanyChecklist: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { companyDetails } = useCompanyMgmtCtx((state) => ({
    companyDetails: state.companyDetails,
  }));

  const { control, reset, handleSubmit, watch, setValue } = useForm<FormValues>({
    defaultValues: {
      openingChecklist: [],
      closingChecklist: [],
    },
  });

  const openingChecklist = watch("openingChecklist");
  const closingChecklist = watch("closingChecklist");

  useEffect(() => {
    const fetchChecklist = async () => {
      if (!companyDetails?.recId) return;

      try {
        setIsLoading(true);
        const data = await companyService.getCompanyChecklist(companyDetails.recId);

        const openingChecklist = data.filter((item: ChecklistItem) => item.type === "Daily Opening Checklist");
        const closingChecklist = data.filter((item: ChecklistItem) => item.type === "Daily Closing Checklist");

        reset({ openingChecklist, closingChecklist });
      } catch (err) {
        console.error("Failed to fetch checklist:", err);
        toast.error("Error fetching checklist data.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchChecklist();
  }, [companyDetails, reset]);

  const reorderItems = (list: ChecklistItem[]): ChecklistItem[] =>
    list.map((item, index) => ({ ...item, pOrder: index + 1 }));

  const handleAddNewItem = (listName: "openingChecklist" | "closingChecklist") => {
    const currentList = watch(listName);
    const newItem: ChecklistItem = {
      recID: 0, // Indicates a new item
      orgID: companyDetails?.orgRecId || 0,
      compID: companyDetails?.recId || 0,
      pOrder: currentList.length + 1,
      body: "",
      type: listName === "openingChecklist" ? "Daily Opening Checklist" : "Daily Closing Checklist",
    };
    setValue(listName, reorderItems([...currentList, newItem]));
  };

  const handleDeleteItem = (listName: "openingChecklist" | "closingChecklist", index: number) => {
    const currentList = watch(listName);
    const updatedList = currentList.filter((_, idx) => idx !== index);
    setValue(listName, reorderItems(updatedList));
  };

  const onSubmit = async (formData: FormValues) => {
    try {
      const updatedData = {
        openingChecklist: reorderItems(formData.openingChecklist),
        closingChecklist: reorderItems(formData.closingChecklist),
      };

      await companyService.updateCompanyChecklist(updatedData); // Updated API call
      reset(updatedData); // Reset form state
    } catch (error) {
      console.error("Error updating checklist:", error);
      toast.error("Error updating checklist.");
    }
  };

  const renderChecklistItems = (checklist: ChecklistItem[], controlName: keyof FormValues) => (
    <Grid container spacing={2}>
      {checklist.map((item, index) => (
        <Grid container item xs={12} sm={6} key={item.recID} alignItems="center" spacing={1}>
          <Grid item xs={10}>
            <Controller
              name={`${controlName}.${index}.body` as const}
              control={control}
              render={({ field }) => (
                <TextInput
                  label={`Checklist Item ${index + 1}`}
                  {...field}
                  value={String(field.value || "")}
                />
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <IconButton
              onClick={() => handleDeleteItem(controlName, index)}
              sx={{
                color: "red",
                "&:hover": { backgroundColor: "rgba(255, 0, 0, 0.1)" },
              }}
              aria-label="delete"
            >
              <Icons.Trash style={{ color: DCS_BLUE, width: '14px', height: '14px' }}/>
            </IconButton>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );

  if (isLoading) {
    return <Loader size="large" />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ bgcolor: "grey.100", p: 4, borderRadius: 2 }}>
        <Grid container direction="column" spacing={4}>
          {/* Opening Checklist Section */}
          <Grid item>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <SectionHeader title="Daily Opening Checklist" />
              <Button
                label="Add New"
                buttonStyle={{ width: "140px" }}
                onClick={() => handleAddNewItem("openingChecklist")}
              />
            </Box>
            {renderChecklistItems(openingChecklist, "openingChecklist")}
          </Grid>

          {/* Closing Checklist Section */}
          <Grid item>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <SectionHeader title="Daily Closing Checklist" />
              <Button
                label="Add New"
                buttonStyle={{ width: "140px" }}
                onClick={() => handleAddNewItem("closingChecklist")}
              />
            </Box>
            {renderChecklistItems(closingChecklist, "closingChecklist")}
          </Grid>

          {/* Action Buttons */}
          <Grid container justifyContent="flex-end" spacing={2} sx={{ mt: 4 }}>
            <Grid item>
              <Button type="submit" label="Submit" buttonStyle={{ width: "140px" }} />
            </Grid>
            <Grid item>
              <Button label="Cancel" color="secondary" buttonStyle={{ width: "140px" }} />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default CompanyChecklist;
