import { FC, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Button } from '@/components/button/Button';
import { Spacer } from '@/components/spacer/Spacer';
import { useAppDispatch } from '@/store/store';
import { accountActions, useAccountSelector } from '@/features/Accounts/accountSlice';
import { useHotlistCtx } from '@/components/hotlist/HotlistProvider';
import { accountsService } from '@/services/accountsService';
import useFetchAndNavAccountDetail from '@/features/Accounts/accountsSubviews/AccountDetail/components/quickActionsModal/useFetchAndNav';
import { sanitizePhoneInput } from '@/utils/helpers/general';
import { DropdownInput } from '@/components';

interface ContactOption {
  appBuyerRecId: number;
  contact: string;
  email: string;
  phoneNumber: string;
}

type SendTextToPayProps = {
  reloadActivity: () => Promise<void>; 
};

const SendTextToPay: FC<SendTextToPayProps> = ({ reloadActivity }) => {
  const params = useParams();
  const loc = useLocation();
  const colRecId = Number(params.colRecId);
  const fetchAndNav = useFetchAndNavAccountDetail();
  const appRecId = useAccountSelector((s) => s.accountInformation!.appRecId);
  const isLegalStatus = useAccountSelector((s) => s.accountInformation!.legalStatus);
  const shouldNotTextBuyer = useAccountSelector((s) => s.contactInformation.buyer.noText);
  const shouldNotTextCoBuyer = useAccountSelector((s) => s.contactInformation.coBuyer?.noText);
  const sendTextToPayResponse = useAccountSelector((s) => s.sendTextToPayResponse);
  const fetchHotlist = useHotlistCtx((s) => s.fetchHotlist);

  const accountInformation = useAccountSelector((s) => s.accountInformation);
  const [contacts, setContacts] = useState<ContactOption[]>([]);
  const [selectedContact, setSelectedContact] = useState<ContactOption | null>(null);

  // Event handlers
  const dispatch = useAppDispatch();
  const onCloseButtonClick = () => {dispatch(accountActions.setQuickActionsModalOpened(false));};

  const handleSendTextToPay = () => {
    if(!selectedContact) return;

    accountsService
      .sendTextToPay(appRecId, colRecId, selectedContact.email, sanitizePhoneInput(selectedContact.phoneNumber))
      .then(async (data) => {
        if (data.success) {
          dispatch(
            accountActions.setSendTextToPayResponse('Thank you, your message has been sent')
          );

          // Update relevant SAM hotlist AFTER response is received
          fetchHotlist(appRecId!, 'Account', 'Active');
          await reloadActivity();

          const browserPath = loc.pathname.replace(params['*'] || ' ', '');
          fetchAndNav(browserPath, colRecId);
        } else {
          dispatch(
            accountActions.setSendTextToPayResponse(data.message || 'Unable to send text-to-pay message')
          );
        }
      })
      .catch((err) => {
        dispatch(
          accountActions.setSendTextToPayResponse('Unable to send text-to-pay message')
        );
      });
  };

  useEffect(() => {
    if(!accountInformation) return;

    const { buyer, coBuyer } = accountInformation;
    const hasCoBuyer = !!(coBuyer?.appBuyerRecId && coBuyer?.firstName);
    
    if (!shouldNotTextBuyer) {
      setContacts([{ 
        appBuyerRecId: buyer.appBuyerRecId!,
        contact: `Buyer - ${buyer.firstName} ${buyer.lastName}`, 
        email: buyer.email,
        phoneNumber: buyer.cellPhone
      }]);
    }
    
    if (hasCoBuyer && !shouldNotTextCoBuyer) {
      setContacts((prevContacts) => [...prevContacts, { 
        appBuyerRecId: coBuyer.appBuyerRecId!,
        contact: `Co-buyer - ${coBuyer.firstName} ${coBuyer.lastName}`, 
        email: coBuyer.email,
        phoneNumber: coBuyer.cellPhone,
      }]);
    }
  }, [accountInformation])

  useEffect(() => {
    if (contacts.length > 0) {
      setSelectedContact(contacts[0] ?? null);
    }
  }, [contacts]);

  if (shouldNotTextBuyer && shouldNotTextCoBuyer) {
    return <div>Both Buyer and Co-Buyer have no text preference on</div>;
  } else if (isLegalStatus) {
    return <div>Account in legal status, no contact can be made</div>;
  } else if (sendTextToPayResponse) {
    return <div>{sendTextToPayResponse}</div>;
  } else {
    return (
      <div>
        <div style={{ display: "flex", flex: 1, flexDirection: "row", alignItems: "center", gap: 10 }}>
          <span>Are you sure you want to send a text to pay message to</span>
          <DropdownInput
            required
            data={contacts}
            dataItemKey="appBuyerRecId"
            textField="contact"
            value={selectedContact}
            onChange={(e) => {
              setSelectedContact(e.value);
            }}
          />
        </div>
        <Spacer size={20} />
        <Button
          label="Yes"
          onClick={handleSendTextToPay}
        />
        <Spacer size={4} />
        <Button label="No" fillMode="outline" onClick={onCloseButtonClick} />
      </div>
    );
  }
};

export default SendTextToPay;
