import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
// kendo
import {   Button, DropdownInput, TextInput } from "@/components";
import { Spacer } from "@/components/spacer/Spacer";
import DateInput from "@/mui/components/form/MuiKendoDateField";
import { Icons } from '@/components/icons';
// components
import { PasstimeWarnForm } from './PasstimeWarnForm';
// state
import { useGpsCtx } from './GpsProvider';
import { useAccountSelector } from '@/features/Accounts/accountSlice';
// utils
import { accountsService } from '@/services/accountsService';
import { systemService } from '@/services/systemService';
import { PasstimePackage } from './interfaces';
import { getRouteParamNum } from '@/utils/routing/formatting';
// interfaces
import { GpsDeviceType } from '@/enums';
import { GpsDevice, UpdateGpsInformation } from './interfaces';
// style
import styles from './Gps.module.scss';

const GpsDeviceInfo: FC<{ isInventory?: boolean; isSold?: boolean }> = ({
  isInventory = false,
  isSold = true,
}) => {
  const colRecId = getRouteParamNum(useParams().colRecId);

  const accountInformation = useAccountSelector((s) => s.accountInformation)!;
  const gpsInformation = useGpsCtx((s) => s.gpsInformation);
  const loadGpsInfo = useGpsCtx((s) => s.loadGpsInfo);
  const setGpsHistory = useGpsCtx((s) => s.setGpsHistory);

  const vehRecId = getRouteParamNum(
    isInventory ? useParams().invRecId : accountInformation?.vehRecId
  );

  // Local state
  const [gpsModels, setGpsModels] = useState<string[]>([]);
  const [isUpdateReqLoading, setIsUpdateReqLoading] = useState(false);
  const [isPasstimeSyncReqLoading, setIsPasstimeSyncReqLoading] = useState(false);
  const [isEnableReqLoading, setIsEnableReqLoading] = useState(false);
  const [isEnable24HoursReqLoading, setIsEnable24HoursReqLoading] = useState(false);
  const [isDisableReqLoading, setIsDisableReqLoading] = useState(false);
  const [warnModalOpen, setWarnModalOpen] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm<UpdateGpsInformation>();
  const device = watch().device;

  // Event handlers
  const loadGpsModels = async () => {
    if (!device) return;
    const modelsRes = await systemService.getGpsModels(device);
    setGpsModels(modelsRes);
  };
  const handlePasstimeSync = async () => {
    if (!vehRecId) return;

    try {
      setIsPasstimeSyncReqLoading(true);
      await systemService.updatePasstimeCustomer(vehRecId);
      toast.success('Sync successful');
    } finally {
      setIsPasstimeSyncReqLoading(false);
    }
  };

  const handleEnable = async () => {
    if (!vehRecId) return;

    try {
      setIsEnableReqLoading(true);
      await systemService.enableDisablePasstimeDevice(vehRecId, true);
      toast.success('Vehicle enabled');
    } finally {
      setIsEnableReqLoading(false);
    }
  };

  const handleEnable24Hours = async () => {
    if (!vehRecId) return;

    try {
      setIsEnable24HoursReqLoading(true);
      await systemService.sendPasstimeEmergencyCode(vehRecId);
      toast.success('Vehicle enabled for 24 hours');
    } finally {
      setIsEnable24HoursReqLoading(false);
    }
  };

  const handleDisable = async () => {
    if (!vehRecId) return;

    try {
      setIsDisableReqLoading(true);
      await systemService.enableDisablePasstimeDevice(vehRecId, false);
    } finally {
      setIsDisableReqLoading(false);
    }
  };

  const handleFormSubmit = async (formBody: UpdateGpsInformation) => {
    // @todo validate form

    try {
      setIsUpdateReqLoading(true);
      var success = await accountsService.updateGpsInformation(formBody);

      if(!success) return;
      toast.success('Device information updated');

      if (formBody.device === GpsDevice.enum.PassTime && !isSold) {
        toast.info(
          'Unsold vehicle - Cannot associate PassTime customer record at this time. After vehicle is sold, return to this page and click "Sync Update with PassTime" button.'
        );
      }

      const gpsRecId = isInventory ? vehRecId : colRecId;
      const recIdType = isInventory ? 'vehRecId' : 'colRecId';

      await loadGpsInfo(gpsRecId, recIdType);
    } finally {
      setIsUpdateReqLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      if(!gpsInformation?.recId) return;

      setIsUpdateReqLoading(true);
      var success = await accountsService.deleteGpsInformation(gpsInformation.recId);
      if(!success) return;

      toast.success('Device information deleted');

      const gpsRecId = isInventory ? vehRecId : colRecId;
      const recIdType = isInventory ? 'vehRecId' : 'colRecId';
      await loadGpsInfo(gpsRecId, recIdType);
      setGpsHistory([]);
    } finally {
      setIsUpdateReqLoading(false);
    }
  };

  useEffect(() => {
    if (gpsInformation) {
      reset({
        recId: gpsInformation.recId,
        device: gpsInformation.device,
        gpsType: gpsInformation.gpsType,
        deviceModel: gpsInformation.deviceModel,
        packageType: gpsInformation.packageType,
        serialNumber: gpsInformation.serialNumber,
      });
    }
  }, [gpsInformation]);

  useEffect(() => {
    loadGpsModels();
  }, [device]);

  const isPassTime = device === GpsDevice.enum.PassTime;
  const isSpireon = device === GpsDevice.enum.Spireon;
  const isIturan = device === GpsDevice.enum.Ituran;
  const isUnsupportedProvider = device && !isPassTime && !isSpireon && !isIturan;

  return (
    <div>
      <div className={styles.formContainer}>
        <form onSubmit={handleSubmit(handleFormSubmit)} className={styles.form}>
          <Controller
            name="device"
            control={control}
            rules={{ required: 'Field is required' }}
            render={({ field }) => (
              <DropdownInput
                label="Provider"
                data={GpsDevice.options}
                required
                errors={errors.device?.message}
                {...field}
              />
            )}
          />
          {isUnsupportedProvider ? (
            <div>{device} is not currently supported in the new UI</div>
          ) : (
            <>
              {isPassTime && (
                <Controller
                  name="gpsType"
                  control={control}
                  rules={{ required: 'Field is required' }}
                  render={({ field }) => (
                    <DropdownInput
                      label="Device Type"
                      data={Object.values(GpsDeviceType)}
                      required
                      errors={errors.gpsType?.message}
                      {...field}
                    />
                  )}
                />
              )}
              {isPassTime && (
                <Controller
                  name="deviceModel"
                  control={control}
                  rules={{ required: 'Field is required' }}
                  render={({ field }) => (
                    <DropdownInput
                      label="Model"
                      data={gpsModels || []}
                      required
                      errors={errors.deviceModel?.message}
                      {...field}
                    />
                  )}
                />
              )}
              {isPassTime && (
                <Controller
                  name="packageType"
                  control={control}
                  rules={{ required: 'Field is required' }}
                  render={({ field }) => (
                    <DropdownInput
                      label="Package Type"
                      data={PasstimePackage.options}
                      required
                      errors={errors.packageType?.message}
                      {...field}
                    />
                  )}
                />
              )}
              {!!device && (
                <Controller
                  name="serialNumber"
                  control={control}
                  rules={{ required: 'Field is required' }}
                  render={({ field }) => (
                    <TextInput
                      label="ID Number"
                      required
                      errors={errors.serialNumber?.message}
                      {...field}
                    />
                  )}
                />
              )}
              {gpsInformation?.expires && isPassTime && (
                <DateInput label="Expires On" value={gpsInformation.expires} readOnly />
              )}
              <div className={styles.buttonBar}>              
                { (gpsInformation?.expires || gpsInformation?.serialNumber) &&
                  <Button
                    label={<Icons.Trash style={{ color: 'white', width: '14px', height: '14px' }}/>}
                    loading={isUpdateReqLoading}
                    fullWidth={false}                  
                    type="button"
                    onClick={handleDelete}
                  />
                }

                <Button
                  label="Update"
                  loading={isUpdateReqLoading}
                  fullWidth={false}
                />
              </div>
            </>
          )}
        </form>
        {isPassTime && (
          <Button
            label="Sync Update with PassTime"
            onClick={handlePasstimeSync}
            loading={isPasstimeSyncReqLoading}
            fullWidth={false}
            disabled={!isSold}
            style={{ float: 'right' }}
          />
        )}
      </div>
      <Spacer size={20} />
      <div className={styles.hr} />
      <Spacer size={20} />
      {gpsInformation?.gpsType === GpsDeviceType.GpsStarterInterrupt && (
        <div className={styles.buttonBar}>
          <Button
            fullWidth={false}
            label="Enable Vehicle"
            secondary
            loading={isEnableReqLoading}
            onClick={handleEnable}
          />
          <Button
            fullWidth={false}
            label="Enable for 24 hours"
            themeColor="base"
            secondary
            loading={isEnable24HoursReqLoading}
            onClick={handleEnable24Hours}
          />
          <Button
            fullWidth={false}
            label="Disable Vehicle"
            themeColor="error"
            secondary
            loading={isDisableReqLoading}
            onClick={handleDisable}
          />
          {isPassTime && (
            <Button
              fullWidth={false}
              label={
                <div>
                  Set Warning <Icons.Gears style={{ width: 15, height: 15 }} />
                </div>
              }
              themeColor="error"
              secondary
              onClick={() => setWarnModalOpen(true)}
            />
          )}
          {isPassTime && warnModalOpen && (
            <PasstimeWarnForm onClose={() => setWarnModalOpen(false)} />
          )}
        </div>
      )}
    </div>
  );
};

export default GpsDeviceInfo;
