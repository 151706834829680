import React, { FC, useEffect, useState } from "react";
import { GridToolbar } from "@progress/kendo-react-all";
import { Button, DropdownInput } from "@/components";
import styles from "./CheckInGrid.module.scss";
import { useInventoryDetailCtx } from "../../../InventoryDetailProvider";
import { CurrentInspection, VehicleInspector } from "@/interfaces";
import MuiKendoDatePicker from "@/mui/components/form/MuiKendoDatePicker";
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { inventoryService } from "@/services/inventoryService";
import { useParams } from "react-router-dom";

dayjs.extend(customParseFormat);

const InspectorSelector: FC = () => {
  const inspectorList = useInventoryDetailCtx((s) => s.inspectorList);
  const currentInspection = useInventoryDetailCtx((s) => s.currentInspection);
  const setCurrentInspection = useInventoryDetailCtx((s) => s.setCurrentInspection);
  const params = useParams();

  const getNameFromRecId = (list: VehicleInspector[], inspectorId: number) => {
    const item = list.find((item) => item.recId === inspectorId);
    return item ? item.shortName : null;
  };

  const currentName = (inspectItem: CurrentInspection) => {
    if (params["*"] === "vehicleCheckIn") {
      return {
        recId: inspectItem.ckInBy!,
        shortName: getNameFromRecId(inspectorList, inspectItem.ckInBy!),
      };
    } else if (params["*"] === "vehicleOptions") {
      return {
        recId: inspectItem.optBy!,
        shortName: getNameFromRecId(inspectorList, inspectItem.optBy!),
      };
    } else {
      return "";
    }
  };

  const parseInspectionDate = (dateString: string | null): Dayjs | null => {
    if (!dateString) return null;

    const format = "M/D/YYYY hh:mm:ssA";
    const parsedDate = dayjs(dateString, format);

    if (!parsedDate.isValid()) {
      console.warn("Invalid date format:", dateString);
      return null;
    }

    return parsedDate;
  };

  const [inspectorId, setInspectorId] = useState(0);
  const [inspectionDate, setInspectionDate] = useState<Dayjs | null>(
    parseInspectionDate(
      params["*"] === "vehicleCheckIn"
        ? currentInspection?.ckInDate ?? null
        : currentInspection?.optDate ?? null
    )
  );

  const handleInspectorSelection = (e: any) => {
    setInspectorId(e.value.recId!);
  };

  const handleDateSelect = (e: Dayjs | null) => {
    setInspectionDate(e);
  };

  const getCurrentInspection = async () => {
    try {
      const initVehicleInspection = await inventoryService.getCurrentInspection(
        Number(params.invRecId)
      );
      setCurrentInspection(initVehicleInspection as CurrentInspection);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async () => {
    if (!inspectionDate) return;
    try {
      const formattedDate = inspectionDate.toISOString();
      const payload =
        params["*"] === "vehicleCheckIn"
          ? {
              vehRecid: Number(params.invRecId),
              ckInBy: inspectorId || currentInspection.ckInBy,
              ckInDate: formattedDate,
            }
          : {
              vehRecid: Number(params.invRecId),
              optBy: inspectorId || currentInspection.optBy,
              optDate: formattedDate,
            };

      if (params["*"] === "vehicleCheckIn") {
        await inventoryService.submitCheckInInspector(payload);
      } else if (params["*"] === "vehicleOptions") {
        await inventoryService.submitOptionsInspector(payload);
      } else {
        throw new Error("Invalid list choice (valid options: CheckIn, Options)");
      }

      getCurrentInspection();
    } catch (e) {
      console.error("Unable to submit inspection data.", e);
    }
  };

  useEffect(() => {
    setInspectionDate(
      parseInspectionDate(
        params["*"] === "vehicleCheckIn"
          ? currentInspection?.ckInDate ?? null
          : currentInspection?.optDate ?? null
      )
    );
  }, [currentInspection, params]);

  return (
    <GridToolbar>
      <div className={styles.inspectorButton}>
        <div className={styles.columnSettings}>
          <DropdownInput
            defaultValue={currentName(currentInspection)}
            data={inspectorList}
            dataItemKey="recId"
            textField="shortName"
            onChange={handleInspectorSelection}
          />
        </div>
      </div>
      <MuiKendoDatePicker
        sx={{ width: "170px", fontSize: "14px" }}
        label="Inspection Date"
        value={inspectionDate}
        onChange={handleDateSelect}
      />
      <Button secondary label="Submit" themeColor="dark" onClick={handleSubmit} />
    </GridToolbar>
  );
};

export default InspectorSelector;
