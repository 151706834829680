import { toast } from "react-toastify";
import { AxiosError, isAxiosError } from "axios";
// state
import { store } from "@/store/store";
import { paymentActions } from "@/features/Accounts/accountsSubviews/AccountDetail/components/PaymentForm/paymentSlice";
import { oldPaymentActions } from "@/features/old/payment/oldPaymentSlice";
// utils
import { AxiosService } from "./axiosService";
import { displayErrors, displayMessage } from "@/utils/helpers/general";
// interfaces
import { ApiResponse } from "@/interfaces/Api";
import { PaymentStatusResponse, SavedPmtMethodRes } from "@/interfaces/CreditCard";
import {
  UpdateEmailPostReq,
  UpdateEmailOptOutStatusPostReq,
  TextToPayNumberPayload,
  IPaymentProviderConfigRes,
  PaymentDataRes,
  SaveMpdReq,
  SendAuthReq,
  AuthDocumentUpload,
  DeactivateMpdReq,
} from "@/interfaces/payment";
import {
  PmtPaidIn,
  PaymentInterval,
  ReversalType,
  RepayFeeModel,
  PaymentMethod,
  PmtContext,
} from "@/enums/payment";
import { IPmtSubviewReq, IPmtSubviewKeyRes } from "@/features/Accounts/pmtSubviews/interfaces";
import {
  IReversiblePaymentRes,
  ReversiblePaymentPostReq,
} from "@/features/Accounts/accountsSubviews/AccountDetail/components/PaymentReversal/interfaces";
import {
  ReversalSuccessNoti,
  RepayErrorNoti,
} from "@/features/Accounts/accountsSubviews/AccountDetail/components/PaymentReversal/PmtReversalDetail/PmtReversalForm/ReversalToastNotis";
import {
  PmtReq,
  PmtReqMisc,
} from "@/features/Accounts/accountsSubviews/AccountDetail/components/PaymentForm/reqValidation";
import { EmployeeField } from "@/interfaces/System";
import { CliqPmtReq } from "@/components/modals/PaymentModalGroup/CliqPaymentModal/cliqReqValidation";
import { SubmitCliqPaymentRes } from "@/interfaces/payment";
import { systemService } from "./systemService";
import { ColTypeCode } from "@/enums";

class PaymentService extends AxiosService {
  public constructor() {
    super();
  }

  /** @deprecated use `getPaymentDataByColRecId`/`getPaymentData` */
  async getPaymentDetails(colRecId?: number, appRecId?: number, colType?: string) {
    try {
      const { data } = await this.axios.get<GetPaymentData>("/Payment/Data", {
        params: { colRecId, appRecId, colType },
      });
      if (data) {
        // Temp fix so we don't have to update all references to convenienceFee and achConvenienceFee
        // Turns out convenienceFee is the openedge CC convenience fee for *mycarpay* and may be different in DMS
        // Same thing with achConvenienceFee
        // When we get a chance we should just update all references to the old fields to use the new fields
        data.convenienceFee = data.openEdgeDmsCcConvFee ?? data.convenienceFee;
        data.achConvenienceFee = data.openEdgeDmsAchConvFee ?? data.achConvenienceFee;
      }
      return data;
    } catch (err: any) {
      displayErrors(err.message);
      throw err;
    }
  }
  async getPaymentData(params: { colRecId: number } | { appRecId: number; colType: ColTypeCode }) {
    try {
      const res = await this.axios.get<PaymentDataRes>("/Payment/Data", { params });
      if (res.data) {
        res.data.convenienceFee = res.data.openEdgeDmsCcConvFee ?? res.data.convenienceFee;
        res.data.achConvenienceFee = res.data.openEdgeDmsAchConvFee ?? res.data.achConvenienceFee;
      }
      return PaymentDataRes.parse(res.data);
    } catch (err: any) {
      displayErrors(err.message);
      throw err;
    }
  }
  /** @note Returns type `PaymentDataRes` */
  async getPaymentDataByAppRecId(appRecId: number, colType: ColTypeCode) {
    return await this.getPaymentData({ appRecId, colType });
  }
  /** @note Returns type `PaymentDataRes` */
  async getPaymentDataByColRecId(colRecId: number) {
    return await this.getPaymentData({ colRecId });
  }

  /**
   * @deprecated move to users service (create usersService if not yet created)
   * @note this does not have any auth on the backend
   */
  async getUsersByCompanyId(compId: number) {
    try {
      const res = await this.axios.get<EmployeeField[]>("/Users/GetByCompanyId", {
        params: { compId },
      });
      return res.data;
    } catch (err: any) {
      console.error(err);
      displayErrors("Error fetching users" + err.message);
      throw err;
    }
  }

  async getRepoCompaniesByCompanyId(compId: number) {
    try {
      const res = await this.axios.get<ApiResponse<RepoCompany[]>>(
        "/Users/GetRepoCompsByCompanyId",
        { params: { compId } }
      );
      return res.data.data;
    } catch (err) {
      isAxiosError(err) && displayErrors("Error fetching repo companies:" + err.message);
      throw err;
    }
  }

  /** @deprecated use `submitPayment` */
  async postPaymentSubmit(payload: PostPaymentPayload) {
    try {      
      // We should split these out to new/old functions
      store.dispatch(oldPaymentActions.setPostPaymentLoading(true));
      store.dispatch(paymentActions.setPostPaymentLoading(true));
      const res = await this.axios.post("/Payment/Submit", payload);

      if (!payload.IsNewCard) toast.info("Payment posted");

      return res?.data;
    } catch (err) {
      console.error(err);

      // @todo handle error properly
      if (isAxiosError(err)) {
        const toastStr = err.response?.status === 403 ? err.response.data : err.message;
        toast.error(toastStr);
      }
      throw err;
    } finally {
      store.dispatch(oldPaymentActions.setPostPaymentLoading(false));
      store.dispatch(paymentActions.setPostPaymentLoading(false));
    }
  }

  /** Used for payment forms within accounts - to be used for all payments in standalone */
  async submitPayment(payload: PmtReq | PmtReqMisc) {
    try {
      // We should split these out to new/old functions
      store.dispatch(paymentActions.setPostPaymentLoading(true));
      const res = await this.axios.post("/Payment/Submit", payload);

      if (payload.useSavedPaymentMethod) toast.info("Payment posted");

      return res?.data;
    } catch (err) {
      console.error(err);

      // @todo handle error properly
      if (isAxiosError(err)) {
        let toastStr: string;
  
        if (err.response?.status === 403) {
          // Display the raw response data for 403 errors
          toastStr = err.response.data;
        } else if (err.response?.data?.message && err.response.data.message.trim() !== "") {
          // Display the message from the response payload if it exists and is not empty
          toastStr = err.response.data.message;
        } else {
          // Fallback to the default error message
          toastStr = err.message;
        }
  
        toast.error(`Error posting payment: ${toastStr}`);
      }
      throw err;
    } finally {
      store.dispatch(paymentActions.setPostPaymentLoading(false));
    }
  }

  async getSavedPaymentMethods(appRecId: number) {
    try {
      const res = await this.axios.get<SavedPmtMethodRes[]>("/Payment/SavedPaymentMethods", {
        params: { appRecId },
      });
      return res.data;
    } catch (err: any) {
      displayErrors(err.message);
      throw err;
    }
  }

  async deleteSavedPaymentMethods(appRecId: number, mpdId: string) {
    try {
      await this.axios.delete("/Payment/SavedPaymentMethod", {
        params: { appRecId, mpdId },
      });
    } catch (err: any) {
      toast.error("Unable to delete saved payment method.");
      throw err;
    }
  }

  async postUpdatedEmail(payload: UpdateEmailPostReq) {
    try {
      const { data } = await this.axios.post("/Users/UpdateEmail", payload);
      return data;
    } catch (err: any) {
      console.error(err.message);
      throw err;
    }
  }

  async postUpdatedOptOutStatus(payload: UpdateEmailOptOutStatusPostReq) {
    try {
      const { data } = await this.axios.post("/Users/UpdateEmailOptOut", payload);
      return data;
    } catch (err: any) {
      displayErrors(err.message);
      throw err;
    }
  }

  async getMiscCategories(orgId: number) {
    try {
      const { data } = await this.axios.get<string[]>("/Payment/MiscCategories", {
        params: { orgId },
      });
      return data;
    } catch (err: any) {
      displayErrors(err.message);
      throw err;
    }
  }

  // @todo add response type
  async postOpenEdgeCCPayment(tempToken: string, paymentLogRecId: number) {
    try {
      const res = await this.axios.post(`/Payment/Complete`, {
        PaymentLogRecId: paymentLogRecId,
        TemporaryToken: tempToken,
      });
      toast.success("Payment posted successfully");
      return res.data;
    } catch (err: any) {
      displayErrors(err.message ?? "There was an issue in processing your payment");
      throw err;
    }
  }

  /** @deprecated incorrect endpoint */
  async postCliqCcPayment(tempToken: string, paymentLogRecId: number) {
    try {
      const res = await this.axios.post(`/Payment/Complete`, {
        PaymentLogRecId: paymentLogRecId,
        TemporaryToken: tempToken,
      });
      toast.success("Payment posted successfully");
      return res.data;
    } catch (err: any) {
      this.displayErrors(err.message ?? "There was an issue in processing your payment", err);
      throw err;
    }
  }

  async getReceiptUrl(pmtRecId: number) {
    // @note Prevent sending req. and avoid unnecessary toast notifications
    if (pmtRecId === 0) throw new Error("getReceiptUrl(): pmtRecId === 0");

    try {
      // @todo move - React state should not be handled outside of React components
      store.dispatch(oldPaymentActions.setPostPaymentLoading(true));
      store.dispatch(paymentActions.setPostPaymentLoading(true));
      const res = await this.axios.get<string>("/Payment/ReceiptURL", { params: { pmtRecId } });
      return res.data;
    } catch (err: any) {
      displayErrors(err.message);
      console.error(`Error: getReceiptUrl ${pmtRecId}`, err);
      toast.error(`Error: getReceiptUrl ${pmtRecId}`);
      throw err;
    } finally {
      // @todo move - React state should not be handled outside of React components
      store.dispatch(oldPaymentActions.setPostPaymentLoading(false));
      store.dispatch(paymentActions.setPostPaymentLoading(false));
    }
  }

  async getPaymentStatus(paymentLogId: number) {
    try {
      const res = await this.axios.get<PaymentStatusResponse>("/Payment/PaymentStatus", {
        params: { paymentLogId },
      });
      return res.data;
    } catch (err: any) {
      displayErrors(err.message);
      console.error(`Error: getPaymentStatus ${paymentLogId}`, err);
      toast.error(`Error: getPaymentStatus ${paymentLogId}`);
      throw err;
    }
  }

  async sendTextToPay(appRecId: number, colRecId: number, email: string, phone: string) {
    try {
      const { data } = await this.axios.post("/Payment/SendTextToPayMessage", {
        appRecId,
        colRecId,
        email,
        phone,
      });
      if (data.success) {
        displayMessage(data.message || "Text message successfully sent");
      } else {
        // Display the message to the user
        alert(data.message || "MyCarPay is not enabled, so the text was not sent.");
      }

      return data;
    } catch (err: any) {
      // Handle unexpected errors
      displayErrors("An unexpected error occurred. Please try again later.");
      throw err;
    }
  }

  async postToggleTextToPay(payload: { colRecId: number; enabled: boolean }) {
    try {
      const { data } = await this.axios.post("/Users/ToggleTextToPay", payload);
      return data;
    } catch (err: any) {
      displayErrors("Unable to update opt out status");
      throw err;
    }
  }

  async updateTextToPayNumber(payload: TextToPayNumberPayload) {
    try {
      const { data } = await this.axios.post("/Users/UpdateTextToPayNumber", payload);
      return data;
    } catch (err: any) {
      displayErrors("Unable to update Text-To-Pay phone number");
      throw err;
    }
  }

  async sendReceipt(paymentRecId: number) {
    try {
      const { data } = await this.axios.post("/Payment/EmailReceipt", {
        paymentRecId,
      });
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async getPaymentProviders(colRecId: number) {
    try {
      const { data } = await this.axios.get<IPaymentProviderConfigRes>("/Payment/Providers", {
        params: { colRecId },
      });
      return data;
    } catch (err) {
      toast.error(`Error fetching payment providers. Collection ID: ${colRecId}`);
      console.error(err);
      throw err;
    }
  }

  async getPaymentProvidersByCompany(compId: number) {
    const { data } = await this.axios.get<IPaymentProviderConfigRes>(
      "/Payment/ProvidersByCompany",
      { params: { compId } }
    );
    return data;
  }

  async getPaymentSubviewList(req: IPmtSubviewReq) {
    try {
      const { data } = await this.axios.post<ApiResponse<IPmtSubviewKeyRes>>(
        "/Payment/GetPaymentSubviewList",
        req
      );
      return data.data!;
    } catch (err: any) {
      toast.error("Unable to get payment subview list");
      throw err;
    }
  }

  async getReversiblePayments(colRecId: number, reversalType: ReversalType) {
    try {
      const { data } = await this.axios.get<ApiResponse<IReversiblePaymentRes[]>>(
        "/Payment/GetReversiblePayments",
        { params: { colRecId, reversalType } }
      );
      return data.data || [];
    } catch (err: any) {
      toast.error("Unable to fetch reversible payments");
      throw err;
    }
  }

  async submitPaymentReversal(req: ReversiblePaymentPostReq): Promise<IReversiblePaymentRes[]> {
    try {
      const res = await this.axios.post<ApiResponse<IReversiblePaymentRes[]>>(
        "/Payment/ReversePayment",
        req
      );
      if (res instanceof AxiosError) throw res;
      const asdf = toast.success(() => <ReversalSuccessNoti req={req} />);

      return res.data.data || [];
    } catch (e: any) {
      const err: AxiosError = e;
      // @ts-ignore
      const errMsg: string = err.response?.data?.Message;
      if (errMsg.includes("`pnRefs` < 1")) {
        toast.error(RepayErrorNoti);
      } else {
        toast.error(`Unable to submit payment reversal: ${errMsg}`);
      }
      throw err;
    }
  }

  async submitCliqPayment(req: CliqPmtReq) {
    try {
      const res = await this.axios.post<SubmitCliqPaymentRes>("/Payment/SubmitCliqPayment", req);
      return res.data;
    } catch (err) {
      toast.error("Error submitting Cliq payment");
      throw err;
    }
  }
  async printAuthForm(mpdId: string): Promise<{ pdfUrl: string }> {
    try {
      // TODO: Implement the actual API call once the backend route is available
      const { data } = await this.axios.get<{ pdfUrl: string }>("/Payment/PrintAuthForm", {
        params: { mpdId },
      });
      return data;
    } catch (err: any) {
      toast.error("Failed to print auth form.");
      throw err;
    }
  }

  async printAuthFormDoc(payload: PrintAuthForm) {
    try {
      const { data } = await this.axios.post<ApiResponse<string>>(
        "/Payment/PrintAuthForm",
        payload
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // Placeholder for Upload Auth. Form
  async uploadAuthForm(mpdId: string, file: File): Promise<{ success: boolean; message: string }> {
    try {
      // TODO: Implement the actual API call once the backend route is available
      const formData = new FormData();
      formData.append("mpdId", mpdId);
      formData.append("file", file);

      const { data } = await this.axios.post<{ success: boolean; message: string }>(
        "/Payment/UploadAuthForm",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return data;
    } catch (err: any) {
      toast.error("Failed to upload auth form.");
      throw err;
    }
  }

  // Placeholder for View Auth. Form
  async viewAuthForm(mpdId: string): Promise<{ formUrl: string }> {
    try {
      // TODO: Implement the actual API call once the backend route is available
      const { data } = await this.axios.get<{ formUrl: string }>("/Payment/ViewAuthForm", {
        params: { mpdId },
      });
      return data;
    } catch (err: any) {
      toast.error("Failed to view auth form.");
      throw err;
    }
  }

  async sendAuthViaEcom(
    appRecId: number,
    colRecId: number,
    phone: string
  ): Promise<{ success: boolean; message: string }> {
    try {
      const { data } = await this.axios.post<{ success: boolean; message: string }>(
        "/Payment/SendAuthText",
        {
          appRecId,
          colRecId,
          phone,
        }
      );
      return data;
    } catch (err: any) {
      toast.error("Failed to send authentication via Text.");
      throw err;
    }
  }

  async updateMpdPending(req: SaveMpdReq) {
    try {
      const res = await this.axios.post<ApiResponse<boolean>>("/Payment/UpdateMpdPending", req);
      return res.data.data;
    } catch (err) {
      toast.error(`Error updating Mpd table for recId ${req.recId}`);
      throw err;
    }
  }

  async deactivateRecurringPayment(req: DeactivateMpdReq) {
    try {
      const res = await this.axios.post<ApiResponse<boolean>>("/Payment/DeactivateRecurringPayment", req);
      return res.data.data;
    } catch (err) {
      toast.error(`Error deactivating recurring payment for recId ${req.recId}`);
      throw err;
    }
  }

  async recurringAuthSend(req: SendAuthReq) {
    try {
      const res = await this.axios.post<ApiResponse<boolean>>("/Payment/RecurringAuthSend", req);
      return res.data.data;
    } catch (err) {
      toast.error(`Error sending authorization text to ${req.recurringAuthCell}`);
      throw err;
    }
  }

  async uploadAuthDoc(data: AuthDocumentUpload) {
    const { file, ...rest } = data;
    try {
      const uuid = crypto.randomUUID();
      const fileType = "." + file.name.split(".").pop();
      const fileNameCloud = `${uuid}${fileType}`;
      const fileNameLocal = file.name;
      const fileSize = file.size;
      const fileMime = file.type;

      const fileUrl = await systemService.uploadBlob(file, data.orgId, fileNameCloud);

      const res = await this.axios.post("/Payment/AuthDocumentUpload", {
        ...rest,
        fileNameLocal,
        fileNameCloud,
        fileSize,
        fileMime,
        fileUrl,
        fileType,
      });
      toast.success("Document uploaded");
      return res;
    } catch (err) {
      console.error(err);
      toast.error("Unable to upload document");
      throw err;
    }
  }
  async viewAuthDoc(orgId: number, blobName: string) {
    try {
      const fileUrl = await systemService.getBlobReadUrl(orgId, blobName);
      window.open(fileUrl);
    } catch (e) {
      toast.error("Unable to print auth document");
      console.error(e);
      throw e;
    }
  }
}

/**
 * @todo move to interface file - potential cyclical import
 * @note these fields are not returned from request `GET /Payment/Data?colRecId` but exist on this interface:
 * 'repayCfeeModelTest' | 'repayCfeeModelProd' | 'cobuyerDob' | 'GpiCustStatmentEnabled'
 * @deprecated replace with `PaymentDataRes` in file `src/interfaces/payment.ts` file
 */
export interface GetPaymentData {
  compId: number;
  orgId: number;
  locId: number;
  colType: string;
  accountNum: string;
  dpa: boolean | null;
  pmtDue: number;
  paAmt: number;
  lcDue: number;
  nsfDue: number;
  miscDue: number;
  cpiDueNow: number;
  ddDueNow: number;
  ddNextAmt: number;
  ddNextDue: Date | string | null;
  defDownBal: number;
  payOff: number;
  maxPayment: number;
  allowPartialPaymentWhenPastDue: boolean | null;
  dateSold: Date | string | null;
  intRate: number;
  snBalance: number;
  prinBal: number;
  accrued: number;
  snDueNow: number;
  cpiStatus: string;
  nextDueAmount: number;
  nextDueDate: Date | string | null;
  nextPaymentDue?: any;
  // buyerNoCall: boolean, // @note this field is sent from backend
  // buyerNoText: boolean, // @note this field is sent from backend
  // vehRecId: number, // @note this field is sent from backend
  dmsNextDueAmount: number;
  dmsNextDueDate: Date | string | null;
  appRecId: number;
  colRecId: number;
  lastPmtAmt: number;
  lastPmtPaid: Date | string | null;
  stockNum: string;
  minCreditCardAmount: number;
  convenienceFee: number;
  minAchAmount: number;
  achConvenienceFee: number;
  nowLocal: Date | string | null;
  daysLate: number | null;
  dmsDaysLate: number | null;
  /** @deprecated */
  repayCfeeModelTest: RepayFeeModel;
  /** @deprecated */
  repayCfeeModelProd: RepayFeeModel; // @note this can be an empty string on backend
  repayConvFeeTest: number;
  repayConvFeeProd: number;
  repayCanWaiveFeeTest: boolean;
  repayCanWaiveFeeProd: boolean;
  buyerRecId: number; // @note this is nullible on backend
  buyerFirstName: string;
  buyerLastName: string;
  buyerAddr: string;
  buyerCity: string;
  buyerState: string;
  buyerZip: string;
  buyerHPhone: string;
  buyerCPhone: string;
  buyerWPhone: string;
  buyerEmployer: string;
  buyerEmail: string;
  buyerNoEmail: boolean;
  buyerDob: string; // @note this is nullible on backend
  buyerSsn: string;
  coBuyerRecId: number | null;
  cobuyerFirstName: string;
  cobuyerLastName: string;
  cobuyerAddr: string;
  cobuyerCity: string;
  cobuyerState: string;
  cobuyerZip: string;
  cobuyerHPhone: string;
  cobuyerCPhone: string;
  cobuyerWPhone: string;
  cobuyerEmployer: string;
  cobuyerEmail: string;
  cobuyerNoEmail: boolean;
  /** @deprecated */
  cobuyerDob: string;
  cobuyerSsn: string;
  /** @deprecated */
  GpiCustStatmentEnabled: boolean;
  gpiCustStatmentEnabled: boolean;
  hasCobuyer: boolean | null;
  year: string;
  make: string;
  model: string;
  vin: string;
  color: string;
  trim: string;
  vehSize: string;
  amountFinanced: number | null;
  tOfPBal: number | null;
  originalBal: number | null;
  insCName: string;
  insPolicyNum: string;
  insExpire: Date | string | null;
  insCanceled: boolean | null;
  insCanDate: Date | string | null;
  insCoverage: string;
  insCompDed: number | null;
  insFireDed: number | null;
  onCpi: boolean | null;
  cpiRate: number | null;
  cpiSchedule: PaymentInterval | null;
  cpiTotalPaid: number | null;
  cpiFirstDueDate: string | null;
  cpiHold: number | null;
  cpiAvailable: number | null;
  openedgeApiKey: string;
  repayTestMode: boolean;
  openEdgeDmsAchConvFee: number;
  openEdgeDmsCcConvFee: number;
}

/** @deprecated use `PostPaymentReq` in the payment-interface file */
export interface PostPaymentPayload {
  // CustomerId: string;
  // MpdRecId: boolean; TODO: Get more info
  // PoNum: string; TODO: Need more info
  // TODO: Need more info
  // TransactionResult: null;
  // UxOpID: null;
  //TODO: More info on this
  AcctNum: string;
  AchAcctType: number | null;
  AchConvFee: number;
  AppRecId: number;
  BillAddress: string;
  BillCity: string;
  BillEmail: string;
  BillFirstName: string;
  BillLastName: string;
  BillState: string;
  BillZip: string;
  CarPmt: number;
  CcConvFee: number;
  Change: number;
  ColRecId?: number;
  ColType: string;
  CompId: number;
  ConvFee: number;
  CpiPaid: number;
  DdPmt: number;
  EmailB: string;
  EmailBOLD: string;
  EmailC: string;
  EmailCOLD: string;
  IsAch: boolean;
  IsMyCarPay: boolean;
  IsNewCard: boolean;
  IsRecurring: boolean;
  LcOwed?: number;
  LcPaid: number;
  LcWaived: number;
  LocId: number;
  MCat: string;
  MiscPaid: number;
  Mpd: { Token: string | null; AccountNumber?: string; RoutingNumber?: string };
  NsfPaid: number;
  OrgId: number;
  PaidBy: string;
  PaidIn: string;
  PaidRef: string;
  PaymentType: string;
  PayNote: string;
  PayToFrom: string;
  PmtContext: PmtContext;
  PmtType: string;
  ProcessorTestMode: boolean;
  ProcessorType: number | null;
  SaveCard: boolean;
  SendB: boolean;
  SendC: boolean;
  SnPmt: number;
  Source: string;
  StockNum: string;
  TakenBy: string;
  TakenByPassword: string; // password of user who took the payment
  TotalReceived: number;
  UsePrintServer: boolean;
  UserEmail: string;
  UserRecId: number; // user who took the payment
  UserShortName: string;
  WaiveAchConvFee: boolean;
  WaiveCCConvFee: boolean;
  WaiveConvFee: boolean;
  RequestIdentifier?: string | null;
  RequestSource?: string | null;
}

/** @deprecated Validate/create default with zod. Scope to relevant domain. */
export const getDefaultPostPaymentPayload = (
  inPersonPayment: GetPaymentData
): PostPaymentPayload => ({
  SendB: false,
  SendC: false,
  EmailB: inPersonPayment.buyerEmail || "",
  EmailC: inPersonPayment.cobuyerEmail || "",
  EmailBOLD: "",
  EmailCOLD: "",
  PmtType: "",
  WaiveCCConvFee: false,
  WaiveAchConvFee: false,
  WaiveConvFee: false,
  CcConvFee: inPersonPayment.convenienceFee,
  AchConvFee: inPersonPayment.achConvenienceFee,
  ConvFee: 0,
  OrgId: inPersonPayment.orgId,
  LocId: inPersonPayment.locId,
  CompId: inPersonPayment.compId,
  ColType: inPersonPayment.colType,
  CarPmt: 0,
  PayNote: "",
  PaidBy: "",
  PaidRef: "",
  Change: 0,
  TotalReceived: 0,
  PaidIn: "",
  PayToFrom: `${inPersonPayment.buyerFirstName} ${inPersonPayment.buyerLastName}`,
  UsePrintServer: false,
  TakenBy: "",
  ColRecId: 0,
  AppRecId: inPersonPayment.appRecId,
  StockNum: inPersonPayment.stockNum,
  AcctNum: inPersonPayment.accountNum,
  LcPaid: 0,
  LcWaived: 0,
  LcOwed: 0,
  NsfPaid: 0,
  MiscPaid: 0,
  CpiPaid: 0,
  DdPmt: 0,
  SnPmt: 0,
  AchAcctType: 0,
  PaymentType: "",
  IsAch: false,
  IsNewCard: false,
  Mpd: { Token: null },
  BillFirstName: inPersonPayment.buyerFirstName,
  BillLastName: inPersonPayment.buyerLastName,
  BillEmail: inPersonPayment.buyerEmail,
  BillAddress: inPersonPayment.buyerAddr,
  BillCity: inPersonPayment.buyerCity,
  BillState: inPersonPayment.buyerState,
  BillZip: inPersonPayment.buyerZip,
  ProcessorType: null,
  SaveCard: false,
  Source: "",
  IsRecurring: false,
  PmtContext: "",
  ProcessorTestMode: false,
  UserRecId: 0,
  UserEmail: "",
  UserShortName: "",
  MCat: "",
  IsMyCarPay: false,
  TakenByPassword: "",
});

/** @deprecated Validate/create default with zod. Scope to relevant domain. */
export const createMiscPaymentPayload = (): PostPaymentPayload => ({
  AcctNum: "",
  AchAcctType: 0,
  AchConvFee: 0,
  AppRecId: 0,
  BillAddress: "",
  BillCity: "",
  BillEmail: "",
  BillFirstName: "",
  BillLastName: "",
  BillState: "",
  BillZip: "",
  CarPmt: 0,
  CcConvFee: 0,
  Change: 0,
  ColRecId: 0,
  ColType: "MI",
  CompId: 0,
  ConvFee: 0,
  CpiPaid: 0,
  DdPmt: 0,
  EmailB: "",
  EmailBOLD: "",
  EmailC: "",
  EmailCOLD: "",
  IsAch: false,
  IsMyCarPay: false,
  IsNewCard: false,
  IsRecurring: false,
  LcOwed: 0,
  LcPaid: 0,
  LcWaived: 0,
  LocId: 0,
  MCat: "",
  MiscPaid: 0,
  Mpd: { Token: null },
  NsfPaid: 0,
  OrgId: 0,
  PaidBy: "",
  PaidIn: "",
  PaidRef: "",
  PayNote: "",
  PayToFrom: "",
  PaymentType: "",
  PmtContext: "",
  PmtType: "",
  ProcessorTestMode: false,
  ProcessorType: null,
  SaveCard: false,
  SendB: false,
  SendC: false,
  SnPmt: 0,
  Source: "",
  StockNum: "",
  TakenBy: "",
  TakenByPassword: "",
  TotalReceived: 0,
  UsePrintServer: false,
  UserEmail: "",
  UserRecId: 0,
  UserShortName: "",
  WaiveAchConvFee: false,
  WaiveCCConvFee: false,
  WaiveConvFee: false,
});

/** @todo move to static method for class - this fxn is specific to one domain
 * - Used for creating `defaultValues` for `useForm`
 * @deprecated use `useValidatedForm`
 */
export const newPaymentForm = (
  paymentData?: GetPaymentData | null,
  isPrincipalOnly: boolean = false
): PostPaymentPayload => {
  // This assumes a regular payment
  // So things like CpiPaid are defaulted to the amount they have due
  // In the case of e.g. principal only payment, you will need to reset those
  return {
    AcctNum: paymentData?.accountNum ?? "",
    AchAcctType: 0,
    AchConvFee: paymentData?.achConvenienceFee ?? 0,
    AppRecId: paymentData?.appRecId ?? 0,
    BillAddress: paymentData?.buyerAddr ?? "",
    BillCity: paymentData?.buyerCity ?? "",
    BillEmail: paymentData?.buyerEmail ?? "",
    BillFirstName: paymentData?.buyerFirstName ?? "",
    BillLastName: paymentData?.buyerLastName ?? "",
    BillState: paymentData?.buyerState ?? "",
    BillZip: paymentData?.buyerZip ?? "",
    CarPmt: 0,
    CcConvFee: paymentData?.convenienceFee ?? 0,
    Change: 0,
    ColRecId: paymentData?.colRecId ?? 0,
    ColType: paymentData?.colType ?? "",
    CompId: paymentData?.compId ?? 0,
    ConvFee: 0,
    CpiPaid: isPrincipalOnly ? 0 : paymentData?.cpiDueNow || 0,
    DdPmt: isPrincipalOnly ? 0 : paymentData?.ddDueNow || 0,
    EmailB: paymentData?.buyerEmail || "",
    EmailBOLD: "",
    EmailC: paymentData?.cobuyerEmail || "",
    EmailCOLD: "",
    IsAch: false,
    IsMyCarPay: false,
    IsNewCard: false,
    IsRecurring: false,
    LcOwed: isPrincipalOnly ? undefined : 0,
    LcPaid: isPrincipalOnly ? 0 : paymentData?.lcDue ?? 0,
    LcWaived: 0,
    LocId: paymentData?.locId ?? 0,
    MCat: "",
    MiscPaid: 0,
    Mpd: { Token: null },
    NsfPaid: isPrincipalOnly ? 0 : paymentData?.nsfDue ?? 0,
    OrgId: paymentData?.orgId ?? 0,
    PaidBy: PaymentMethod.enum.Cash,
    PaidIn: PmtPaidIn.enum["In-Person"],
    PaidRef: "",
    PayNote: "",
    PayToFrom: `${paymentData?.buyerFirstName} ${paymentData?.buyerLastName}`,
    PaymentType: isPrincipalOnly ? "PrinOnly" : "",
    PmtContext: isPrincipalOnly ? "NEW_UI_MISC" : "NEW_UI",
    PmtType: "",
    ProcessorTestMode: false,
    ProcessorType: null,
    SaveCard: false,
    SendB: !!paymentData?.buyerEmail && !paymentData?.buyerNoEmail,
    SendC: !!paymentData?.cobuyerEmail && !paymentData?.cobuyerNoEmail,
    SnPmt: 0,
    Source: "",
    StockNum: paymentData?.stockNum ?? "",
    TakenBy: "",
    TakenByPassword: "",
    TotalReceived: isPrincipalOnly ? 0 : paymentData?.nextDueAmount ?? 0,
    UsePrintServer: false,
    UserEmail: "",
    UserRecId: 0,
    UserShortName: "",
    WaiveAchConvFee: false,
    WaiveCCConvFee: false,
    WaiveConvFee: false,
  };
};

/** @deprecated use `EmployeeField` in `@/interfaces/System` */
export interface Employee {
  recId: number;
  shortName: string;
  userId: string;
}

export interface RepoCompany {
  recId: number;
  company: string;
}

export interface SendAuthResponse {
  success: boolean;
  message: string;
}

export interface PrintAuthFormResponse {
  pdfUrl: string;
}

export interface UploadAuthFormResponse {
  success: boolean;
  message: string;
}

export interface ViewAuthFormResponse {
  formUrl: string;
}

export interface InactivateResponse {
  success: boolean;
  message: string;
}
export interface SubmitResponse {
  success: boolean;
  message: string;
}

export class PrintAuthForm {
  constructor(public mpdId?: number, public formId?: number) {}
}

/** @deprecated use class/interface `IPaymentProviderConfigRes`/`PaymentProviderConfig` in `@/interfaces/payments.ts` */
export type PaymentProviders = IPaymentProviderConfigRes;

export const paymentService = new PaymentService();
