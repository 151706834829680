import { Route, Routes } from "react-router-dom";
import LienHolderTable from "./LienHolderTable";
import LienHolderDetail from "./LienHolderDetail";
import LienholdersProvider from "./LienHolderProvider";

const LienHolderRouter = () => {
  return (
    <LienholdersProvider>
      <Routes>
        <Route index element={<LienHolderTable />} />
        <Route path=":id" element={<LienHolderDetail />} />
      </Routes>
    </LienholdersProvider>
  );
}

export default LienHolderRouter;