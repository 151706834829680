import { useParams } from "react-router-dom";
import inventoryPageStyles from "../InventoryPageStyles.module.scss";
import { PageHeader } from "@/components/pageHeader/PageHeader";
import { useEffect, useState } from "react";
import { inventoryService } from "@/services/inventoryService";
import ScannedDocuments from "./ScannedDocuments";
import Photos from "./Photos";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
//style
import { BG_HIGHLIGHT } from "@/mui/theme/colors";

export const InventoryDocuments = () => {
  const { invRecId } = useParams();
  const [saleStatus, setSaleStatus] = useState<string>("");
  const [stockNum, setStockNum] = useState<string>("");

  const loadSaleStatus = async () => {
    if (!invRecId) {
      return;
    }

    try {
      const response = await inventoryService.getVehicleDetails(invRecId);
      setSaleStatus(response?.saleStatus ?? "");
      setStockNum(response?.stockNum ?? "");
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadSaleStatus();
  }, [invRecId]);

  if (!invRecId) {
    return <></>;
  }

  return (
    <Grid
      container
      direction="column"
      gap={2}
      sx={{ height: "80vh", width: "100%", overflow: "hidden", padding: "20px" }}
    >
      {/* Header Section */}
      <Grid overflow={"hidden"}>
        <PageHeader headerLabel="Vehicle Options" />
      </Grid>
      <Grid
        sx={{
          flex: 1, // Takes the remaining space
          borderRadius: 2.5,
          backgroundColor: BG_HIGHLIGHT,
          overflow: "scroll", // Prevent content overflow
        }}
      >
        <Grid className={inventoryPageStyles.body}>
          <Grid className={inventoryPageStyles.bodyContainer}>
            <Grid className={inventoryPageStyles.bodyChildMultipleSections}>
              <ScannedDocuments />
              <Photos />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
