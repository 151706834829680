import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// mui
import Grid from "@mui/material/Unstable_Grid2";
// kendo
import { Button } from "@/components/button/Button";
// state
import { useAuthCtx } from "@/AppProviders/AuthProvider";
import { useAuthSelector } from "../auth/authSlice";
// utils
import { authService } from "@/services/authService";
// style
import logo from "../../assets/dcsHorizontalLogo.png";

/** ###  */
const Login: FC = () => {
  const navigate = useNavigate();
  /** @deprecated */
  const isLoggedInDeprec = useAuthSelector((s) => s.isLoggedInDeprec);
  const isLoggedIn = useAuthCtx((s) => s.isLoggedIn || isLoggedInDeprec);
  const loadingBffUser = useAuthCtx((s) => s.loadingBffUser);
  const isLoginLoading = useAuthCtx((s) => s.isLoginLoading);
  const [loadingLogin, setLoadingLogin] = useState(false);

  useEffect(() => {
    if (!loadingBffUser && isLoggedIn) navigate("/", { relative: "path", replace: true });
    else {
      if(!isLoginLoading) {        
        const buttonElement = document.querySelector("button#loginButton");
        if (buttonElement && !isLoggedIn && !loadingLogin) {
          setLoadingLogin(true);
          (buttonElement as HTMLButtonElement).click();
        }
      }
    }
  }, [loadingBffUser, isLoggedIn]);

  return (
    <Grid
      container
      direction="column"
      flex={1}
      justifyContent="center"
      alignItems="center"
      minHeight={500}
      textAlign="center"
      wrap="nowrap"
      sx={{ backgroundColor: "#fff" }}
    >
      <img src={logo} style={{ width: "229px", height: "auto" }} />

      <Grid component="h1" my={2.5} fontSize={32} fontWeight={700}>
        SOLUTIONS SOFTWARE
      </Grid>

      <Grid component="h2" fontSize={24} fontWeight={700} mt={2}>
        Dealer Management System
      </Grid>

      <Button
        id="loginButton"
        onClick={authService.login}
        label="Log In"
        style={{ marginTop: "30px", width: "200px" }}
        loading={loadingBffUser || loadingLogin}
      />
    </Grid>
  );
};

export default Login;
