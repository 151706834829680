import { FC, useState } from "react";
import { useParams } from "react-router";
import { Button } from "@mui/material";
import { scienapticService } from "@/services/scienapticService";
import { getRouteParamNum } from "@/utils/routing/formatting";
import { useAiUnderwritingCtx } from "../AiUnderwritingProvider";
import { toast } from "react-toastify";
import ConfirmationModal from "@/mui/components/ConfirmationModal";

const ClearAllEvaluationsButton: FC = () => {
  const appRecId = getRouteParamNum(useParams().id);

  const reportsHistory = useAiUnderwritingCtx((s) => s.reportsHistory);
  const existingEvals = reportsHistory.value; 
  const hasEvals = !!existingEvals?.length;

  if (!hasEvals) return null;

  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleConfirmClear = async () => {
    if (!appRecId) return;

    try {
      await scienapticService.deleteAppEvaluations(appRecId);
      await reportsHistory.load();
      toast.success("All AutoLend IQ evaluations cleared.");
    } catch (error) {
      console.error(error);
      toast.error("Failed to clear evaluations.");
    }
  };

  return (
    <>
      <Button
        variant="text"
        color="primary"
        onClick={() => setConfirmOpen(true)}
        style={{ textDecoration: "underline" }}
      >
        Clear All
      </Button>

      <ConfirmationModal
        isOpen={confirmOpen}
        setIsOpen={setConfirmOpen}
        title="Clear Evaluations"
        message="Clear all AutoLend IQ results for this application?"
        onConfirm={handleConfirmClear}
      />      
    </>
  );
};

export default ClearAllEvaluationsButton;
