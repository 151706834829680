////////////////////////////////////////////////////////
// accountsSubviews/tableColumns/recurring.ts
////////////////////////////////////////////////////////

import { routeBase } from '../..';
import { KendoColumnDef, newColumnDef } from '@/utils/tableLayout/utils';
import AmtDueCell from '../tableCellComponents/AmtDueCell';
import RecAmountCell from '../tableCellComponents/RecAmount';


export interface RecurringDb {
  // From collections (col)
  recId: number;                 // col.RecId
  stockNum: string;              // col.StockNum
  code3: string;                 // col.Code3 => "Lot"
  amtDue: number | null;         // col.AmtDue
  accountNum: string;

  // From mpddata (mp)
  last4: string | null;          // mp.Last4
  buyers: string | null;         // mp.BelongsTo (aliased as Buyers)
  recurringStatus: string | null;  // mp.recurring_status
  recurringStartDate: string | Date | null;  // mp.StartDate
  recurringSchedule: string | null;          // mp.Schedule
  recurringNextRun: string | Date | null;    // mp.CCRNextDue
  recurringAmount: number | null;            // mp.Amt
  recurringLastResult: string | null;        // mp.CCRResult
}


const buildDetailRoute = (row: RecurringDb) => `/${routeBase}/${row.recId}`;


export const recurringColumns: KendoColumnDef<keyof RecurringDb>[] = [
  newColumnDef('accountNum', 'Account#', 110, 'link', true, true, buildDetailRoute),
  newColumnDef('last4', 'CC Last 4', 80, 'text', true, true),
  newColumnDef('buyers', 'Belongs To', 130, 'text', true, true),
  newColumnDef('recurringStatus', 'Recurring Status', 140, 'text', true, true),
  newColumnDef('recurringStartDate', 'Start Date', 120, 'date', true, true),
  newColumnDef('recurringSchedule', 'Schedule', 100, 'text', true, true),
  newColumnDef('recurringNextRun', 'Next Run', 120, 'date', true, true),
  newColumnDef('recurringAmount', 'Amount', 120, RecAmountCell, true, true),
  newColumnDef('recurringLastResult', 'Last Result', 140, 'text', true, true),
  newColumnDef('code3', 'Lot', 'auto', 'text', true, true),
];
