import { FC, ReactNode } from 'react';
// mui
import Grid, { Grid2Props } from '@mui/material/Unstable_Grid2';
import { BG_HIGHLIGHT } from '../../theme/colors';
// components
import NestedDetailHeaderLayout from './NestedDetailHeaderLayout';

/** ###  */
const NestedDetailLayout: FC<
  Grid2Props & {
    /** Header can be the title string or a custom header component of type `NestedDetailHeaderLayout` */
    header?: string | ReactNode;
  }
> = ({ header, children, sx, ...gridProps }) => {
  const headerComponent =
    typeof header === 'string' ? <NestedDetailHeaderLayout headerTitle={header} /> : header;

  return (
    // @todo Change padding to p={2}
    <Grid container direction="column" px={4.5} py={2} gap={2} overflow="hidden" width="100%" height="80vh" wrap='nowrap' flex={1} sx={{ ...sx }} {...gridProps}>
      {header && headerComponent}

      <Grid py={2} px={4} borderRadius={2.5} flex={1} overflow="scroll" sx={{ backgroundColor: BG_HIGHLIGHT }}>
        {children}
      </Grid>
    </Grid>
  );
};

export default NestedDetailLayout;
