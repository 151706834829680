import { FC } from "react";
import { Controller } from "react-hook-form";
import { Tooltip } from "react-tooltip";
// kendo
import { Loader } from "@progress/kendo-react-all";
import { SocialSecurityInput } from "@/components/inputs/socialSecurityInput/SocialSecurityInput";
import { Modal } from "@/components/modals/Modal";
import { Button } from "@/components/button/Button";
import DateInput from "@/mui/components/form/MuiKendoDateField";
import { DropdownInput } from "@/components/inputs/dropdown/DropdownInput";
import { Spacer } from "@/components/spacer/Spacer";
import { TextInput } from "@/components/inputs/text/TextInput";
import { ZipInput } from "@/components/inputs/zipInput/ZipInput";
import { BasicTable } from "@/components/table/Table";
import { SectionHeader } from "@components/sectionHeader/SectionHeader";
import CheckboxGroup from "@/components/inputs/checkboxGroup/CheckboxGroup";
import AddressAutocomplete from "@/components/inputs/addressAutocomplete/AddressAutocomplete";
// components
import { MultipleInputRow } from "../../multipleInputRow/MultipleInputRow";
// state
import { useCreditReports } from "./useCreditReports";
// utils
import {
  ssnRegex,
  genericRequired,
  optionalNotGreaterThanTodayValidation,
} from "@/utils/helpers/formValidation";
import { UsaStateCode } from "@/general/regions";
// interfaces
import { BuyerType } from "@/enums/general";
import { CreditReportType, JointBuyerType } from "@/features/Sales/enums";
// style
import salesTabStyles from "../SalesTab.module.scss";
import applicationTabStyle from "../../../detailView/tabs/ApplicationTab/subviews/ApplicationTabs.module.scss";

/** @deprecated needs refactor */
const CreditReports: FC = () => {
  const {
    accountForm,
    creditBureauOptions,
    creditInquiryAuthPrinting,
    creditReportForm,
    creditReportTypeOptions,
    creditReportsColumns,
    creditReportSettings,
    creditReportSettingsLoading,
    previousCreditReportsLoading,
    onAccountFormSubmit,
    previousCreditReports,
    printCreditInquiryAuth,
    resetAccountForm,
    runningReport,
    runReport,
    setViewReportIframeUrl,
    updatingAccount,
    viewReportIframeUrl,
    resettingData,
    fullBuyerAddress,
    fullCobuyerAddress,
    handleBuyerPlaceChange,
    handleCobuyerPlaceChange,
    isDisabled,
  } = useCreditReports();

  // @todo this is not the way to handle errors.
  let pageError;

  if (!creditReportSettings) {
    pageError = "Unable to load credit report settings";
  } else if (!creditReportSettings.sevenHcEnabled) {
    pageError = "Credit reports are not enabled";
  }

  const reportType = creditReportForm.watch("reportType");
  const reportFor = creditReportForm.watch("reportFor");
  const isCredit = reportType === CreditReportType.Credit;
  const reportForOptions = isCredit ? Object.values(JointBuyerType) : Object.values(BuyerType);

  return (
    <div className={salesTabStyles.container}>
      <header className={salesTabStyles.headerContainer}>
        <div className={salesTabStyles.row} style={{ gap: "24px" }}>
          <h2 className={salesTabStyles.header}>Credit & Pre-Screen Reports</h2>
        </div>
      </header>
      <main className={salesTabStyles.body} style={{ overflowY: "scroll" }}>
        {creditReportSettingsLoading ? (
          <Loader />
        ) : pageError ? (
          pageError
        ) : (
          <>
            <section className={salesTabStyles.section}>
              <SectionHeader title="Credit Reports on File" />
              <BasicTable
                data={previousCreditReports}
                columns={creditReportsColumns}
                noResultsComponent={
                  previousCreditReportsLoading ? (
                    <Loader size="large" />
                  ) : (
                    <div>No records found</div>
                  )
                }
              />
            </section>
            <section className={salesTabStyles.section}>
              <SectionHeader title="New Report" />

              <form
                onSubmit={creditReportForm.handleSubmit(runReport)}
                className={salesTabStyles.bodyContainer}
              >
                <div className={salesTabStyles.bodyChild}>
                  <Controller
                    name="reportType"
                    control={creditReportForm.control}
                    rules={genericRequired}
                    render={({ field }) => (
                      <DropdownInput
                        required
                        label="Report Type"
                        data={creditReportTypeOptions}
                        disabled={isDisabled}
                        errors={
                          creditReportForm.formState?.errors?.reportType?.message ||
                          !!creditReportForm.formState?.errors?.reportType
                        }
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="reportFor"
                    control={creditReportForm.control}
                    rules={genericRequired}
                    render={({ field }) => (
                      <DropdownInput
                        required
                        label={`${reportType} For`}
                        data={reportForOptions}
                        disabled={isDisabled}
                        errors={
                          creditReportForm.formState?.errors?.reportFor?.message ||
                          !!creditReportForm.formState?.errors?.reportFor
                        }
                        {...field}
                      />
                    )}
                  />
                  {isCredit && (
                    <Controller
                      name="bureaus"
                      control={creditReportForm.control}
                      rules={{
                        validate: (e) => (e.length === 0 ? "Field is required" : undefined),
                      }}
                      render={({ field }) => (
                        <CheckboxGroup
                          control={creditReportForm.control}
                          required
                          horizontalOptions
                          label="Credit Bureau"
                          options={creditBureauOptions}
                          errors={
                            creditReportForm.formState?.errors?.bureaus?.message ||
                            !!creditReportForm.formState?.errors?.bureaus
                          }
                          disabled={isDisabled}
                          {...field}
                        />
                      )}
                    />
                  )}
                </div>
                <div className={salesTabStyles.bodyChild}>
                  {reportType && (
                    <div className={salesTabStyles.creditButtonContainer}>
                      <div className="runReportButton">
                        <Button
                          label={`Run ${reportType}`}
                          loading={runningReport}
                          disabled={accountForm.formState.isDirty || isDisabled}
                          fullWidth={false}
                        />
                      </div>
                      {accountForm.formState.isDirty && (
                        <Tooltip anchorSelect=".runReportButton" place="top" style={{ zIndex: 5 }}>
                          The buyer information form has pending updates. Please submit or reset the
                          updates before running the report
                        </Tooltip>
                      )}
                    </div>
                  )}
                  {isCredit && (
                    <div className={salesTabStyles.creditButtonContainer}>
                      <Button
                        secondary
                        onClick={printCreditInquiryAuth}
                        loading={creditInquiryAuthPrinting}
                        label="Print Credit Inquiry Authorization"
                        fullWidth={false}
                      />
                    </div>
                  )}
                </div>
              </form>
              <Spacer size={30} />
              <div className={applicationTabStyle.sectionBar} style={{ width: "100%" }} />
              <Spacer size={30} />
              <form onSubmit={accountForm.handleSubmit(onAccountFormSubmit)}>
                {["Joint", "Buyer"].includes(reportFor) && (
                  <>
                    <h3 className={salesTabStyles.subHeading}>Buyer Information</h3>
                    <Spacer size={11} />
                    <div className={salesTabStyles.bodyContainer}>
                      <div className={salesTabStyles.bodyChild}>
                        <Controller
                          name="buyerFirstName"
                          control={accountForm.control}
                          rules={genericRequired}
                          render={({ field }) => (
                            <TextInput
                              required
                              label="First Name"
                              errors={accountForm.formState.errors.buyerFirstName?.message}
                              readOnly={isDisabled}
                              {...field}
                            />
                          )}
                        />
                        <Controller
                          name="buyerMiddleName"
                          control={accountForm.control}
                          render={({ field }) => (
                            <TextInput label="Middle Name" readOnly={isDisabled} {...field} />
                          )}
                        />
                        <Controller
                          name="buyerLastName"
                          control={accountForm.control}
                          rules={genericRequired}
                          render={({ field }) => (
                            <TextInput
                              required
                              label="Last Name"
                              errors={accountForm.formState.errors.buyerLastName?.message}
                              readOnly={isDisabled}
                              {...field}
                            />
                          )}
                        />
                        <Controller
                          name="buyerSsn"
                          control={accountForm.control}
                          rules={{
                            validate: (v?: string) =>
                              !ssnRegex.test(v ?? "") ? "Please enter a valid SSN" : undefined,
                          }}
                          render={({ field }) => (
                            <SocialSecurityInput
                              required
                              label="SSN"
                              errors={accountForm.formState.errors.buyerSsn?.message}
                              readOnly={isDisabled}
                              {...field}
                            />
                          )}
                        />
                      </div>
                      <div className={salesTabStyles.bodyChild}>
                        <Controller
                          name="buyerAddress"
                          control={accountForm.control}
                          rules={genericRequired}
                          render={({ field }) => (
                            <AddressAutocomplete
                              required
                              label="Address"
                              errors={accountForm.formState.errors.buyerAddress?.message}
                              {...field}
                              onPlaceSelected={handleBuyerPlaceChange}
                              readOnly={isDisabled}
                              fullAddress={fullBuyerAddress}
                            />
                          )}
                        />
                        <Controller
                          name="buyerCity"
                          control={accountForm.control}
                          rules={genericRequired}
                          render={({ field }) => (
                            <TextInput
                              required
                              label="City"
                              errors={accountForm.formState.errors.buyerCity?.message}
                              readOnly={isDisabled}
                              {...field}
                            />
                          )}
                        />
                        <MultipleInputRow label="State/Zip *" htmlFor="stateZip">
                          <Controller
                            name="buyerState"
                            control={accountForm.control}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <DropdownInput
                                required
                                suggest
                                id="stateZip"
                                data={UsaStateCode.options}
                                errors={!!accountForm.formState.errors.buyerState}
                                disabled={isDisabled}
                                {...field}
                              />
                            )}
                          />
                          <Controller
                            name="buyerZip"
                            control={accountForm.control}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <ZipInput
                                id="stateZip"
                                required
                                errors={!!accountForm.formState.errors.buyerZip}
                                readOnly={isDisabled}
                                {...field}
                              />
                            )}
                          />
                        </MultipleInputRow>
                        <Controller
                          name="buyerSecurityPin"
                          control={accountForm.control}
                          render={({ field }) => (
                            <TextInput label="Security Pin" readOnly={isDisabled} {...field} />
                          )}
                        />
                      </div>
                    </div>
                    <Spacer size={34} />
                    {isCredit && (
                      <div className={salesTabStyles.bodyContainer}>
                        <div className={salesTabStyles.bodyChild}>
                          <Controller
                            name="buyerSignatureVerifiedBy"
                            control={accountForm.control}
                            render={({ field }) => (
                              <TextInput readOnly label="Signature Verified By" {...field} />
                            )}
                          />
                          <Controller
                            name="buyerCreditAuthSigned"
                            control={accountForm.control}
                            rules={optionalNotGreaterThanTodayValidation}
                            render={({ field }) => (
                              <DateInput
                                required
                                label="Credit Authorization Date"
                                errors={
                                  accountForm.formState.errors?.buyerCreditAuthSigned?.message
                                }
                                readOnly={isDisabled}
                                {...field}
                              />
                            )}
                          />
                        </div>
                        <div className={salesTabStyles.bodyChild}></div>
                      </div>
                    )}
                  </>
                )}
                {["Joint", "Co-Buyer"].includes(reportFor) && (
                  <div>
                    {reportFor === "Joint" && <Spacer size={34} />}
                    <h3 className={salesTabStyles.subHeading}>Co-Buyer Information</h3>
                    <Spacer size={11} />
                    <div className={salesTabStyles.bodyContainer}>
                      <div className={salesTabStyles.bodyChild}>
                        <Controller
                          name="cobuyerFirstName"
                          control={accountForm.control}
                          rules={{ required: "Field is required" }}
                          render={({ field }) => (
                            <TextInput
                              required
                              label="First Name"
                              errors={accountForm.formState.errors.cobuyerFirstName?.message}
                              readOnly={isDisabled}
                              {...field}
                            />
                          )}
                        />
                        <Controller
                          name="cobuyerMiddleName"
                          control={accountForm.control}
                          render={({ field }) => (
                            <TextInput label="Middle Name" readOnly={isDisabled} {...field} />
                          )}
                        />
                        <Controller
                          name="cobuyerLastName"
                          control={accountForm.control}
                          rules={{ required: "Field is required" }}
                          render={({ field }) => (
                            <TextInput
                              required
                              label="Last Name"
                              errors={accountForm.formState.errors.cobuyerLastName?.message}
                              readOnly={isDisabled}
                              {...field}
                            />
                          )}
                        />
                        <Controller
                          name="cobuyerSsn"
                          control={accountForm.control}
                          rules={{
                            validate: (v?: string) =>
                              !ssnRegex.test(v ?? "") ? "Please enter a valid SSN" : undefined,
                          }}
                          render={({ field }) => (
                            <SocialSecurityInput
                              required
                              label="SSN"
                              errors={accountForm.formState.errors.cobuyerSsn?.message}
                              readOnly={isDisabled}
                              {...field}
                            />
                          )}
                        />
                      </div>
                      <div className={salesTabStyles.bodyChild}>
                        <Controller
                          name="cobuyerAddress"
                          control={accountForm.control}
                          rules={genericRequired}
                          render={({ field }) => (
                            <AddressAutocomplete
                              required
                              label="Address"
                              errors={accountForm.formState.errors.cobuyerAddress?.message}
                              {...field}
                              onPlaceSelected={handleCobuyerPlaceChange}
                              readOnly={isDisabled}
                              fullAddress={fullCobuyerAddress}
                            />
                          )}
                        />
                        <Controller
                          name="cobuyerCity"
                          control={accountForm.control}
                          rules={{ required: "Field is required" }}
                          render={({ field }) => (
                            <TextInput
                              required
                              label="City"
                              errors={accountForm.formState.errors.cobuyerCity?.message}
                              readOnly={isDisabled}
                              {...field}
                            />
                          )}
                        />
                        <MultipleInputRow label="State/Zip *" htmlFor="stateZip">
                          <Controller
                            name="cobuyerState"
                            control={accountForm.control}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <DropdownInput
                                id="stateZip"
                                required
                                data={UsaStateCode.options}
                                errors={!!accountForm.formState.errors.cobuyerState}
                                disabled={isDisabled}
                                {...field}
                              />
                            )}
                          />
                          <Controller
                            name="cobuyerZip"
                            control={accountForm.control}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <ZipInput
                                id="stateZip"
                                required
                                errors={!!accountForm.formState.errors.cobuyerZip}
                                readOnly={isDisabled}
                                {...field}
                              />
                            )}
                          />
                        </MultipleInputRow>
                        <Controller
                          name="cobuyerSecurityPin"
                          control={accountForm.control}
                          render={({ field }) => (
                            <TextInput label="Security Pin" readOnly={isDisabled} {...field} />
                          )}
                        />
                      </div>
                    </div>
                    <Spacer size={34} />
                    {isCredit && (
                      <div className={salesTabStyles.bodyContainer}>
                        <div className={salesTabStyles.bodyChild}>
                          <Controller
                            name="cobuyerSignatureVerifiedBy"
                            control={accountForm.control}
                            render={({ field }) => (
                              <TextInput readOnly label="Signature Verified By" {...field} />
                            )}
                          />
                          <Controller
                            name="cobuyerCreditAuthSigned"
                            control={accountForm.control}
                            rules={optionalNotGreaterThanTodayValidation}
                            render={({ field }) => (
                              <DateInput
                                required
                                label="Credit Authorization Date"
                                errors={
                                  accountForm.formState.errors?.cobuyerCreditAuthSigned?.message
                                }
                                readOnly={isDisabled}
                                {...field}
                              />
                            )}
                          />
                        </div>
                        <div className={salesTabStyles.bodyChild}></div>
                      </div>
                    )}
                  </div>
                )}
                <div className={salesTabStyles.creditButtonContainer}>
                  <Button
                    label="Submit"
                    loading={updatingAccount}
                    disabled={!accountForm.formState.isDirty}
                    fullWidth={false}
                  />
                </div>
              </form>
              <Spacer size={10} />
              <div className={salesTabStyles.creditButtonContainer}>
                <Button
                  label="Reset"
                  themeColor="error"
                  secondary
                  loading={resettingData}
                  disabled={!accountForm.formState.isDirty}
                  onClick={() => resetAccountForm(true)}
                />
              </div>
            </section>
          </>
        )}
      </main>
      {viewReportIframeUrl && (
        <Modal
          isOpen={true}
          centerModal
          closeButton
          onCloseButtonClick={() => setViewReportIframeUrl("")}
          onDimmerClick={() => setViewReportIframeUrl("")}
          panelChildrenStyle={{ width: "1200px", height: "100vh", overflow: "hidden" }}
        >
          <iframe
            src={viewReportIframeUrl}
            style={{ width: "100%", height: "100%", border: "none" }}
          />
        </Modal>
      )}
    </div>
  );
};

export default CreditReports;
