import { routeBase } from '../..';
import AmtDueCell from '../tableCellComponents/AmtDueCell';
import { KendoColumnDef, newColumnDef } from '../../../../utils/tableLayout/utils';

export interface DeferredDownDb {
  recId: number;
  appRecId: number;
  accountNum: string;
  buyers: string;
  vehicle: string;
  dateSold: string | Date;
  amtDue: number;
  code3: string;
}

const buildDetailRoute = (row: DeferredDownDb) => `/${routeBase}/${row.recId}`;

export const deferredDownColumns: KendoColumnDef<keyof DeferredDownDb>[] = [
  newColumnDef('accountNum', 'Account', 110, 'link', true, true, buildDetailRoute),
  newColumnDef('buyers', 'Buyer(s)', 'auto', 'text', true, true),
  newColumnDef('vehicle', 'Vehicle', 'auto', 'text', true, true),
  newColumnDef('dateSold', 'Date Sold', 120, 'date', true, true),
  newColumnDef('amtDue', 'Amount Due', 140, AmtDueCell),
  newColumnDef('code3', 'Lot', 80, 'text', true, true),
];
