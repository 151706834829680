import { FC } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
// mui
import Grid from "@mui/material/Unstable_Grid2";
// kendo
import FormLoader from "../layout/FormLoader";
// components
import SaleManagementForm from "./saleManagementForm/SaleManagementForm";
import DeclineNotice from "./DeclineNoticeView";
import DownPaymentView from "./DownPaymentView";
// state
import { useSalesSelector } from "@/features/Sales/salesSlice";
import SaleMgmtProvider from "./SaleMgmtProvider";
// interfaces
import { AppStatus } from "@/features/Sales/enums";

/** @deprecated move to router */
const SaleMgmtDeprec: FC = () => {
  const saleData = useSalesSelector((s) => s.saleData);
  if (saleData?.sale?.appstatus === AppStatus.Declined) return <DeclineNotice />;
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        overflow: "hidden", // Prevent scrolling at this level
      }}
    >
      <SaleManagementForm />
    </div>
  );
};

/** @todo add saleData?.sale?.appstatus === AppStatus.Declined navigation */
const SaleMgmtTabLayout: FC = () => (
  <Grid container direction="column" px={4.5} py={2} gap={2} sx={{
    flex: 1,
    height: "100%", // Ensure it fills the available space
    overflow: "hidden", // Prevent scrolling at this level
  }}>
    <Outlet />
  </Grid>
);

/** ### // https://localhost:5173/#/sales/postedSales/761843/ */
const SaleManagement: FC = () => {
  const saleDataLoading = useSalesSelector((s) => s.saleDataLoading);

  if (saleDataLoading) return <FormLoader />;

  return (
    <SaleMgmtProvider>
      <Routes>
        <Route element={<SaleMgmtTabLayout />}>
          <Route path="" element={<SaleMgmtDeprec />} />
          {/* <Route path="" element={<SaleManagementForm />} /> */}

          {/* @todo replace `/down-payment` route with table component:
            <Route path="/down-payment" element={<DownPaymentTable />} /> 
          */}
          <Route path="/down-payment" element={<DownPaymentView />} />
          {/* @todo add `/down-payment/form` route with form component:
            <Route path="/down-payment/form" element={<DownPaymentForm />} />
          */}

          {/* @todo set up routing */}
          <Route path="/decline-notice" element={<DeclineNotice />} />
        </Route>
        {/* Fallback */}
        <Route path="*" element={<Navigate to="" relative="path" replace={true} />} />
        <Route path="/" element={<Navigate to="" relative="path" replace={true} />} />
      </Routes>
    </SaleMgmtProvider>
  );
};

export default SaleManagement;
