import { FC } from "react";
import BuyersGuideProvider, { useBuyersGuideCtx } from "./BuyersGuideProvider";
import BuyersGuideForm from "./BuyersGuideForm";
import { PageHeader } from "@/components/pageHeader/PageHeader";
import inventoryPageStyles from "../InventoryPageStyles.module.scss";
import { Loader } from "@/components";
// mui
import Grid from "@mui/material/Unstable_Grid2";
//style
import { BG_HIGHLIGHT } from "@/mui/theme/colors";

const BuyersGuide: FC = () => {
  const isLoading = useBuyersGuideCtx((s) => s.isLoading);
  const setIsLoading = useBuyersGuideCtx((s) => s.setIsLoading);

  return (
    <>
      <Grid
        container
        direction="column"
        gap={2}
        sx={{ height: "80vh", width: "100%", overflow: "hidden", padding: "20px" }}
      >
        {/* Header Section */}
        <Grid overflow={"hidden"}>
          <PageHeader headerLabel="Buyer's Guide" />
        </Grid>
        <Grid
          sx={{
            flex: 1, // Takes the remaining space
            borderRadius: 2.5,
            backgroundColor: BG_HIGHLIGHT,
            overflow: "scroll", // Prevent content overflow
          }}
        >
          <section>
            {isLoading ? (
              <Loader size="large" />
            ) : (
              <div className={inventoryPageStyles.bodyContainer}>
                <div className={inventoryPageStyles.bodyChild}>
                  <BuyersGuideForm />
                </div>
              </div>
            )}
          </section>
        </Grid>
      </Grid>
    </>
  );
};

export default () => (
  <BuyersGuideProvider>
    <BuyersGuide />
  </BuyersGuideProvider>
);
