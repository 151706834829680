import { CSSProperties, FC } from 'react';
// kendo
import { GridCellProps } from '@progress/kendo-react-all';
// interfaces
import { AccountDb } from '../interfaces';

const colorMap: { [key: number]: string | undefined } = { 2: 'red', 1: 'green', 0: undefined };
const circleStyle: CSSProperties = { width: '20px', borderRadius: '50%' };

/** ###  */
const GPSCell: FC<Omit<GridCellProps, 'dataItem'> & { dataItem: AccountDb }> = ({ dataItem }) => {
  const { gpsCode, saleType } = dataItem;
  const noGPSOnSidenote = saleType === "Sidenote" ? 0 : gpsCode!
  const backgroundColor = colorMap[noGPSOnSidenote];

  return (
    <td className="k-table-td">
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ ...circleStyle, backgroundColor, width: '20px', height: '20px' }}></div>
      </div>
    </td>
  );
};

export default GPSCell;
