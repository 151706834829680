import { FC } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2";
// components
import SubmitButton from "./SubmitButton";
import TitleGroup from "./TitleGroup";
import ClearAllEvaluationsButton from "./ClearButton";
import FeatureFlag from "@/env/FeatureFlag";
import useEmailFeatureFlag from "@/env/useEmailFeatureFlag";

/** ### Header layout for "AutoLend IQ" tab-view */
const Header: FC = () => {
  const emailFeatureFlag = useEmailFeatureFlag()

  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center">
      <Grid container direction="row" alignItems="center" gap={2} wrap="nowrap">
        <TitleGroup />
        <FeatureFlag maxAllowedEnv="production" conditions={[emailFeatureFlag]}>
          <ClearAllEvaluationsButton />
        </FeatureFlag>
      </Grid>

      <SubmitButton />
    </Grid>
  );
};

export default Header;
