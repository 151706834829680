import React, { forwardRef } from 'react';
import {
  CheckboxHandle,
  Checkbox as KendoCheckbox,
  CheckboxProps as KendoCheckboxProps,
} from '@progress/kendo-react-inputs';
import styles from './Checkbox.module.scss';

export interface CheckboxProps extends KendoCheckboxProps {
  label?: string;
  boldLabel?: boolean;
  labelStyles?: React.CSSProperties;
  labelPlacement?: 'before' | 'after';
  alignWithTextInputs?: boolean;
  containerStyles?: React.CSSProperties;
  checkboxInputWraperStyle?: React.CSSProperties;
}

export const CheckboxInputNew = forwardRef<CheckboxHandle, CheckboxProps>((props, ref) => {
  const {
    hidden,
    // @ts-ignore
    label,
    boldLabel = true,
    labelStyles = {},
    containerStyles = {},
    labelPlacement,
    alignWithTextInputs,
    checkboxInputWraperStyle = {},
    ...rest
  } = props;

  const checkboxContents = (
    <div className={styles.container} style={containerStyles} hidden={hidden}>
      {labelPlacement === 'before' && (
        <div
          className={styles.label}
          style={{ ...labelStyles, fontWeight: boldLabel ? 700 : labelStyles.fontWeight }}
        >
          {label}
        </div>
      )}
      <div style={checkboxInputWraperStyle}>
        <KendoCheckbox {...rest} rounded={null} ref={ref} />
      </div>
      {(labelPlacement === 'after' || !labelPlacement) && (
        <div
          className={styles.label}
          style={{ ...labelStyles, fontWeight: boldLabel ? 700 : labelStyles.fontWeight }}
        >
          {label}
        </div>
      )}
    </div>
  );

  return alignWithTextInputs ? (
    <div className={styles.row} hidden={hidden}>
      <div className={styles.textInputAlign} />
      <div className={styles.inputContainer}>{checkboxContents}</div>
    </div>
  ) : (
    checkboxContents
  );
});
