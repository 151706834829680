// mui
import { MuiColumnDef } from '@/mui/components/dataTable/interfaces';
import ButtonLinkCell from '@/mui/components/dataTable/cells/ButtonLinkCell';
// utils
import { Lienholders } from '@/interfaces/Lienholders';
import { Typography } from '@mui/material';
// interfaces

export const tableColumns: MuiColumnDef<Lienholders>[] = [
  {
    field: 'name',
    headerName: 'Company Name',
    flex: 1,
    minWidth: 120,
    valueFormatter: (p) => (p.value?.length > 0 ? p.value : '-') ?? '-',
  },
  {
    field: 'contact',
    headerName: 'Contact',
    flex: 1,
    minWidth: 70,
    valueFormatter: (p) => (p.value?.length > 0 ? p.value : '-') ?? '-',
  },
  {
    field: 'phone',
    headerName: 'Phone',
    minWidth: 150,
    valueFormatter: (p) => (p.value?.length > 0 ? p.value : '-') ?? '-',
  },
  {
    field: 'active',
    headerName: 'Status',
    minWidth: 150,
    renderCell: (params) => {
      const isActive = params.value; // Assuming `active` is a boolean
      return (
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "400px",
            fontStyle: "bold",
            color: isActive ? '#3C763D' : '#A94442', // Green for active, red for inactive
          }}
        >
          {isActive ? 'Active' : 'Inactive'}
        </Typography>
      );
    },
  },
  {
    field: 'recId',
    headerName: 'Actions',
    renderCell: ButtonLinkCell,
    align: 'center',
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
  },
];
