import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
// kendo
import { Button } from '@/components/button/Button';
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import DateInput from "@/mui/components/form/MuiKendoDateField";
import { DropdownInput } from '@/components/inputs/dropdown/DropdownInput';
import { Icons } from '@/components/icons';
import { Loader } from '@/components/loader/Loader';
import { NumberInput } from '@/components/inputs/numberInput/NumberInput';
import { Spacer } from '@/components/spacer/Spacer';
// components
import UserInfo from '../../../UserInfo';
// state
import { useFinance } from './useFinance';
import { useDealsQuotesDetailCtx } from '@/features/Sales/detailView/DealsQuotesDetail/DealsQuotesDetailProvider';
// utils
import { salesService } from '@/services/salesService';
import { formatCurrency, scheduleData } from '@/utils/helpers/general';
// interfaces
import { IFinanceQuote } from './interfaces';
import { DealData } from '@/interfaces';
import { SalesSubview } from '@/features/Sales/enums';
// style
import styles from './FinanceTab.module.scss';
import tabStyles from '../../../../salesTabs/SalesTab.module.scss';

const FinanceTab: FC = () => {
  const {
    control,
    customField1Name,
    customField2Name,
    customField3Name,
    customField4Name,
    customField5Name,
    customField6Name,
    showFullFinanceOptions,
    setShowFullFinanceOptions,
    watch,
    addSpecialPaymentPlan,
    submitSpecialPaymentPlan,
    specialPaymentPlanList,
    setSpecialPaymentPlanList,
    onFieldBlur,
    handleReset,
    reset,
    handleSubmit,
    isDirty,
    errors,
    dealData,
    setDealData,
    setIsLoading,
    isLoading,
    isQuoteDataLoading,
    isQuoteInfoLoading,
  } = useFinance();
  const buttonWidth = '102px';
  const printTargetRef = useDealsQuotesDetailCtx((s) => s.printTargetRef);
  const navigate = useNavigate();

  const onSaveButton = async (currentForm: IFinanceQuote) => {
    const updateData: DealData = {
      ...dealData, // Spread existing dealData to include all existing values
      recId: dealData?.recId,
      quoteDate: currentForm.dateOfQuote,
      customerName: currentForm.fullName,
      customerPhone: currentForm.phoneNumber,

      //Finance properties
      salesPrice: currentForm.salesPrice,
      salesTax: currentForm.salesTax,
      license: currentForm.license,
      title: currentForm.title,
      registration: currentForm.registration,
      serviceContract: currentForm.serviceContract,
      credityInsurance: currentForm.creditInsurance,
      creditDisability: currentForm.creditDisability,
      totalSalesPrice: currentForm.totalSalePrice,
      tradeAllowance: currentForm.tradeAllowance,
      tradePayoff: currentForm.tradePayoff,
      depositDown: currentForm.depositDown,
      cashOnDelivery: currentForm.cashOnDelivery,
      totalDown: currentForm.totalDown,
      interestRate: currentForm.interestRate,
      amountFinanced: currentForm.amountFinanced,
      schedule: currentForm.schedule,
      firstPaymentDue: currentForm.dateStarted,
      term: currentForm.term,
      paymentAmount: currentForm.amount,
      numberOfPayments: currentForm.numberOfPayments,
      finalPayment: currentForm.final,
      finalPaymentDue: currentForm.finalDueOn,
      financeCharge: currentForm.financeCharge,
      totalPayments: currentForm.totalPayments,
      customField1Label: customField1Name,
      customField1: currentForm.customField1,
      customField2Label: customField2Name,
      customField2: currentForm.customField2,
      customField3Label: customField3Name,
      customField3: currentForm.customField3,
      customField4Label: customField4Name,
      customField4: currentForm.customField4,
      customField5Label: customField5Name,
      customField5: currentForm.customField5,
      customField6Label: customField6Name,
      customField6: currentForm.customField6,
      specialPaymentNum1:
        specialPaymentPlanList.length >= 1 ? specialPaymentPlanList[0]?.numberOfPayments : null,
      specialPaymentNum2:
        specialPaymentPlanList.length >= 2 ? specialPaymentPlanList[1]?.numberOfPayments : null,
      specialPaymentNum3:
        specialPaymentPlanList.length >= 3 ? specialPaymentPlanList[2]?.numberOfPayments : null,
      specialPaymentNum4:
        specialPaymentPlanList.length >= 4 ? specialPaymentPlanList[3]?.numberOfPayments : null,
      specialPaymentAmount1:
        specialPaymentPlanList.length >= 1 ? specialPaymentPlanList[0]?.amount : null,
      specialPaymentAmount2:
        specialPaymentPlanList.length >= 2 ? specialPaymentPlanList[1]?.amount : null,
      specialPaymentAmount3:
        specialPaymentPlanList.length >= 3 ? specialPaymentPlanList[2]?.amount : null,
      specialPaymentAmount4:
        specialPaymentPlanList.length >= 4 ? specialPaymentPlanList[3]?.amount : null,
      specialPaymentBeginningDate1:
        specialPaymentPlanList.length >= 1
          ? dayjs(specialPaymentPlanList[0]?.dateStarted).format('YYYY-MM-DD')
          : null,
      specialPaymentBeginningDate2:
        specialPaymentPlanList.length >= 2
          ? dayjs(specialPaymentPlanList[1]?.dateStarted).format('YYYY-MM-DD')
          : null,
      specialPaymentBeginningDate3:
        specialPaymentPlanList.length >= 3
          ? dayjs(specialPaymentPlanList[2]?.dateStarted).format('YYYY-MM-DD')
          : null,
      specialPaymentBeginningDate4:
        specialPaymentPlanList.length >= 4
          ? dayjs(specialPaymentPlanList[3]?.dateStarted).format('YYYY-MM-DD')
          : null,
      specialPaymentSchedule1:
        specialPaymentPlanList.length >= 1 ? specialPaymentPlanList[0]?.schedule : null,
      specialPaymentSchedule2:
        specialPaymentPlanList.length >= 2 ? specialPaymentPlanList[1]?.schedule : null,
      specialPaymentSchedule3:
        specialPaymentPlanList.length >= 3 ? specialPaymentPlanList[2]?.schedule : null,
      specialPaymentSchedule4:
        specialPaymentPlanList.length >= 4 ? specialPaymentPlanList[3]?.schedule : null,

      //Lease Properties
      sellingPrice: dealData?.sellingPrice,
      mmr: dealData?.mmr,
      moneyDueAtInception: dealData?.moneyDueAtInception,
      moneyDueAtInceptionField1: dealData?.moneyDueAtInceptionField1,
      moneyDueAtInceptionField2: dealData?.moneyDueAtInceptionField2,
      moneyDueAtInceptionField3: dealData?.moneyDueAtInceptionField3,
      securityDeposit: dealData?.securityDeposit,
      downPayment: dealData?.downPayment,
      
      taxRate: dealData?.taxRate,
      apr: dealData?.apr,
      leaseDownPayment: dealData?.leaseDownPayment ?? null,

      term1: dealData?.term1,
      term1EstPayment: dealData?.term1EstPayment,
      term1ResidualRate: dealData?.term1ResidualRate,
      term1Residual: dealData?.term1Residual,
      term1GuaranteedTradeOption: dealData?.term1GuaranteedTradeOption,
      term1EstimatedNetPayoff: dealData?.term1EstimatedNetPayoff,
      term2: dealData?.term2,
      term2EstPayment: dealData?.term2EstPayment,
      term2ResidualRate: dealData?.term2ResidualRate,
      term2Residual: dealData?.term2Residual,
      term2GuaranteedTradeOption: dealData?.term2GuaranteedTradeOption,
      term2EstimatedNetPayoff: dealData?.term2EstimatedNetPayoff,
      term3: dealData?.term3,
      term3EstPayment: dealData?.term3EstPayment,
      term3ResidualRate: dealData?.term3ResidualRate,
      term3Residual: dealData?.term3Residual,
      term3GuaranteedTradeOption: dealData?.term3GuaranteedTradeOption,
      term3EstimatedNetPayoff: dealData?.term3EstimatedNetPayoff,
      tradeOption: dealData?.tradeOption,
      customField11Label: dealData?.customField11Label,
      customField11: dealData?.customField11,
      customField12Label: dealData?.customField12Label,
      customField12: dealData?.customField12,
      customField13Label: dealData?.customField13Label,
      customField13: dealData?.customField13,
      customField14Label: dealData?.customField14Label,
      customField14: dealData?.customField14,
      customField15Label: dealData?.customField15Label,
      customField15: dealData?.customField15,
      daysToFirstPayment: dealData?.daysToFirstPayment,
      afterNumberOfMonths: dealData?.afterNumberOfMonths,
      estimatedApr: dealData?.estimatedApr,
      leaseConversionTerm: dealData?.leaseConversionTerm,
      reFinancePayment: dealData?.reFinancePayment,
      leaseTerm: dealData?.leaseTerm,
      leaseResidualRate: dealData?.leaseResidualRate,
      leaseResidual: dealData?.leaseResidual,
      leaseAddedCapCost: dealData?.leaseAddedCapCost,
      leasePayment: dealData?.leasePayment,
    };

    setIsLoading(true);
    await salesService
      .postDealQuotes(updateData)
      .then((res) => {
        setDealData(res.data);

        if (dealData?.recId != res.data.recId) {
          const editRoute = `/sales/${SalesSubview.enum.dealsQuotes}/${res.data.recId}/finance`;
          navigate(editRoute);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {isQuoteDataLoading || isQuoteInfoLoading ? (
        <div className={tabStyles.body}>
          <div className={tabStyles.loading}>
            <Loader size="large" />
          </div>
        </div>
      ) : (
        <div className={tabStyles.bodyPrintTarget} ref={printTargetRef}>
          <form onSubmit={handleSubmit(onSaveButton)}>
            <UserInfo control={control} errors={errors} />

            <Spacer size={20} />
            <div className={tabStyles.body}>
              <div className={tabStyles.bodyContainer}>
                <div className={tabStyles.bodyChild}>
                  <Controller
                    name="salesPrice"
                    control={control}
                    render={({ field }) => (
                      <CurrencyInput
                        label="Sales Price"
                        rightAlignInput
                        {...field}
                        onBlur={() => onFieldBlur()}
                      />
                    )}
                  />
                  {showFullFinanceOptions && (
                    <>
                      <Controller
                        name="salesTax"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput
                            label="Sales Tax"
                            readOnly
                            rightAlignInput
                            {...field}
                            onBlur={() => onFieldBlur()}
                          />
                        )}
                      />
                      <Controller
                        name="license"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput
                            label="License"
                            rightAlignInput
                            {...field}
                            onBlur={() => onFieldBlur()}
                          />
                        )}
                      />
                      <Controller
                        name="title"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput
                            label="Title"
                            rightAlignInput
                            {...field}
                            onBlur={() => onFieldBlur()}
                          />
                        )}
                      />
                      <Controller
                        name="registration"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput
                            label="Registration"
                            rightAlignInput
                            {...field}
                            onBlur={() => onFieldBlur()}
                          />
                        )}
                      />
                      <Controller
                        name="customField1"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput
                            label={customField1Name}
                            rightAlignInput
                            {...field}
                            onBlur={() => onFieldBlur()}
                          />
                        )}
                      />
                      <Controller
                        name="customField2"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput
                            label={customField2Name}
                            rightAlignInput
                            {...field}
                            onBlur={() => onFieldBlur()}
                          />
                        )}
                      />
                      <Controller
                        name="customField3"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput
                            label={customField3Name}
                            rightAlignInput
                            {...field}
                            onBlur={() => onFieldBlur()}
                          />
                        )}
                      />
                      <Controller
                        name="customField4"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput
                            label={customField4Name}
                            rightAlignInput
                            {...field}
                            onBlur={() => onFieldBlur()}
                          />
                        )}
                      />
                      <Controller
                        name="customField5"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput
                            rightAlignInput
                            label={customField5Name}
                            {...field}
                            onBlur={() => onFieldBlur()}
                          />
                        )}
                      />
                      <Controller
                        name="customField6"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput
                            rightAlignInput
                            label={customField6Name}
                            {...field}
                            onBlur={() => onFieldBlur()}
                          />
                        )}
                      />
                      <Controller
                        name="serviceContract"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput
                            rightAlignInput
                            label="Service Contract"
                            {...field}
                            onBlur={() => onFieldBlur()}
                          />
                        )}
                      />
                      <Controller
                        name="creditInsurance"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput
                            rightAlignInput
                            label="Credit Insurance"
                            readOnly
                            {...field}
                            onBlur={() => onFieldBlur()}
                          />
                        )}
                      />
                      <Controller
                        name="creditDisability"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput
                            rightAlignInput
                            label="Credit Disability"
                            readOnly
                            {...field}
                            onBlur={() => onFieldBlur()}
                          />
                        )}
                      />
                    </>
                  )}
                  <Controller
                    name="totalSalePrice"
                    control={control}
                    render={({ field }) => (
                      <CurrencyInput
                        rightAlignInput
                        label="Total Sale Price"
                        readOnly
                        {...field}
                        onBlur={() => onFieldBlur()}
                      />
                    )}
                  />
                  <small className={tabStyles.disclaimer} style={{ alignSelf: 'flex-end' }}>
                    * Includes sales tax and other fees
                  </small>
                </div>
                <div className={tabStyles.bodyChild}>
                  {showFullFinanceOptions && (
                    <>
                      <Controller
                        name="tradeAllowance"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput
                            rightAlignInput
                            label="Trade Allowance"
                            {...field}
                            onBlur={() => onFieldBlur()}
                          />
                        )}
                      />
                      <Controller
                        name="tradePayoff"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput
                            rightAlignInput
                            label="Trade Payoff"
                            {...field}
                            onBlur={() => onFieldBlur()}
                          />
                        )}
                      />
                      <Controller
                        name="depositDown"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput
                            rightAlignInput
                            label="Deposit Down"
                            {...field}
                            onBlur={() => onFieldBlur()}
                          />
                        )}
                      />
                      <Controller
                        name="cashOnDelivery"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput
                            rightAlignInput
                            label="Cash On Delivery"
                            {...field}
                            onBlur={() => onFieldBlur()}
                          />
                        )}
                      />
                      <Controller
                        name="totalDown"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput
                            rightAlignInput
                            label="Total Down"
                            readOnly
                            {...field}
                            onBlur={() => onFieldBlur()}
                          />
                        )}
                      />
                    </>
                  )}
                  <Controller
                    name="interestRate"
                    control={control}
                    render={({ field }) => (
                      <NumberInput
                        rightAlignInput
                        label="Interest Rate"
                        max={1}
                        min={0}
                        format="p2"
                        {...field}
                        onBlur={() => onFieldBlur()}
                      />
                    )}
                  />
                  <Controller
                    name="amountFinanced"
                    control={control}
                    render={({ field }) => (
                      <CurrencyInput
                        rightAlignInput
                        label="Amount Financed"
                        readOnly
                        inputStyles={{ textAlign: 'right' }}
                        {...field}
                        onBlur={() => onFieldBlur()}
                      />
                    )}
                  />
                  <Controller
                    name="schedule"
                    control={control}
                    render={({ field: { onChange, ...restProps } }) => (
                      <DropdownInput
                        label="Schedule"
                        data={scheduleData}
                        {...restProps}
                        onChange={(e) => {
                          onChange(e.value);
                          onFieldBlur(e.value);
                        }}
                      />
                    )}
                  />
                  <Controller
                    name="dateStarted"
                    control={control}
                    render={({ field }) => (
                      <DateInput label="Date Started" {...field} onBlur={() => onFieldBlur()} />
                    )}
                  />
                  <Controller
                    name="term"
                    control={control}
                    render={({ field }) => (
                      <NumberInput
                        rightAlignInput
                        label="Term (months)"
                        {...field}
                        onBlur={() => onFieldBlur(undefined, undefined, true)}
                      />
                    )}
                  />
                  <Controller
                    name="amount"
                    control={control}
                    render={({ field }) => (
                      <CurrencyInput
                        rightAlignInput
                        label="Amount"
                        {...field}
                        onBlur={() => onFieldBlur(undefined, undefined, false, true)}
                      />
                    )}
                  />
                  <Controller
                    name="numberOfPayments"
                    control={control}
                    render={({ field }) => (
                      <NumberInput
                        rightAlignInput
                        label="# of Payments"
                        {...field}
                        onBlur={() => onFieldBlur()}
                      />
                    )}
                  />
                  <Controller
                    name="finalDueOn"
                    control={control}
                    render={({ field }) => (
                      <DateInput
                        label="Final Due On"
                        readOnly
                        {...field}
                        onBlur={() => onFieldBlur()}
                      />
                    )}
                  />
                  <Controller
                    name="financeCharge"
                    control={control}
                    render={({ field }) => (
                      <CurrencyInput
                        rightAlignInput
                        label="Finance Charge"
                        readOnly
                        {...field}
                        onBlur={() => onFieldBlur()}
                      />
                    )}
                  />
                  <Controller
                    name="totalPayments"
                    control={control}
                    render={({ field }) => (
                      <CurrencyInput
                        rightAlignInput
                        label="Total of Payments"
                        readOnly
                        {...field}
                        onBlur={() => onFieldBlur()}
                      />
                    )}
                  />
                  <a
                    className={tabStyles.anchorText}
                    onClick={() => setShowFullFinanceOptions((prev) => !prev)}
                  >
                    {showFullFinanceOptions ? 'Hide' : 'Show'} Full Finance Quote Options
                  </a>
                </div>
              </div>
              <div className={styles.footer}>
                <Button
                  label="Reset"
                  fillMode="outline"
                  buttonStyle={{ width: buttonWidth }}
                  onClick={handleReset}
                />
                <div className={styles.rightButtonGroup}>
                  <Button
                    label="Recalculate"
                    fillMode="outline"
                    buttonStyle={{ width: buttonWidth }}
                    onClick={() => onFieldBlur()}
                    type="button"
                  />
                </div>
              </div>

              <Spacer size={30} />
              <div className={styles.bottomRow}>
                <Button
                  label="Submit"
                  secondary={!isDirty}
                  disabled={!isDirty}
                  type="submit"
                  fullWidth={false}
                  buttonStyle={{ width: buttonWidth }}
                  loading={isLoading}
                />
                <Spacer horizontal={true} size={10} />
                <Button
                  label="Cancel"
                  secondary={true}
                  disabled={!isDirty}
                  onClick={() => reset()}
                  fullWidth={false}
                  buttonStyle={{ width: buttonWidth }}
                />
              </div>
            </div>
          </form>

          <Spacer size={20} />
          <div className={tabStyles.body}>
            <div className={tabStyles.bodyContainer}>
              <div className={tabStyles.bodyChild} style={{ gap: '23px' }}>
                <h3 className={tabStyles.subHeading}>Payment Schedule</h3>
                <div className={styles.paymentSchedule}>
                  <ul key={'listSpecialPayments'}>
                    <li key={'fixedPayment'}>
                      {watch('numberOfPayments')} Payments of {formatCurrency(watch('amount'))}{' '}
                      {watch('schedule')} Beginning{' '}
                      {dayjs(watch('dateStarted')).format('MMMM D, YYYY')}
                    </li>
                    {specialPaymentPlanList.map((value, index) => (
                      <div className={styles.paymentScheduleItem} key={`specialPayment${index}`}>
                        <li key={index}>
                          {value.numberOfPayments} Payments of {formatCurrency(value.amount)}{' '}
                          {value.schedule} Beginning{' '}
                          {dayjs(value.dateStarted).format('MMMM D, YYYY')}
                        </li>
                        <div
                          className={styles.delete}
                          onClick={() => {
                            let filteredSpecialPaymentPlan = specialPaymentPlanList;
                            setSpecialPaymentPlanList((prevList) => {
                              filteredSpecialPaymentPlan = prevList.filter(
                                (list, filteredIndex) => filteredIndex !== index
                              );
                              onFieldBlur(undefined, filteredSpecialPaymentPlan);
                              return prevList.filter(
                                (list, filteredIndex) => filteredIndex !== index
                              );
                            });
                          }}
                        >
                          <Icons.X />
                        </div>
                      </div>
                    ))}
                  </ul>
                </div>
              </div>
              <form
                className={tabStyles.bodyChild}
                style={{ gap: '23px' }}
                onSubmit={addSpecialPaymentPlan.handleSubmit(submitSpecialPaymentPlan)}
              >
                <h3 className={tabStyles.subHeading}>Add Special Payment Plan +</h3>
                <Controller
                  name="numberOfPayments"
                  control={addSpecialPaymentPlan.control}
                  rules={{ required: 'This field is required' }}
                  render={({ field }) => (
                    <NumberInput
                      required
                      label="Number of Payments"
                      errors={addSpecialPaymentPlan.formState.errors.numberOfPayments?.message}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="paymentAmount"
                  control={addSpecialPaymentPlan.control}
                  rules={{ required: 'This field is required' }}
                  render={({ field }) => (
                    <CurrencyInput
                      required
                      label="Payment Amount"
                      errors={addSpecialPaymentPlan.formState.errors.paymentAmount?.message}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="schedule"
                  control={addSpecialPaymentPlan.control}
                  rules={{ required: 'This field is required' }}
                  render={({ field }) => (
                    <DropdownInput
                      required
                      label="Schedule"
                      data={scheduleData}
                      errors={addSpecialPaymentPlan.formState.errors.schedule?.message}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="beginningDate"
                  control={addSpecialPaymentPlan.control}
                  rules={{ required: 'This field is required' }}
                  render={({ field }) => (
                    <DateInput
                      required
                      label="Beginning Date"
                      errors={addSpecialPaymentPlan.formState.errors.beginningDate?.message}
                      {...field}
                    />
                  )}
                />
                <div className={styles.paymentPlanButton}>
                  <Button label="Add Payment" fillMode="outline" type="submit" />
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FinanceTab;
