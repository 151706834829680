import { ExpensesGrid } from "./expensesGrid/expensesGrid";
import { useParams } from "react-router-dom";
import inventoryPageStyles from "../InventoryPageStyles.module.scss";
import { PageHeader } from "@/components/pageHeader/PageHeader";
import { useCallback, useEffect, useState } from "react";
import { inventoryService } from "@/services/inventoryService";
// mui
import Grid from "@mui/material/Unstable_Grid2";
//style
import { BG_HIGHLIGHT } from "@/mui/theme/colors";

export const InventoryExpenses = () => {
  const { invRecId } = useParams();
  const [saleStatus, setSaleStatus] = useState<string>("");
  const [stockNum, setStockNum] = useState<string>("");

  const loadSaleStatus = useCallback(async () => {
    if (!invRecId) {
      return;
    }

    const response = await inventoryService.getVehicleDetails(invRecId);
    setSaleStatus(response?.saleStatus ?? "");
    setStockNum(response?.stockNum ?? "");
  }, [invRecId]);

  useEffect(() => {
    loadSaleStatus();
  }, [loadSaleStatus]);

  if (!invRecId) {
    return <></>;
  }

  return (
    <Grid container direction="column" gap={2} sx={{ height: "80vh", width: "100%", overflow: "hidden", padding: "20px" }}>
      {/* Header Section */}
      <Grid overflow={"hidden"}>
          <PageHeader headerLabel="Expenses" />
      </Grid>
      <Grid
        sx={{
          flex: 1, // Takes the remaining space
          borderRadius: 2.5,
          backgroundColor: BG_HIGHLIGHT,
          overflow: "scroll", // Prevent content overflow
        }}
      >
        <div className={inventoryPageStyles.body}>
          <div className={inventoryPageStyles.bodyContainer}>
            <div className={inventoryPageStyles.bodyChildMultipleSections}>
              <ExpensesGrid
                recId={invRecId}
                type="Current"
                saleStatus={saleStatus}
                vehRecId={invRecId}
                stockNum={stockNum}
              />
              <ExpensesGrid
                recId={invRecId}
                type="Historical"
                saleStatus={saleStatus}
                vehRecId={invRecId}
                stockNum={stockNum}
              />
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
