import { FC } from "react";
import { Controller } from "react-hook-form";
// components
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Checkbox, TextInput } from "@/components";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
// state
import { useAddOnsSalesTaxCtx } from "../AddOnsSalesTaxProvider";
import { CompanyAddOnsTaxForm } from "@/interfaces/CtxAdd";

// Unified Styles for Labels
const gridLabelStyle = {
  fontSize: "15px",
  fontWeight: "700",
  display: "flex",
  alignItems: "center",
  fontStyle: "italic",
  paddingBottom: "8px",
  minHeight: "38px", // Makes all label rows uniform in height
};

const gridLabelStyleCheck = {
  fontSize: "15px",
  fontWeight: "700",
  display: "flex",
  justifyContent: "center",
  fontStyle: "italic",
  paddingBottom: "8px",
  minHeight: "38px", // Makes all label rows uniform in height
};

const AddOnName: FC = () => (
  <Grid container direction="column" spacing={2}>
    <Grid sx={{ ...gridLabelStyle, visibility: "hidden" }}>Where</Grid>
    {[
      "Add On 1.",
      "Add On 2.",
      "Add On 3.",
      "Add On 4.",
      "Add On 5.",
      "Add On 6.",
      "Add On 7.",
      "Add On 8.",
      "Add On 9.",
      "Add On 10.",
    ].map((label, idx) => (
      <Grid key={idx} sx={{ ...gridLabelStyle, padding: "14.75px 8px 14.75px 8px" }}>
        {label}
      </Grid>
    ))}
  </Grid>
);

const NameSettings: FC = () => {
  const { control } = useAddOnsSalesTaxCtx((s) => s.addOnsSalesTaxForm);

  const formFields: (keyof CompanyAddOnsTaxForm)[] = [
    "addOn1",
    "addOn2",
    "addOn3",
    "addOn4",
    "addOn5",
    "addOn6",
    "addOn7",
    "addOn8",
    "addOn9",
    "addOn10",
  ];

  return (
    <Grid container direction="column" spacing={2}>
      <Grid sx={gridLabelStyle}>Setting</Grid>
      {formFields.map((fieldName) => (
        <Grid key={fieldName}>
          <Controller
            name={fieldName}
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                value={field.value ? field.value.toString() : null} // Ensure the value is a number or null
              />
            )}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const PriceSettings: FC = () => {
  const { control } = useAddOnsSalesTaxCtx((s) => s.addOnsSalesTaxForm);

  const formFields: (keyof CompanyAddOnsTaxForm)[] = [
    "price1",
    "price2",
    "price3",
    "price4",
    "price5",
    "price6",
    "price7",
    "price8",
    "price9",
    "price10",
  ];

  return (
    <Grid container direction="column" spacing={2}>
      <Grid sx={gridLabelStyle}>Price</Grid>
      {formFields.map((fieldName) => (
        <Grid key={fieldName}>
          <Controller
            name={fieldName}
            control={control}
            render={({ field }) => (
              <CurrencyInput
                {...field}
                rightAlignInput
                value={
                  field.value !== null && field.value !== undefined ? Number(field.value) : null
                } // Ensure the value is a number or null
                style={{ textAlign: "right" }}
              />
            )}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const CostSettings: FC = () => {
  const { control } = useAddOnsSalesTaxCtx((s) => s.addOnsSalesTaxForm);

  const formFields: (keyof CompanyAddOnsTaxForm)[] = [
    "cost1",
    "cost2",
    "cost3",
    "cost4",
    "cost5",
    "cost6",
    "cost7",
    "cost8",
    "cost9",
    "cost10",
  ];

  return (
    <Grid container direction="column" spacing={2}>
      <Grid sx={gridLabelStyle}>Cost</Grid>
      {formFields.map((fieldName) => (
        <Grid key={fieldName}>
          <Controller
            name={fieldName}
            control={control}
            render={({ field }) => (
              <CurrencyInput
                {...field}
                rightAlignInput
                value={
                  field.value !== null && field.value !== undefined ? Number(field.value) : null
                }
              />
            )}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const StateCheckboxes: FC = () => {
  const { control } = useAddOnsSalesTaxCtx((s) => s.addOnsSalesTaxForm);

  const formFields: (keyof CompanyAddOnsTaxForm)[] = [
    "stateTax1",
    "stateTax2",
    "stateTax3",
    "stateTax4",
    "stateTax5",
    "stateTax6",
    "stateTax7",
    "stateTax8",
    "stateTax9",
    "stateTax10",
  ];

  return (
    <Grid container direction="column" spacing={2}>
      <Grid sx={gridLabelStyleCheck}>State</Grid>
      {formFields.map((fieldName) => (
        <Grid key={fieldName}>
          <Controller
            name={fieldName}
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                containerStyles={{
                  height: "36px",
                  display: "flex",
                  justifyContent: "center",
                }}
              />
            )}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const CountyCheckboxes: FC = () => {
  const { control } = useAddOnsSalesTaxCtx((s) => s.addOnsSalesTaxForm);

  const formFields: (keyof CompanyAddOnsTaxForm)[] = [
    "countyTax1",
    "countyTax2",
    "countyTax3",
    "countyTax4",
    "countyTax5",
    "countyTax6",
    "countyTax7",
    "countyTax8",
    "countyTax9",
    "countyTax10",
  ];

  return (
    <Grid container direction="column" spacing={2}>
      <Grid sx={gridLabelStyleCheck}>County</Grid>
      {formFields.map((fieldName) => (
        <Grid key={fieldName}>
          <Controller
            name={fieldName}
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                containerStyles={{
                  height: "36px",
                  display: "flex",
                  justifyContent: "center",
                }}
              />
            )}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const CityCheckboxes: FC = () => {
  const { control } = useAddOnsSalesTaxCtx((s) => s.addOnsSalesTaxForm);

  const formFields: (keyof CompanyAddOnsTaxForm)[] = [
    "cityTax1",
    "cityTax2",
    "cityTax3",
    "cityTax4",
    "cityTax5",
    "cityTax6",
    "cityTax7",
    "cityTax8",
    "cityTax9",
    "cityTax10",
  ];

  return (
    <Grid container direction="column" spacing={2}>
      <Grid sx={gridLabelStyleCheck}>City</Grid>
      {formFields.map((fieldName) => (
        <Grid key={fieldName}>
          <Controller
            name={fieldName}
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                containerStyles={{
                  height: "36px",
                  display: "flex",
                  justifyContent: "center",
                }}
              />
            )}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const LocationCheckboxes: FC = () => {
  const { control } = useAddOnsSalesTaxCtx((s) => s.addOnsSalesTaxForm);

  // Defining the form fields as an array
  const formFields: (keyof CompanyAddOnsTaxForm)[] = [
    "locTax1",
    "locTax2",
    "locTax3",
    "locTax4",
    "locTax5",
    "locTax6",
    "locTax7",
    "locTax8",
    "locTax9",
    "locTax10",
  ];

  return (
    <Grid container direction="column" spacing={2}>
      <Grid sx={gridLabelStyleCheck}>Location</Grid>
      {formFields.map((fieldName) => (
        <Grid key={fieldName}>
          <Controller
            name={fieldName}
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                containerStyles={{
                  height: "36px",
                  display: "flex",
                  justifyContent: "center",
                }}
              />
            )}
          />
        </Grid>
      ))}
    </Grid>
  );
};

/** ###  */
const SettingsFields: FC = () => [
  <Grid container direction="row" spacing={2} flex={1}>
    <Grid sx={{ maxWidth: "150px", minWidth: "150px" }} flex={1}>
      <AddOnName key="AddOnNameFields" />
    </Grid>
    <Grid xs={0.75} flex={1}>
      <NameSettings key="nameSettingFields" />
    </Grid>
    <Grid xs={0.75} flex={1}>
      <PriceSettings key="priceSettingFields" />
    </Grid>
    <Grid xs={1.5} flex={1}>
      <CostSettings key="costSettingFields" />
    </Grid>
    <Grid xs={0.5} flex={1}>
      <StateCheckboxes key="stateCheckboxes" />
    </Grid>
    <Grid xs={0.5} flex={1}>
      <CountyCheckboxes key="countyCheckboxes" />
    </Grid>
    <Grid xs={0.5} flex={1}>
      <CityCheckboxes key="cityCheckboxes" />
    </Grid>
    <Grid xs={0.5} flex={1}>
      <LocationCheckboxes key="locationCheckboxes" />
    </Grid>
  </Grid>,
];

export default SettingsFields;
