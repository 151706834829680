import { FC, useCallback } from "react";
import { useMatch, useParams } from "react-router-dom";
import { getRouteParamNum } from "@/utils/routing/formatting";
import DetailViewLayout from "@/mui/components/layout/DetailViewLayout";
import DetailViewHeader from "@/mui/components/layout/DetailViewLayout/DetailViewHeader";
import DetailViewBody from "@/mui/components/layout/DetailViewLayout/DetailViewBody";
import NavTabs from "./layout/Header/NavTabs";
import LeftContainer from "./layout/LeftContainer";
import RightContainer from "./layout/RightContainer";
import QuickActionsModal from "./components/quickActionsModal/QuickActionsModal";
import { useAcctDetailsCtx } from "./AcctDetailProvider";
import { customerService } from "@/services/customerService";

const AccountDetailLayout: FC = () => {
  const isAcctInfoLoading = useAcctDetailsCtx((s) => s.acctInfoReq.isLoading);
  const collectionsViewMatch = useMatch({ path: `/collections/:queueRecId/run/:colRecId/*` })?.pathname;
  const appliedNavPath = collectionsViewMatch ? false : "/accounts";

  const colRecId = getRouteParamNum(useParams().colRecId);



  return (
    <DetailViewLayout backLink={appliedNavPath}>
      <DetailViewHeader title="Account Overview">
        <NavTabs hasTabs />
      </DetailViewHeader>

      <DetailViewBody direction="row" gap={2.5} p="10px" isLoading={isAcctInfoLoading}>
        <LeftContainer />
        <RightContainer />
      </DetailViewBody>

    </DetailViewLayout>
  );
};

export default AccountDetailLayout;
