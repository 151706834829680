import { toast } from "react-toastify";
import dayjs from "dayjs";

// utils
import { AxiosService } from "./axiosService";
import { DateFormat, displayErrors, formatDate, handleRequestError } from "@/utils/helpers/general";
// interfaces
import {
  AccountContact,
  AccountFullInformation,
  AccountInformation,
  ActivityLog,
  CallLog,
  ChargeOffInformation,
  ChargeOffPayload,
  Employer,
  GetContactData,
  GetEmployersData,
  GetGpsHistoryPayload,
  GetReferencesData,
  GpsHistoryItemDeprec,
  GpsInformation,
  NextPayment,
  OdometerTrackingData,
  IAccountPaymentHistoryRequest,
  IAccountPaymentHistoryRes,
  ReactivateChargeOffPayload,
  Reference,
  ReleasePossessionPayload,
  UpdateBuyerContact,
  WarrantyInformation,
  WarrantyLog,
  WarrantyRequestPayload,
  SidenoteDetail,
  SidenoteAddOns,
  MyCarPayConfig,
} from "@/interfaces/Accounts";
import { ApiResponse, ApiReturnResponse, GridData } from "@/interfaces/Api";
import { GetCustomersResponse } from "@/interfaces/Customer";
import { DocumentsNoticesItem } from "@/interfaces/Documents";
import { SamTask } from "@/interfaces/sam";
import {
  AccountsGetPgReq,
  AccountsGetPgRes,
} from "@/features/Accounts/accountsSubviews/interfaces";
import {
  LeaseAmendmentGetRes,
  LeaseAmendmentPostReq,
} from "@/features/Accounts/accountsSubviews/AccountDetail/components/leaseAmendment/interfaces";
import {
  TakePossessionInfo,
  TakePossessionReq,
} from "@/features/Accounts/accountsSubviews/AccountDetail/components/takePossession/interfaces";
import { UpdateGpsInformation } from "@/features/Accounts/accountsSubviews/AccountDetail/components/GpsView/interfaces";
import { Dayjs } from "dayjs";
import { SidenoteReq } from "@/features/Accounts/accountsSubviews/AccountDetail/components/Sidenotes/forms";
import { PrintSalesDoc } from "@/interfaces";

class AccountsService extends AxiosService {
  public constructor() {
    super();
  }

  async getCustomers(req: any) {
    try {
      const { data } = await this.axios.post<GetCustomersResponse>("Customer/List", req);
      return data;
    } catch (err: any) {
      displayErrors(err.message);
      throw err;
    }
  }

  async sendTextMessege(appBuyerRecId: number, message: string) {
    try {
      const { data } = await this.axios.post("Customer/SendMessage", {
        appBuyerRecId,
        message,
      });
      return data;
    } catch (err: any) {
      displayErrors(err.message);
      throw err;
    }
  }

  /** ### Fetch list of accounts - paginated
   * Makes a request at endpoint: `GetAccountSubviewList`.
   * @todo when backend is updated, remove compId from request params.
   */
  async getAccountSubviewList(req: AccountsGetPgReq) {
    try {
      const { data } = await this.axios.post<AccountsGetPgRes>(
        "/Account/GetAccountSubviewList",
        req
      );
      return data;
    } catch (err: any) {
      displayErrors(err.message);
      throw err;
    }
  }

  async getAccountInformation(colRecId: number) {
    try {
      const { data } = await this.axios.get<{ data: AccountInformation }>("/Account/Information", {
        params: { colRecId },
      });
      return data.data;
    } catch (err: any) {
      displayErrors(err.message);
      throw err;
    }
  }

  /** @note appRecId in state is `Partial` type, we need to handle validation */
  async sendTextToPay(appRecId: number | null, colRecId: number, email: string, phone: string) {
    if (appRecId === null)
      throw new Error(`appRecId is null: colRecId: ${colRecId} email: ${email} phone: ${phone}`);
    try {
      const { data } = await this.axios.post("/Payment/SendTextToPayMessage", {
        appRecId,
        colRecId,
        email,
        phone,
      });
      return data;
    } catch (e: any) {
      const errMessage = e.message ? e.message : "Unable to send text to pay";
      toast.error(errMessage);
      throw e;
    }
  }

  async getWarrantyLogs(colRecId: number) {
    try {
      const { data } = await this.axios.get("/CustomerAdmin/WarrantyLogs", {
        params: { colRecId },
      });
      return data.data as WarrantyLog[];
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getSidenotes(appRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<SidenoteDetail<string>[]>>(
        "/CustomerAdmin/GetSideNotesByAppRecId",
        {
          params: { appRecId },
        }
      );
      return data.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getSidenoteAddOns(compId: number | null) {
    if (!compId) {
      console.warn("getSidenoteAddOns(): No compId provided");
      return;
    }
    try {
      const res = await this.axios.get<ApiResponse<SidenoteAddOns>>(
        "/CustomerAdmin/GetSidenoteAddOns",
        { params: { compId } }
      );
      return res.data.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getSidenoteTypeOptions(compId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<string[]>>(
        "/CustomerAdmin/GetSidenoteTypeOptions",
        {
          params: { compId },
        }
      );
      return data.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async postSidenote(payload: SidenoteReq) {
    try {
      const { data } = await this.axios.post<ApiResponse<number>>(
        "/CustomerAdmin/CreateSidenote",
        payload
      );
      return data.data;
    } catch (e) {
      toast.error("Unable to post sidenote");
      throw e;
    }
  }

  async deleteSidenote(appRecId: number) {
    try {
      const { data } = await this.axios.delete<ApiResponse<boolean>>(
        "/CustomerAdmin/DeleteSidenote",
        { params: { appRecId } }
      );
      return data.data;
    } catch (e) {
      toast.error("Unable to delete sidenote");
      throw e;
    }
  }

  async printSidenoteDocs(payload: PrintSalesDoc) {
    try {
      const { data } = await this.axios.post<ApiResponse<string>>(
        "/CustomerAdmin/PrintSidenoteDocs",
        payload
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getWarrantyLog(recId: number) {
    try {
      const { data } = await this.axios.get("/CustomerAdmin/WarrantyLog", {
        params: { recId },
      });
      return data.data as WarrantyLog;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getWarrantyInformation(colRecId: number) {
    try {
      const { data } = await this.axios.get("/CustomerAdmin/WarrantyInformation", {
        params: { colRecId },
      });
      return data.data as WarrantyInformation;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async postWarrantyLog(payload: WarrantyRequestPayload) {
    try {
      const response = await this.axios.post("/CustomerAdmin/WarrantyRequest", payload);
      return response;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async updateWarrantyLog(payload: WarrantyRequestPayload) {
    try {
      const response = await this.axios.put("/CustomerAdmin/WarrantyRequest", payload);
      return response;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getDocumentsNoticesList(colRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<DocumentsNoticesItem[]>>(
        "/System/DocumentsList",
        {
          params: {
            colRecId,
          },
        }
      );

      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getTakePossessionInfo(colRecId: number) {
    try {
      const res = await this.axios.get<ApiResponse<TakePossessionInfo>>(
        "/CustomerAdmin/TakePossessionInformation",
        { params: { colRecId } }
      );
      return res.data.data;
    } catch (e) {
      handleRequestError(e, "Unable to fetch take possession information");
      throw e;
    }
  }

  async postTakePossession(reqBody: TakePossessionReq) {
    try {
      await this.axios.post("/CustomerAdmin/TakePossession", reqBody);

      toast.success("Take possession posted successfully");
    } catch (e) {
      handleRequestError(e, "Error encountered when attempting to take possession");
      throw e;
    }
  }

  async getReleasePossessionInformation(colRecId: number) {
    try {
      const { data } = await this.axios.get("/CustomerAdmin/ReleasePossessionInformation", {
        params: { colRecId },
      });
      return data.data!;
    } catch (e: any) {
      handleRequestError(e, "Unable to get release possession information");
      throw e;
    }
  }

  async releasePossession(payload: ReleasePossessionPayload) {
    try {
      const { data } = await this.axios.post("/CustomerAdmin/ReleasePossession", payload);
      return data;
    } catch (e: any) {
      handleRequestError(e, "Unable to release possession");
      throw e;
    }
  }

  async printOneDoc(formId: number, colRecId: number) {
    try {
      const { data } = await this.axios.post<ApiResponse<string>>("/System/PrintOneDoc", {
        formId,
        colRecId,
      });
      window.open(data.data!);
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getGpsHistory(req: GetGpsHistoryPayload) {
    try {
      const { data } = await this.axios.post<ApiResponse<GridData<GpsHistoryItemDeprec>>>(
        "/Account/GpsHistory",
        req
      );
      return data.data!;
    } catch (err: any) {
      toast.error("Unable to fetch GPS history");
      throw err;
    }
  }

  async getGpsInfoFromColRecId(colRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<GpsInformation>>("/Account/Gps", {
        params: { colRecId },
      });
      if (data.data && data.data.expires) {
        data.data.expires = formatDate(data.data.expires, { pattern: DateFormat.DateInput });
      }
      return data.data!;
    } catch (err: any) {
      toast.error("Unable to fetch GPS information");
      throw err;
    }
  }

  async updateGpsInformation(gpsInfo: UpdateGpsInformation) {
    try {
      const { data } = await this.axios.put<ApiResponse<ApiReturnResponse>>("/Account/Gps", gpsInfo);
      if(!data.data?.success) {
        toast.error(`Error updating GPS information: ${data.data!.message}`);
        return;
      }
      return data.data.success;
    } catch (e) {
      toast.error("Unable to update GPS information");
      throw e;
    }
  }

  async deleteGpsInformation(invRecId: number) {
    try {
      const { data } = await this.axios.delete<ApiResponse<ApiReturnResponse>>("/Account/Gps", {
        params: { invRecId },
      });

      if(!data.data?.success) {
        toast.error(`Error deleting GPS information: ${data.data!.message}`);
        return;
      }

      return data.data.success;
    } catch (e) {
      toast.error("Unable to update GPS information");
      throw e;
    }
  }

  async getReferences(colRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<GetReferencesData>>("/Account/References", {
        params: { colRecId },
      });
      return data.data!;
    } catch (err: any) {
      toast.error("Unable to fetch references");
      throw err;
    }
  }

  async updateReference(reference: Reference) {
    try {
      await this.axios.put("/Account/Reference", reference);
    } catch (e) {
      toast.error("Unable to update reference");
      throw e;
    }
  }

  async createReference(reference: Reference) {
    try {
      await this.axios.post("/Account/Reference", reference);
    } catch (e) {
      toast.error("Unable to add reference");
      throw e;
    }
  }

  async deleteReference(referenceRecId: number) {
    try {
      await this.axios.delete("/Account/Reference", {
        params: { recId: referenceRecId },
      });
    } catch (e) {
      toast.error("Unable to delete reference");
      throw e;
    }
  }

  async getEmployers(colRecId: number) {
    // Returns an array for buyer and cobuyer, however there should only be one each, maximum - the primary employer
    // Other current and past employers are a separate entity and come from the get employer history route
    try {
      const { data } = await this.axios.get<ApiResponse<GetEmployersData>>("/Account/Employers", {
        params: { colRecId },
      });
      return data.data!;
    } catch (e) {
      toast.error("Unable to fetch employers");
      throw e;
    }
  }

  async updateEmployer(employer: Employer) {
    // Primary employer
    try {
      await this.axios.put("/Account/Employer", employer);
      toast.success("Employer information updated");
    } catch (e) {
      toast.error("Unable to update employer");
      throw e;
    }
  }

  async getHistoryEmployers(colRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<GetEmployersData>>(
        "/Account/EmployersHistory",
        {
          params: { colRecId },
        }
      );
      return data.data!;
    } catch (e) {
      toast.error("Unable to fetch employer history");
      throw e;
    }
  }

  async createHistoryEmployer(historyEmployer: Employer) {
    try {
      await this.axios.post("/Account/EmployersHistory", historyEmployer);
      toast.success("Employer added");
    } catch (e) {
      toast.error("Unable to add employer");
      throw e;
    }
  }

  async updateHistoryEmployer(historyEmployer: Employer) {
    try {
      await this.axios.put("/Account/EmployersHistory", historyEmployer);
      toast.success("Employer information updated");
    } catch (e) {
      toast.error("Unable to update employer");
      throw e;
    }
  }

  async deleteHistoryEmployer(historyEmployerRecId: number) {
    try {
      await this.axios.delete("/Account/EmployersHistory", {
        params: { recId: historyEmployerRecId },
      });
    } catch (e) {
      toast.error("Unable to delete employer");
      throw e;
    }
  }

  async getContactInformation(colRecId: number) {
    try {
      const res = await this.axios.get<ApiResponse<GetContactData>>("Account/Contact", {
        params: { colRecId },
      });
      return res.data.data;
    } catch (e) {
      toast.error("Unable to get contact information");
      throw e;
    }
  }

  async updateContactInformation(payload: UpdateBuyerContact) {
    try {
      await this.axios.post("Account/Contact", payload);
      toast.success("Contact information updated");
    } catch (e) {
      toast.error("Unable to update contact information");
      throw e;
    }
  }

  async getHotlist(
    recId: number,
    transType: "Account" | "Inventory" | "Application",
    status: "Active" | "Complete" | "Completed" | "Dismissed"
  ) {
    // This masterkey filtering logic should be moved to the server
    const relevantMasterKeys = [
      "appt",
      "bappt",
      "oncpi",
      "cure1",
      "cure2",
      "cured1",
      "cured2",
      //'regpmt',
      "mycarpay",
      //'vin',
      "latestat",
      "recurring",
      //'gps',
      "orepo",
      "iop",
      "iclaims",
      "legal",
      "rsold",
      "rqrw",
      "sninfo",
      "sinscan",
      "sinsexp",
      "sinsmis",
      //'inscan',
      //'insexp',
      //'insmis',
      //'insnr',
      "noisd",
      "scure2",
      "sendcure1",
      "sendcure1m",
      "sendlr",
      "sendnoi",
      "sendnos",
      "sendsfc",
      "pa",
      "bpa",
      "manual",
    ];
    try {
      let { data } = await this.axios.get<SamTask[]>("/Users/Hotlist", {
        params: { recId, transType, status },
      });

      if (transType === "Account" && data && data.length) {
        data = data.filter((item) => relevantMasterKeys.includes(item.masterKey.toLowerCase()));
      }

      return data;
    } catch (e) {
      toast.error("Unable to get SAM items");
      throw e;
    }
  }

  async getAccountContacts(colRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<AccountContact[]>>("/Account/Contacts", {
        params: { colRecId },
      });
      return data.data!;
    } catch (e) {
      toast.error("Unable to get contact numbers");
      throw e;
    }
  }

  async logCall(call: CallLog) {
    try {
      await this.axios.post("/Account/LogPhoneCall", call);
      toast.success("Call logged");
    } catch (e) {
      toast.error("Unable to log call");
      throw e;
    }
  }

  async getPaymentHistory(req: IAccountPaymentHistoryRequest) {
    try {
      const res = await this.axios.post<ApiResponse<IAccountPaymentHistoryRes>>(
        "/Account/PaymentHistory",
        req
      );
      return res.data.data;
    } catch (e) {
      toast.error("Error fetching payment history");
      throw e;
    }
  }

  async getAccountFullInformation(colRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<AccountFullInformation>>(
        "Account/FullInformation",
        { params: { colRecId } }
      );
      return data.data!;
    } catch (e) {
      toast.error("Error fetching account information");
      console.error(e);
      throw e;
    }
  }

  async getNext6Payments(colRecId: number) {
    try {
      const res = await this.axios.get<ApiResponse<NextPayment[]>>("Account/Next6Payments", {
        params: { colRecId },
      });

      // Check if there is valid data in the response
      const payments = res.data?.data;
      if (!payments) {
        throw new Error("No payment data found in the response.");
      }

      // Adjust each payment's dueDate to be one day ahead and format it as MM/DD/YYYY
      const adjustedPayments = payments.map((payment) => ({
        ...payment,
        dueDate: dayjs(payment.dueDate).add(1, "day").format("MM/DD/YYYY"),
      }));

      return adjustedPayments;
    } catch (e) {
      toast.error("There was an error receiving the next payments");
      console.error(e);
      throw e;
    }
  }

  async getLeaseAmendmentData(colRecId: number) {
    try {
      const data = await this.axios.get<ApiResponse<LeaseAmendmentGetRes>>(
        "Account/GetLeaseAmendmentData",
        { params: { colRecId } }
      );
      return data.data.data;
    } catch (e) {
      toast.error("Unable to load lease amendment data");
      console.error(e);
      throw e;
    }
  }

  async postLeaseAmendment(payload: LeaseAmendmentPostReq) {
    try {
      await this.axios.post("/Account/AmendLease", payload);
      toast.success("Lease amendment processed");
    } catch (e) {
      toast.error("Unable to post lease amendment");
      throw e;
    }
  }

  async getOdometerTracking(colRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<OdometerTrackingData>>(
        "Account/OdometerTracking",
        {
          params: { colRecId },
        }
      );
      return data.data!;
    } catch (e) {
      toast.error("Unable to fetch odometer tracking data");
      console.error(e);
      throw e;
    }
  }

  async getActivityLog(colRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<ActivityLog[]>>("Account/ActivityLog", {
        params: { colRecId },
      });
      return data.data!;
    } catch (e) {
      toast.error("Unable to fetch log activity");
      console.error(e);
      throw e;
    }
  }

  async getIsPendingRewrite(appRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<boolean>>("/Account/IsPendingRewrite", {
        params: { appRecId },
      });
      return data.data ?? false;
    } catch (err: any) {
      console.error(err);
      throw err;
    }
  }

  async cancelPendingRewrite(appRecId: number) {
    try {
      await this.axios.put("/Account/CancelPendingRewrite", { appRecId });
    } catch (err: any) {
      toast.error("Unable to cancel pending rewrite");
      console.error(err);
      throw err;
    }
  }

  async getChargeOffInformation(colRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<ChargeOffInformation>>(
        "/CustomerAdmin/ChargeOffInformation",
        {
          params: { colRecId },
        }
      );
      if (data?.data?.coRepoTypes) {
        // "Insurance " type has a space in the DB ... don't want to update it in the DB and potentially break something else
        // The new API's POST route for chargeoff is searching for "Insurance" without a space, so we do need to fix it here before potentially sending it
        data.data.coRepoTypes = data.data.coRepoTypes.map(({ type, ...rest }) => ({
          type: (type ?? "").trim(),
          ...rest,
        }));
      }
      return data.data!;
    } catch (err: any) {
      console.error(err);
      throw err;
    }
  }

  async postChargeOff(data: ChargeOffPayload) {
    try {
      await this.axios.post("/CustomerAdmin/ChargeOff", data);
    } catch (e: any) {
      if (e?.response?.status === 403) {
        toast.error("Password for selected user is invalid");
      } else {
        toast.error("Unable to charge off account");
      }
      throw e;
    }
  }

  async toggleMyCarPay(appRecId: number, enabled: boolean) {
    try {
      await this.axios.post("/Users/ToggleMyCarPay", { appRecId, enabled });
      toast.success(`MyCarPay Access successfully ${enabled ? "enabled" : "disabled"}`);
    } catch (e) {
      toast.error("Unable to toggle MyCarPay Access");
      console.error(e);
      throw e;
    }
  }

  async getMyCarPay(appRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<MyCarPayConfig>>("/Users/GetAllowOnline", {
        params: { appRecId },
      });
      //the API needs to be updated, notes in ticket.
      return data.data?.allowOnline;
    } catch (err: any) {
      console.error(err);
      throw err;
    }
  }
  
  async postReactivateChargeOff(data: ReactivateChargeOffPayload) {
    try {
      await this.axios.post("/CustomerAdmin/ReactivateChargeOff", data);
    } catch (e: any) {
      if (e?.response?.status === 403) {
        toast.error("Password for selected user is invalid");
      } else {
        toast.error("Unable to reactivate charge off");
      }
      throw e;
    }
  }
}

export const accountsService = new AccountsService();
