import { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
// mui
import DateInput from "@/mui/components/form/MuiKendoDateField";
import Grid from "@mui/material/Unstable_Grid2";
// component
import { PageHeader } from "@/components/pageHeader/PageHeader";
// kendo
import { Button, DropdownInput, NumberInput, TextArea, TextInput, Loader } from "@/components";
import { Spacer } from "@/components/spacer/Spacer";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import { SectionHeader } from "@/components/sectionHeader/SectionHeader";
// utils
import { inventoryService } from "@/services/inventoryService";
import { useNavigationConfirm } from "@/hooks/useNavigationConfirm/useNavigationConfirm";
import { DateFormat, formatDate, scheduleData } from "@/utils/helpers/general";
import { getRouteParamNum } from "@/utils/routing/formatting";
import useReq from "@/utils/useReq";
import { scienapticService } from "@/services/scienapticService";
// interfaces
import { SuggestedSalesInfo } from "@/interfaces/Inventory";
// style
import styles from "./SuggestedAndSoldInfo.module.scss";
import inventoryPageStyles from "../InventoryPageStyles.module.scss";
import { BG_HIGHLIGHT } from "@/mui/theme/colors";



const SuggestedAndSoldInfo: FC = () => {
  const [getSuggestedSalesInfoLoading, setGetSuggestedSalesInfoLoading] = useState(false);
  const [updateSuggestedSalesInfoLoading, setUpdateSuggestedSalesInfoLoading] = useState(false);
  const [getSoldVehicleInfoLoading, setGetSoldVehicleInfoLoading] = useState(false);
  const [showSoldVehicleInfo, setShowVehicleInfo] = useState(false);
  const invRecId = getRouteParamNum(useParams().invRecId);  
  const [showAutoLendIQ, setShowAutoLendIQ] = useState(false);

  const scienapticValidation = useReq(async () => {

    const anyActiveCred = await scienapticService.getScienapticCredentialsByOrgId();
    setShowAutoLendIQ(anyActiveCred ?? false);
    return;
  });

  useEffect(() => {
    scienapticValidation.load();
  }, []);

  const autoLendIQPlaceholder = showAutoLendIQ ? "Required for Autolend IQ" : ""

  const getSuggestedSalesInfo = async () => {
    if (!invRecId) return;
    setGetSuggestedSalesInfoLoading(true);
    try {
      const response = await inventoryService.getSuggestedSalesInfo(invRecId);
      // Database Stores percent as whole number, need to convert to decimal for input
      const updatedResponse: SuggestedSalesInfo = {
        ...response,
        sugPercent: (response.sugPercent || 0) / 100,
      };
      return updatedResponse;
    } finally {
      setGetSuggestedSalesInfoLoading(false);
    }
  };

  const updateSuggestedSalesInfo = async (data: SuggestedSalesInfo) => {
    setUpdateSuggestedSalesInfoLoading(true);
    try {
      // Database Stores percent as whole number, need to convert from decimal input to whole number on submit
      const updatedData: SuggestedSalesInfo = {
        ...data,
        sugPercent: (data.sugPercent || 0) * 100,
      };
      await inventoryService.updateSuggestedSalesInfo(updatedData);
      toast.success("Suggested Sales Info Updated");
    } finally {
      setUpdateSuggestedSalesInfoLoading(false);
    }
  };

  const getSoldVehicleInfo = async () => {
    if (!invRecId) return;
    setGetSoldVehicleInfoLoading(true);
    try {
      setShowVehicleInfo(true);
      const response = await inventoryService.getSoldVehicleInfo(invRecId);
      const updatedResponse = {
        ...response,
        saleDate: formatDate(response.saleDate, { pattern: DateFormat.DateInput, utc: true }),
      };
      return updatedResponse;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        setShowVehicleInfo(false);
      }
      throw e;
    } finally {
      setGetSoldVehicleInfoLoading(false);
    }
  };

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isDirty, isSubmitSuccessful },
  } = useForm<SuggestedSalesInfo>({
    defaultValues: async () => {
      return (await getSuggestedSalesInfo()) ?? ({} as SuggestedSalesInfo);
    },
  });

  const soldVehicleForm = useForm({
    defaultValues: async () => {
      return (await getSoldVehicleInfo()) ?? ({} as SuggestedSalesInfo);
    },
  });

  const { NavigationConfirm } = useNavigationConfirm(isDirty);

  useEffect(() => {
    reset(watch());
  }, [isSubmitSuccessful]);

  const SoldVehicleSection = () => {
    return (
      <section>
        <SectionHeader title="Sold Vehicle Info" />
        {getSoldVehicleInfoLoading ? (
          <Loader size="large" />
        ) : showSoldVehicleInfo ? (
          <div className={inventoryPageStyles.bodyContainer}>
            <div className={inventoryPageStyles.bodyChild}>
              <Controller
                name="saleDate"
                control={soldVehicleForm.control}
                render={({ field }) => <DateInput label="Date Sold" readOnly {...field} />}
              />
              <Controller
                name="buyerName"
                control={soldVehicleForm.control}
                render={({ field }) => <TextInput readOnly label="Buyer's Name" {...field} />}
              />
              <Controller
                name="salesperson"
                control={soldVehicleForm.control}
                render={({ field }) => <TextInput readOnly label="Salesperson" {...field} />}
              />
            </div>
            <div className={inventoryPageStyles.bodyChild}>
              <Controller
                name="salesPrice"
                control={soldVehicleForm.control}
                render={({ field }) => (
                  <CurrencyInput rightAlignInput label="Sales Price" readOnly placeholder={autoLendIQPlaceholder} {...field} />
                )}
              />
              <Controller
                name="saleType"
                control={soldVehicleForm.control}
                render={({ field }) => <TextInput readOnly label="Sale Type" placeholder={autoLendIQPlaceholder} {...field} />}
              />
            </div>
          </div>
        ) : (
          <div className={styles.soldVehicleMessage}>This vehicle has not been sold</div>
        )}
      </section>
    );
  };

  return (
    <>
      <Grid
        container
        direction="column"
        gap={2}
        sx={{ height: "80vh", width: "100%", overflow: "hidden", padding: "20px" }}
      >
        {/* Header Section */}
        <Grid overflow={"hidden"}>
          <PageHeader headerLabel="Suggested Sale / Sold Vehicle" />
        </Grid>
        <Grid
          sx={{
            flex: 1, // Takes the remaining space
            borderRadius: 2.5,
            backgroundColor: BG_HIGHLIGHT,
            overflow: "scroll", // Prevent content overflow
          }}
        >
          <form
            className={inventoryPageStyles.body}
            onSubmit={handleSubmit(updateSuggestedSalesInfo)}
          >
            <section>
              <SectionHeader title="Suggested Sales Info" />
              {getSuggestedSalesInfoLoading ? (
                <Loader size="large" />
              ) : (
                <div className={inventoryPageStyles.bodyContainer}>
                  <div className={inventoryPageStyles.bodyChild}>
                    <Controller
                      name="sugPrice"
                      control={control}
                      render={({ field }) => (
                        <CurrencyInput rightAlignInput label="Price" {...field} />
                      )}
                    />
                    <Controller
                      name="sugDown"
                      control={control}
                      render={({ field }) => (
                        <CurrencyInput rightAlignInput label="Down Payment" {...field} />
                      )}
                    />
                    <Controller
                      name="sugPercent"
                      control={control}
                      render={({ field }) => (
                        <NumberInput
                          rightAlignInput
                          format="p2"
                          label="Down Payment %"
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="sugTerms"
                      control={control}
                      render={({ field }) => (
                        <DropdownInput label="Schedule" data={scheduleData} {...field} />
                      )}
                    />
                    <Controller
                      name="priceSheetMemo"
                      control={control}
                      render={({ field }) => (
                        <TextArea label="Price Sheet Memo" rows={3} {...field} />
                      )}
                    />
                  </div>
                  <div className={inventoryPageStyles.bodyChild}>
                    <Controller
                      name="sugPayment"
                      control={control}
                      render={({ field }) => (
                        <CurrencyInput rightAlignInput label="Payment" {...field} />
                      )}
                    />
                    <Controller
                      name="sugTerm"
                      control={control}
                      render={({ field }) => (
                        <NumberInput rightAlignInput format="n0" label="Months" {...field} />
                      )}
                    />
                    <Controller
                      name="msrp"
                      control={control}
                      render={({ field }) => (
                        <CurrencyInput rightAlignInput label="MSRP" {...field} />
                      )}
                    />
                    <Controller
                      name="internetPrice"
                      control={control}
                      render={({ field }) => (
                        <CurrencyInput rightAlignInput label="Internet Price" {...field} />
                      )}
                    />
                    <Controller
                      name="book1"
                      control={control}
                      render={({ field }) => <TextInput label="Source of Value" {...field} />}
                    />
                    <Controller
                      name="bookValue1"
                      control={control}
                      render={({ field }) => (
                        <CurrencyInput rightAlignInput label="Book Value/MMR" {...field} />
                      )}
                    />
                  </div>
                </div>
              )}
            </section>
            <Spacer size={20} />
            <SoldVehicleSection />
            <div className={inventoryPageStyles.submitButtonContainer}>
              <Button label="Submit" loading={updateSuggestedSalesInfoLoading} />
            </div>
          </form>
        </Grid>
      </Grid>
      {NavigationConfirm}
    </>
  );
};

export default SuggestedAndSoldInfo;
