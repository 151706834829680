import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import { CircleSpinner } from "react-spinners-kit";
// kendo
import { Icons } from "@/components/icons";
import { TextInput } from "@components/inputs/text/TextInput";
import { Button } from "@components/button/Button";
// state
import { useAuthSelector } from "@/features/auth/authSlice";
import { useAuthCtx } from "@/AppProviders/AuthProvider";
import { useAcctDetailsCtx } from "@/features/Accounts/accountsSubviews/AccountDetail/AcctDetailProvider";
// utils
import { Messages, customerService } from "@/services/customerService";
import { accountsService } from "@/services/accountsService";
import { DateFormat, formatDate } from "@/utils/helpers/general";
import { formatPhone } from "@/utils/helpers/formatting";
// interfaces
import { Events } from "@/enums/general";
import { ActivityType, CustomerActivity } from "./activityPanelInterfaces";
// style
import styles from "./activityPanel.module.scss";
import { DropdownInput } from "../inputs";
import { useAccountSelector } from "@/features/Accounts/accountSlice";

interface ChatBoxProps {
  activityData: CustomerActivity[];
  appBuyerRecId: number;
  initData: () => Promise<void>;
  height?: number;
  loading: boolean;
  isLegal: boolean;
  canTextBuyer: boolean;
  canTextCoBuyer: boolean;
}

interface ContactOption {
  appBuyerRecId: number;
  contact: string;
}

interface SmsForm {
  newMessage: string;
  appBuyerRecId: number | null;
}

export const ChatBox: FC<ChatBoxProps> = (props) => {
  const { userName, compId } = useAuthSelector((s) => s);
  const signalRConnection = useAuthCtx((s) => s.signalRConnection);
  const [messages, setMessages] = useState<Messages[]>([]);
  const [newMessageSending, setNewMessageSending] = useState(false);
  const optStatus = useAcctDetailsCtx((s) => s.optStatus);
  const optInAuthorization = useAcctDetailsCtx((s) => s.optInAuthorization);
  const accountInformation = useAccountSelector((s) => s.accountInformation);
  const [contacts, setContacts] = useState<ContactOption[]>([]);
  const [selectedContact, setSelectedContact] = useState<ContactOption | null>(null);

  const { control, handleSubmit, watch, reset, setValue, formState: { errors, isValid }
  } = useForm<SmsForm>({
    defaultValues: {
      newMessage: "",
      appBuyerRecId: props.appBuyerRecId,
    },
    mode: "onChange"
  });

  const newMessage = watch("newMessage");

  const init = () => {
    if (!props.activityData) return;
    if (!accountInformation) return;
    const tempMessages = props.activityData
      .filter((ad) => ad.activityType === ActivityType.enum.Message)
      .map((ad) => ad.activity as Messages);

    tempMessages.sort((a, b) => (b.timestamp! > b.timestamp! ? -1 : 1));

    setMessages(tempMessages);

    const { buyer, coBuyer } = accountInformation;
    const hasCoBuyer = !!(coBuyer?.appBuyerRecId && coBuyer?.firstName);

    if (props.canTextBuyer) {
      setContacts([{ appBuyerRecId: buyer.appBuyerRecId!, contact: `Buyer - ${buyer.firstName} ${buyer.lastName}`}]);
    }
    
    if (hasCoBuyer && props.canTextCoBuyer) {
      setContacts((prevContacts) => [...prevContacts, { appBuyerRecId: coBuyer.appBuyerRecId!, contact: `Co-buyer - ${coBuyer.firstName} ${coBuyer.lastName}`}]);
    }
  };

  useEffect(() => {
    if (contacts.length > 0) {
      setSelectedContact(contacts[0] ?? null);
      setValue("appBuyerRecId", contacts[0]?.appBuyerRecId ?? props.appBuyerRecId); // Set the first contact
    }
  }, [contacts, setValue]);

  useEffect(() => {
    init();
  }, [props.activityData]);

  useEffect(() => {
    window.addEventListener(Events.ReceivedSMS, props.initData);
    return () => window.removeEventListener(Events.ReceivedSMS, props.initData);
  }, []);

  const addNewMessage = async (formData: SmsForm) => {
    if(!isValid) return;

    setNewMessageSending(true);
    try {
      await accountsService.sendTextMessege(formData.appBuyerRecId!, formData.newMessage);
      reset();
      // Fetching the messages from the history is slow so mock it in there first
      const insertedMessage: Messages = {
        timestamp: new Date().toString(),
        body: formData.newMessage,
        sentBy: userName || "",
        sentByNumber: null,
        sentTo: null,
        sentToNumber: null,
        received: false,
        ecomRecId: Number(Math.random().toString().slice(2)),
        seen: false,
        isDirectConsentError: null,
        status: null,
        detailRaw: null,
        errorCode: null,
        errorMessage: null,
      };
      setMessages([insertedMessage, ...messages]);
      props.initData();
    } finally {
      setNewMessageSending(false);
    }
  };

  const changeReadStatus = async (changedMessage: Messages) => {
    try {
      if (changedMessage.seen) {
        await customerService.markEcomMessageAsUnread(changedMessage.ecomRecId);
      } else {
        await customerService.markEcomMessageAsRead(changedMessage.ecomRecId);
      }

      if (signalRConnection && compId) {
        await signalRConnection.send("UpdateMessageCountForConnections", compId, "");
      }

      setMessages(
        messages.map((m) => {
          if (m.ecomRecId === changedMessage.ecomRecId) {
            m.seen = !m.seen;
          }
          return m;
        })
      );
    } catch (err) {
      toast.error("Unable to change read status");
    }
  };

  const placeholderMessageSwitch = () => {
    if (optInAuthorization && optStatus !== "accepted") {
      return "Customer has not consented to texts. Messaging is disabled.";
    }
    if (props.isLegal) {
      return "Cannot contact customer with legal status";
    }

    if (!props.canTextBuyer && !props.canTextCoBuyer) {
      return "No Text preference on";
    }
    return "Type a new message...";
  };

  const messagingInputDisabledSwitch = ()=>{
    if (optInAuthorization && optStatus !== "accepted") {
      return true;
    }
    if (props.isLegal || !props.canTextBuyer) {
      return true;
    }
    return false;
  }

  const messagingButtonDisabledSwitch = ()=>{
    if (optInAuthorization && optStatus !== "accepted") {
      return true;
    }
    if (props.isLegal || !newMessage || newMessageSending || (!props.canTextBuyer && !props.canTextCoBuyer) || !isValid) {
      return true;
    }
    return false;
  }

  return (
    <>
      <form onSubmit={handleSubmit(addNewMessage)} className={styles.smsInputContainer}>
        <div style={{ display: "flex", flex: 1, flexDirection: "row", gap: 15 }}>
          <Controller
            name="newMessage"
            control={control}
            render={({ field }) => (
              <TextInput
                containerStyles={{ display: "flex", flex: "1" }}
                placeholder={
                  placeholderMessageSwitch()
                }
                disabled={messagingInputDisabledSwitch()}
                {...field}
              />
            )}
          />
          
          <Controller
            name="appBuyerRecId"
            control={control}
            rules={{ required: 'Field is required' }}
            render={({ field }) => (
              <DropdownInput
                required={props.canTextBuyer || props.canTextCoBuyer}
                disabled={!props.canTextBuyer && !props.canTextCoBuyer}
                data={contacts}
                dataItemKey="appBuyerRecId"
                textField="contact"
                value={selectedContact}
                onChange={(e) => {
                  setSelectedContact(e.value);
                  setValue("appBuyerRecId", e.value?.appBuyerRecId);
                }}
                errors={errors.appBuyerRecId?.message}
              />
            )}
          />
        </div>
        <div>
          <Button
            label="Send"
            disabled={messagingButtonDisabledSwitch()}
            loading={newMessageSending}
          />
        </div>
      </form>
      <div className={styles.chatBoxContainer}>
        {props.loading ? (
          <div className={styles.loading}>
            <CircleSpinner color={"#3299df"} size={50} />
          </div>
        ) : (
          messages.map((message) => {
            const wasOutboundSMS = !message.received;
            const headerContents = [
              message.sentBy,
              formatDate(message.timestamp, { pattern: DateFormat.DateTime, utc: false }),
            ];
            // Date may be missing, if so don't show the dash
            const headerText = headerContents.filter((el) => !!el).join(" - ");
            return (
              <div
                className={wasOutboundSMS ? styles.smsOutbound : styles.smsInbound}
                key={message.ecomRecId}
              >
                <div className={styles.smsHeader}>{headerText}</div>
                <div className={styles.smsBodyWrapper}>
                  <div className={styles.readIcon} onClick={() => changeReadStatus(message)}>
                    {!wasOutboundSMS && message.seen && <Icons.Eye color="#129A38" />}
                    {!wasOutboundSMS && !message.seen && <Icons.EyeX color="#C60000" />}
                    {(message.isDirectConsentError ||
                      message.status === "failed" ||
                      message.status === "undelivered") && <Icons.RoundX color="#C60000" />}
                  </div>
                  <div className={styles.smsBody}>
                    {message.isDirectConsentError && (
                      <div className={styles.smsDirectConsentFailure}>
                        Failed to send, Customer opted out of text communication with phone number:{" "}
                        {formatPhone(message.sentToNumber ?? "")}
                      </div>
                    )}
                    {(message.status === "failed" || message.status === "undelivered") && (
                      <div className={styles.smsDirectConsentFailure}>
                        Message failed with error: {message.errorMessage ?? message.errorCode}
                      </div>
                    )}
                    <div>{message.body}</div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>        
    </>
  );
};
