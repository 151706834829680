import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
// utils
import { buildSubStateSelector } from '@/store/utilities/useAppStateSelector';
// interfaces
import { SelectableCompany } from '@/interfaces/System';

export interface AuthAppState {
  /** @note This is the SELECTED COMPANY Id if not found then the LoggedIn user's DEFAULT Company ID */
  compId?: number; 
  /** @note This is the SELECTED COMPANY Org Id if not found then the LoggedIn user's DEFAULT Org ID */
  orgId?: number;
  /** @note This is the SELECTED COMPANY Location Id if not found then the LoggedIn user's DEFAULT Location ID */
  locId?: number;
  ccLocId?: number;
  userId?: number;
  userName?: string;
  userEmail?: string;
  hasAfsLevel?: boolean;
  hasDclAccess?: boolean;
  invChangeStkNum?: boolean;
  invPrice?: boolean;
  invDelete?: boolean;
  backDateInv?: boolean;
  backDateExp?: boolean;
  /** @deprecated non-standalone - uses `dmsToken` localstorage item */
  isLoggedInDeprec: boolean;
  selectableCompanies?: SelectableCompany[];
  companySelectorOpen: boolean;
}

const initialState: AuthAppState = {
  compId: undefined,
  orgId: undefined,
  locId: undefined,
  userId: undefined,
  userName: undefined,
  userEmail: undefined,
  hasAfsLevel: false,
  hasDclAccess: false,
  invChangeStkNum: false,
  invPrice: false,
  invDelete: false,
  backDateInv: false,
  backDateExp: false,
  /** Doesn't matter if it's expired - if it is, axios middleware should handle
   * logging them out when they make their first request
   */
  isLoggedInDeprec: !!localStorage.getItem('dmsToken'),
  selectableCompanies: undefined,
  companySelectorOpen: false,
};

const slice = createSlice<
  AuthAppState,
  {
    setState: CaseReducer<AuthAppState, PayloadAction<Partial<AuthAppState>>>;
    setHasAfsLevel: CaseReducer<AuthAppState, PayloadAction<boolean>>;
    setHasDclAccess: CaseReducer<AuthAppState, PayloadAction<boolean>>;
    setInvChangeStkNum: CaseReducer<AuthAppState, PayloadAction<boolean>>;
    setInvPrice: CaseReducer<AuthAppState, PayloadAction<boolean>>;
    setInvDelete: CaseReducer<AuthAppState, PayloadAction<boolean>>;
    setBackDateInv: CaseReducer<AuthAppState, PayloadAction<boolean>>;
    setBackDateExp: CaseReducer<AuthAppState, PayloadAction<boolean>>;
    setCompanySelectorOpen: CaseReducer<AuthAppState, PayloadAction<boolean>>;
    /** @deprecated */
    loginDeprec: CaseReducer<AuthAppState>;
    /** @deprecated */
    logoutDeprec: CaseReducer<AuthAppState>;
  }
>({
  name: 'auth',
  initialState,
  reducers: {
    setState: (state, { payload }) => {
      return { ...state, ...payload };
    },
    setHasAfsLevel: (state, { payload }) => {
      state.hasAfsLevel = payload;
    },
    setHasDclAccess: (state, { payload }) => {
      state.hasDclAccess = payload;
    },
    setInvChangeStkNum: (state, { payload }) => {
      state.invChangeStkNum = payload;
    },
    setInvPrice: (state, { payload }) => {
      state.invPrice = payload;
    },
    setInvDelete: (state, { payload }) => {
      state.invDelete = payload;
    },
    setBackDateInv: (state, { payload }) => {
      state.backDateInv = payload;
    },
    setBackDateExp: (state, { payload }) => {
      state.backDateExp = payload;
    },
    setCompanySelectorOpen: (state, { payload }) => {
      state.companySelectorOpen = payload;
    },
    /** @deprecated */
    loginDeprec: (state) => {
      state.isLoggedInDeprec = true;
    },
    /** @deprecated */
    logoutDeprec: () => {
      localStorage.clear();
      return initialState;
    },
  },
});

/** @todo transition to `useAuthCtx` for auth state access */
export const useAuthSelector = buildSubStateSelector<AuthAppState>((state) => state.auth);
/** @todo transition to `useAuthCtx` for auth state access */
export const authActions = slice.actions;
export const authReducer = slice.reducer;
