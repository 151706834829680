// state
// interfaces
import { ValidationError } from "./interfaces";
import { toPairs } from "lodash";

const errorMapping = Object.fromEntries(
  Object.entries({
    ApplicationId: { name: "Application Id", msg: "" },
    LoanType: { name: "Loan Type", msg: "" },
    RequestedFinancingAmount: { name: "Requested Financing Amount", msg: "" },
    RequestedTermInMonths: { name: "Requested Term In Months", msg: "" },
    LeasePaymentAmount: { name: "Lease Payment Amount", msg: "" },
    LeasePrice: { name: "Lease Price", msg: "" },
    GapFee: { name: "Gap Fee", msg: "" },
    WarrantyFee: { name: "Warranty Fee", msg: "" },
    AdminFee: { name: "Admin Fee", msg: "" },
    MaintenanceFee: { name: "Maintenance Fee", msg: "" },
    ServiceFee: { name: "Service Fee", msg: "" },
    CapCost: { name: "Cap Cost", msg: "" },
    AdjCapCost: { name: "Adj Cap Cost", msg: "" },
    ResidualRate: { name: "Residual Rate", msg: "" },
    ResidualAmount: { name: "Residual Amount", msg: "" },
    MoneyFactor: { name: "Money Factor", msg: "" },
    InterestRate: { name: "Interest Rate", msg: "" },
    TradeAllowance: { name: "Trade Allowance", msg: "" },
    TradePayoff: { name: "Trade Payoff", msg: "" },
    DeferredAmount: { name: "Deferred Amount", msg: "" },
    CashDownPayment: { name: "CashDown Payment", msg: "" },
    SalesTaxOnDownPayment: { name: "Sales Tax On Down Payment", msg: "" },
    LicenseAndRegistrationFee: { name: "License And Registration Fee", msg: "" },
    TitleFee: { name: "Title Fee", msg: "" },
    DocFee: { name: "Doc Fee", msg: "" },
    SecurityDeposit: { name: "Security Deposit", msg: "" },
    CollateralType: { name: "Collateral Type", msg: "" },
    VehicleMake: { name: "Vehicle Make", msg: "" },
    VehicleModel: { name: "Vehicle Model", msg: "" },
    VehicleMileage: { name: "Vehicle Mileage", msg: "" },
    VehicleYear: { name: "Vehicle Year", msg: "" },
    VehicleAgeInMonths: { name: "Vehicle Age In Months", msg: "" },
    Vin: { name: "Vin", msg: "" },
    CollateralPurchasePrice: { name: "Collateral Purchase Price", msg: "Inventory > Vehicle > Suggested Sale Page" },
    CollateralValueMMR: { name: "Collateral Value MMR", msg: "Inventory > Vehicle > Suggested Sale Page" },
    TradeInCollateralValue: { name: "Trade In Collateral Value", msg: "Terms > Trade In" },
    FirstName: { name: "First Name", msg: "" },
    MiddleName: { name: "Middle Name", msg: "" },
    LastName: { name: "Last Name", msg: "" },
    Ssn: { name: "Ssn", msg: "" },
    Email: { name: "Email", msg: "" },
    CellPhone: { name: "Cell Phone", msg: "" },
    HomePhone: { name: "Home Phone", msg: "" },
    Dob: { name: "Dob", msg: "" },
    HouseNumber: { name: "House Number", msg: "" },
    Street: { name: "Street", msg: "" },
    State: { name: "State", msg: "" },
    City: { name: "City", msg: "" },
    ZipCode: { name: "Zip Code", msg: "" },
    CreditScore: { name: "Credit Score", msg: "" },
    OccupancyType: { name: "Occupancy Type", msg: "Application" },
    LengthOfResidencyInMonths: { name: "Length Of Residency In Months", msg: "" },
    HousingPaymentAmount: { name: "Housing Payment Amount", msg: "Application > Underwriting" },
    IncomeTotalMonthlyNet: { name: "Income Total Monthly Net", msg: "Application > Underwriting" },
    IncomeMonthlyDisposable: { name: "Income Monthly Disposable", msg: "Application > Underwriting" },
    ExpenseRentMortgage: { name: "Expense Rent Mortgage", msg: "Application > Underwriting" },
    ExpenseOtherAuto: { name: "Expense Other Auto", msg: "Application > Underwriting" },
    ExpenseAutoInsurance: { name: "Expense Auto Insurance", msg: "Application > Underwriting" },
    ExpenseHomePhone: { name: "Expense Home Phone", msg: "Application > Underwriting" },
    ExpenseCellPhone: { name: "Expense Cell Phone", msg: "Application > Underwriting" },
    ExpenseElectric: { name: "Expense Electric", msg: "Application > Underwriting" },
    ExpenseGas: { name: "Expense Gas", msg: "Application > Underwriting" },
    ExpenseCreditCards: { name: "Expense Credit Cards", msg: "Application > Underwriting" },
    ExpenseStudentLoans: { name: "Expense Student Loans", msg: "Application > Underwriting" },
    ExpenseAlimony: { name: "Expense Alimony", msg: "Application > Underwriting" },
    ExpenseMiscellaneous: { name: "Expense Miscellaneous", msg: "Application > Underwriting" },
    ExpenseTotal: { name: "Expense Total", msg: "Application > Underwriting" },
    EmployerName: { name: "Employer Name", msg: "Application > Buyer/Cobuyer" },
    JobName: { name: "Job Name", msg: "Application > Buyer/Cobuyer" },
    EmployerWorkPhone: { name: "Employer Work Phone", msg: "Application > Buyer/Cobuyer" },
    EmployerAddress: { name: "Employer Address", msg: "Application > Buyer/Cobuyer" },
    MonthsOnJob: { name: "Months On Job", msg: "Application > Buyer/Cobuyer" },
    JobStatus: { name: "Job Status", msg: "Application > Buyer/Cobuyer" },
    PaydayFrequency: { name: "Payday Frequency", msg: "Application > Buyer/Cobuyer" },
    GrossMonthlyIncome: { name: "Gross Monthly Income", msg: "Application > Buyer/Cobuyer" },
    TakeHomeMonthlyIncome: { name: "Take Home Monthly Income", msg: "Application > Buyer/Cobuyer" },
    OtherMonthlyIncome: { name: "Other Monthly Income", msg: "Application > Buyer/Cobuyer" },
  }).map(([key, value]) => [key.toLowerCase(), value]) // Normalize keys to lowercase
);

export type ErrorMap = {
  ApplicationId?: string;
  LoanType?: string;
  RequestedFinancingAmount?: string;
  RequestedTermInMonths?: string;
  LeasePaymentAmount?: string;
  LeasePrice?: string;
  GapFee?: string;
  WarrantyFee?: string;
  AdminFee?: string;
  MaintenanceFee?: string;
  ServiceFee?: string;
  CapCost?: string;
  AdjCapCost?: string;
  ResidualRate?: string;
  ResidualAmount?: string;
  MoneyFactor?: string;
  InterestRate?: string;
  TradeAllowance?: string;
  TradePayoff?: string;
  DeferredAmount?: string;
  CashDownPayment?: string;
  SalesTaxOnDownPayment?: string;
  LicenseAndRegistrationFee?: string;
  TitleFee?: string;
  DocFee?: string;
  SecurityDeposit?: string;
  CollateralType?: string;
  VehicleMake?: string;
  VehicleModel?: string;
  VehicleMileage?: string;
  VehicleYear?: string;
  VehicleAgeInMonths?: string;
  Vin?: string;
  CollateralPurchasePrice?: string;
  CollateralValueMMR?: string;
  TradeInCollateralValue?: string;
  FirstName?: string;
  MiddleName?: string;
  LastName?: string;
  Ssn?: string;
  Email?: string;
  CellPhone?: string;
  HomePhone?: string;
  Dob?: string;
  HouseNumber?: string;
  Street?: string;
  State?: string;
  City?: string;
  ZipCode?: string;
  CreditScore?: string;
  OccupancyType?: string;
  LengthOfResidencyInMonths?: string;
  HousingPaymentAmount?: string;
  IncomeTotalMonthlyNet?: string;
  IncomeMonthlyDisposable?: string;
  ExpenseRentMortgage?: string;
  ExpenseOtherAuto?: string;
  ExpenseAutoInsurance?: string;
  ExpenseHomePhone?: string;
  ExpenseCellPhone?: string;
  ExpenseElectric?: string;
  ExpenseGas?: string;
  ExpenseCreditCards?: string;
  ExpenseStudentLoans?: string;
  ExpenseAlimony?: string;
  ExpenseMiscellaneous?: string;
  ExpenseTotal?: string;
  EmployerName?: string;
  JobName?: string;
  EmployerWorkPhone?: string;
  EmployerAddress?: string;
  MonthsOnJob?: string;
  JobStatus?: string;
  PaydayFrequency?: string;
  GrossMonthlyIncome?: string;
  TakeHomeMonthlyIncome?: string;
  OtherMonthlyIncome?: string;
};


const createValidationErrors = (
  errors: ErrorMap, 
): ValidationError[] => {
  const validationErrors: ValidationError[] = [];
  const errorsArr = toPairs(errors);

  // Iterate through each error in the list
  errorsArr.forEach(([key, message]) => {
    const normalizedKey = key.toLowerCase(); // Normalize to lowercase
    const mappedValue = errorMapping[normalizedKey];

    // Ensure that mappedValue is defined before creating a ValidationError
    if (mappedValue) {
      const validationError = new ValidationError(
        [key, message], // pass the original error tuple
        mappedValue.msg, // msg from the mapping object
        mappedValue.name, // label from the mapping object
      );
      validationErrors.push(validationError);
    }
    else {
      console.warn("No mapping found for key:", {key, message});
    }
  });

  return validationErrors;
};

const submitValidationErrors = (errors: ErrorMap | null) => {
  if (errors === null) return null;

  // Use the updated createValidationErrors function
  const validationErrorList = createValidationErrors(errors);

  console.log("Generated Validation Errors:", validationErrorList);
  return validationErrorList.length > 0 ? validationErrorList : null;
};

export default submitValidationErrors;
