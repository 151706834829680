import { CreditEducationInfo, CreditEducationDBInfo } from '@/interfaces/CreditEducation';
import { applicationService } from '@/services/applicationService';
import useCtxFactory from '@/utils/ctxState/useCtxFactory';
import { FC, ReactNode, createContext, useContext, useState } from 'react';
// utils
// interfaces

export interface ICreditEducationCtx {
  isLoading: boolean;
  setIsLoading: (_: boolean) => void;
  showCreditRepairForm: boolean;
  setShowCreditRepairForm: (_: boolean) => void;
  isDataLoading: boolean;
  setIsDataLoading: (_: boolean) => void;
  creditEducationInfo: CreditEducationInfo | null;
  setCreditEducationInfo: (_: CreditEducationInfo | null) => void;
  creditEducationDBInfo: CreditEducationDBInfo | null;
  setCreditEducationDBInfo: (_: CreditEducationDBInfo | null) => void;
  applicationRecId: number | null | undefined;
  setApplicationRecId: (_: number | null) => void;
  fetchCreditEducationData: (_: number | null | undefined) => void;
  isCobuyerTab: boolean | null | undefined;
  setIsCobuyerTab: (_: boolean | null | undefined) => void;
}

const CreditEducationCtx = createContext<ICreditEducationCtx | null>(null);

const CreditEducationProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showCreditRepairForm, setShowCreditRepairForm] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [creditEducationInfo, setCreditEducationInfo] = useState<CreditEducationInfo | null>(null);
  const [creditEducationDBInfo, setCreditEducationDBInfo] = useState<CreditEducationDBInfo | null>(
    null
  );
  const [isCobuyerTab, setIsCobuyerTab] = useState<boolean | null | undefined>(false);
  const [applicationRecId, setApplicationRecId] = useState<number | null>(null);

  const fetchCreditEducationData = async (appRecId: number | null | undefined) => {
    if (appRecId == null) return;
        
    setIsDataLoading(true);

    const getCreditEducationRes = await applicationService.getCreditEducationInfo(appRecId);

    if (!getCreditEducationRes)
      throw new Error(
        `No credit education information found for appRecId: ${appRecId}`
      );

    setCreditEducationDBInfo(getCreditEducationRes);

    const buyerInfo = isCobuyerTab ? getCreditEducationRes?.coBuyer : getCreditEducationRes?.buyer;
    const buyerCreditInfo = isCobuyerTab ? getCreditEducationRes?.coBuyerCreditEducation : getCreditEducationRes?.buyerCreditEducation;
    const creditInfo = {
      firstName: buyerInfo?.b_FIRST,
      middleName: buyerInfo?.b_MID,
      lastName: buyerInfo?.b_LAST,
      dateOfBirth: buyerInfo?.b_DOB,
      SSNLast4: buyerInfo?.b_SSN!.slice(-4),
      phoneNumber: buyerInfo?.b_CPHONE,
      homePhoneNumber: buyerInfo?.b_HPHONE,
      workPhoneNumber: buyerInfo?.b_LLPHONE,
      faxNumber: buyerInfo?.b_LLFAX,
      email: buyerInfo?.b_EMAIL,
      currentAddress: buyerInfo?.b_ADDR,
      currentCity: buyerInfo?.b_CITY,
      currentState: buyerInfo?.b_STATE,
      currentZipCode: buyerInfo?.b_ZIP,
      appBuyersRecID: buyerInfo?.rec_Id!.toString(),
      creditRepairCloudID: buyerCreditInfo?.creditRepairCloudId,
      trackingToken: buyerCreditInfo?.smartCreditTrackingToken,
      customerToken: buyerCreditInfo?.smartCreditCustomerToken,
      creditEducationID: buyerCreditInfo?.rec_Id!.toString(),
      creditHeroInviteLink: buyerCreditInfo?.creditHeroInviteLink,
    } as CreditEducationInfo;

    setCreditEducationInfo(creditInfo);
    setIsDataLoading(false);
  };

  return (
    <CreditEducationCtx.Provider
      value={{
        isLoading,
        setIsLoading,
        showCreditRepairForm,
        setShowCreditRepairForm,
        isDataLoading,
        setIsDataLoading,
        creditEducationInfo,
        setCreditEducationInfo,
        creditEducationDBInfo,
        setCreditEducationDBInfo,
        applicationRecId, 
        setApplicationRecId,
        fetchCreditEducationData,
        isCobuyerTab,
        setIsCobuyerTab,
      }}
    >
      {children}
    </CreditEducationCtx.Provider>
  );
};

export default CreditEducationProvider;

export const useCreditEducationCtx = useCtxFactory(CreditEducationCtx);
